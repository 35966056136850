export enum PartnerStatuses {
  total = 'total',
  active = 'active',
  inactive = 'inactive',
  pending = 'pending',
  deleted = 'deleted'
}

export enum AccessLevelStatuses {
  total = 'total',
  active = 'active',
  inactive = 'inactive'
}
