import { css } from '@emotion/react';

import { palette } from 'utils';

export const inputStyles = css`
  .ant-input-affix-wrapper {
    color: ${palette['grey-60']};

    &,
    &-md {
      height: 40px;
      padding: 0 10px;

      .ant-input-suffix {
        gap: 8px;
      }
    }
    &-sm {
      height: 32px;
      padding: 0 8px;
      border-radius: 8px;

      .ant-input-suffix {
        gap: 4px;
      }
    }
    &-lg {
      height: 48px;
      padding: 0 12px;
    }

    &-disabled {
      border-color: transparent !important;
      background-color: ${palette['grey-90']};
    }

    & > input.ant-input {
      &,
      &-md {
        height: 38px; // 40 - 2
        line-height: 38px;
        padding: 0 8px;
      }
      &-sm {
        height: 30px; // 32px - 2
        line-height: 30px;
        padding: 0 4px;
        border-radius: 8px;

        font-size: 14px;
      }
      &-lg {
        height: 46px;
        line-height: 46px;
        padding: 0 12px;
      }
    }

    &:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: ${palette['grey-50']};
    }

    &:focus {
      border-color: ${palette['grey-50']};
    }

    &.ant-input-affix-wrapper-focused {
      border-color: ${palette['grey-50']};
      box-shadow: 0 0 0 4px rgba(171, 112, 255, 0.2);
    }

    .ant-input-suffix > :not(:last-child) {
      margin-inline-end: 0 !important;
    }

    .ant-input-clear-icon-has-suffix {
      margin: 0 !important;
    }
  }

  .ant-input {
  }

  .ant-input-prefix {
    &-has-value {
      color: ${palette['purple-100']};
    }
  }
  .ant-input-suffix {
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .ant-input-clear-icon {
    display: flex;
  }

  .ant-input-clear-icon-hidden {
    width: 0px;
  }
`;
