import { useContext, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  DelegateDocumentHeader,
  DocumentContent,
  DelegateDocumentFooter,
  FullpageSpinner,
  Flex
} from 'components';
import { documentsQueries } from 'api';
import { axios } from 'api/axios';
import { getNotificationError, getRoute } from 'utils';
import { AxiosError } from 'axios';
import { NotificationContext } from 'contexts';

export const DelegatedDocumentPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { notification } = useContext(NotificationContext);
  const token = new URLSearchParams(location.search).get('token');
  const [enabled, setEnabled] = useState(false)

  const {
    data: delegatedDocument,
    isLoading,
    refetch,
  } = useQuery({
    ...documentsQueries.getDelegatedDocumentById(Number(id)),
    enabled,
    onError: (error: AxiosError) => {
      notification.error({
        message: getNotificationError(error)
      });
      navigate(getRoute('404Page'));
    },
    select: ({ document, ...rest }) => ({
      document: { ...document, file: document.pdf_file || document.file },
      ...rest
    })
  });

  useEffect(() => {
    if (!token) return;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    setEnabled(true);
  }, [refetch, token]);

  if (isLoading) return <FullpageSpinner />;

  return (
    <>
      {delegatedDocument?.document && (
        <Flex direction="column" style={{ height: '100vh' }}>
          <DelegateDocumentHeader document={delegatedDocument.document} />

          <div style={{ overflow: 'auto' }}>
            <DocumentContent withoutImage document={delegatedDocument.document} />
          </div>

          <DelegateDocumentFooter isClient delegatedDocument={[delegatedDocument]} />
        </Flex>
      )}
    </>
  );
};
