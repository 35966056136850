import React, { createContext, PropsWithChildren, useState } from 'react';
import { ConfigProvider } from 'antd';

import { darkTheme, themeColorTypeProvider } from 'utils';
import { ThemeType, ThemeTypes } from 'types';

import { GlobalStyles } from './GlobalStyles';

export const ThemeContext = createContext({
  currentTheme: darkTheme,
  onThemeChange: (type: ThemeTypes) => {}
});

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [theme, setTheme] = useState<ThemeType>(darkTheme);

  const onThemeChange = (type: ThemeTypes) => setTheme(themeColorTypeProvider[type]);

  return (
    <ThemeContext.Provider value={{ currentTheme: theme, onThemeChange: onThemeChange }}>
      <GlobalStyles />

      <ConfigProvider
        theme={{
          token: theme
        }}
      >
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};
