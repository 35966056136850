import { useContext, useState } from 'react';
import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { DeleteConfirmModal, HeadTitle, HeaderActions, Input, MainLayout, Text } from 'components';
import { useFilters, useModalState } from 'hooks';
import { Group, Plus } from 'icons';
import { NotificationContext, ThemeContext } from 'contexts';
import { ClientUserProfile } from 'api/users.types';
import { users, usersApi } from 'api';
import { getNotificationError } from 'utils';
import { PartnerStatuses } from 'components/utils';

import { UserClientFormModal, UsersMenuSection, UsersTable } from '../components';

export interface UsersPageFiltersValue {
  search: string;
  page: number;
  per_page: number;
  state?: PartnerStatuses;
  ordering?: string;
}

export const UsersPage = () => {
  const { t } = useTranslation();
  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);
  const userModal = useModalState<ClientUserProfile>();
  const deleteUserModal = useModalState<ClientUserProfile>();
  const deleteBulkUsersModal = useModalState();
  const queryClient = useQueryClient();

  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

  const { filters, onFiltersChange } = useFilters<UsersPageFiltersValue>(
    {
      search: '',
      page: 1,
      per_page: 8,
      state: undefined
    },
    { keyword: 'client-users-filters' }
  );

  const usersCount = selectedUsers.length;

  const deleteMutation = useMutation(() => users.deleteClient(deleteUserModal.data?.id!), {
    onSuccess: () => {
      deleteUserModal.reset();
      queryClient.invalidateQueries(usersApi.getClientsList().queryKey);

      notification.success({
        message: t('deletedClient'),
        placement: 'bottomRight'
      });
    },
    onError: (error: AxiosError) => notification.error({ message: getNotificationError(error) })
  });

  const deleteBulkMutation = useMutation(() => users.bulkClientDelete({ ids: selectedUsers }), {
    onSuccess: () => {
      queryClient.invalidateQueries(usersApi.getClientsList().queryKey);
      setSelectedUsers([]);
      deleteBulkUsersModal.close();
      notification.success({
        message: t('deletedClients'),
        placement: 'bottomRight'
      });
    }
  });

  return (
    <MainLayout
      pageHeading={
        !usersCount ? (
          <HeadTitle Icon={Group} label={t('users')} />
        ) : (
          <HeaderActions onClear={() => setSelectedUsers([])} onDelete={deleteBulkUsersModal.open}>
            {usersCount > 1
              ? t('selectedElements', {
                  count: usersCount,
                  name: t('accessLevels')
                })
              : t('selectedElement', {
                  count: usersCount,
                  name: t('accessLevel')
                })}
          </HeaderActions>
        )
      }
      pageActions={
        <Space>
          <Input
            prefix="search"
            style={{ width: 420 }}
            placeholder={t('search')}
            value={filters.search}
            onChange={(e) => onFiltersChange({ search: e.target.value })}
          />

          <Button type="primary" onClick={userModal.open}>
            {t('add')}
            <Plus color={currentTheme['grey-white']} fontSize={20} />
          </Button>
        </Space>
      }
      menuSections={[<UsersMenuSection onChange={onFiltersChange} />]}
    >
      <UsersTable
        filters={filters}
        onFiltersChange={onFiltersChange}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        onEdit={userModal.openWith}
        onDelete={deleteUserModal.openWith}
      />

      <UserClientFormModal
        open={userModal.isOpen}
        data={userModal.data}
        onCancel={userModal.reset}
      />

      <DeleteConfirmModal
        title={t('deleteClient')}
        mutation={deleteMutation}
        open={deleteUserModal.isOpen}
        onCancel={() => deleteUserModal.reset()}
      >
        <Text
          style={{
            color: currentTheme['grey-40'],
            fontSize: '0.875rem',
            display: 'block'
          }}
        >
          {t('deleteClientQuestion')}{' '}
          <Text
            style={{
              color: currentTheme['blue-75'],
              fontSize: '0.875rem',
              display: 'block'
            }}
          >
            {deleteUserModal.data?.name}
          </Text>
        </Text>
      </DeleteConfirmModal>

      <DeleteConfirmModal
        title={t('deleteClients')}
        mutation={deleteBulkMutation}
        open={deleteBulkUsersModal.isOpen}
        onCancel={deleteBulkUsersModal.close}
      >
        <Text style={{ color: currentTheme['grey-40'], fontSize: '0.875rem' }}>
          {t('bulkDeleteClientQuestion', { count: selectedUsers?.length })}
        </Text>
      </DeleteConfirmModal>
    </MainLayout>
  );
};
