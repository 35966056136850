/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType) => ({
  Container: css`
    width: 256px;
    min-height: 104vh;
    display: inline-block;
    border-left: 0.5px solid ${theme['grey-70']};
  `,
  DetailsBody: css`
    padding: 20px 32px;
  `,
  DetailsItem: css`
    border-bottom: 0.5px solid ${theme['grey-70']};
    padding: 16px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  `,
  DetailsItemLabel: css`
    display: block;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${theme['grey-50']};
  `,
  DetailsItemValue: css`
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${theme['grey-20']};
    margin-top: 4px;
  `
});
