import { ReactNode, useContext } from 'react';
import { Skeleton, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { TypedUseQueryOptions } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { ThemeContext } from 'contexts';
import { onFilterChange, useQueryParams, useQueryUpdate } from 'hooks';
import { $Object } from 'types';
import { comparePaths } from 'utils';
import { SelectionCollapse } from './SelectionCollapse';

interface QuerySelectionCollapseInterface {
  title: ReactNode;
  paramsKey: string;
  query: (params: $Object) => TypedUseQueryOptions<any>;
  route: string;
  translates: $Object;
  onChange?: onFilterChange<$Object>;
  select?: (data: any) => any;
  params?: $Object;
}

export const QuerySelectionCollapse = ({
  title,
  paramsKey,
  query,
  route,
  translates,
  onChange,
  select,
  params: apiParams
}: QuerySelectionCollapseInterface) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const { currentTheme } = useContext(ThemeContext);

  const LoadingContainer = styled(Space)`
    padding: 0.625rem 1.25rem;
    font-size: 14px;
    color: ${currentTheme['grey-50']};
    justify-content: space-between;
    width: calc(100% - 2.5rem);
  `;

  const { data: stats, isLoading } = useQuery({
    ...query({ ...apiParams }),
    select
  });

  const onDocumentItemClick = (state: string | 'total') => {
    const isTotal = state?.includes('total');

    const updateLocalValues = onChange || updateQuery;

    if (isCurrentPage) {
      if (isTotal) {
        return updateLocalValues({ ...params, [paramsKey]: null, page: 1 });
      }

      return updateLocalValues({ ...params, [paramsKey]: state, page: 1 });
    }

    if (isTotal) {
      return navigate({
        pathname: route,
        search: queryString.stringify({ ...params, page: 1 })
      });
    }

    navigate({
      pathname: route,
      search: queryString.stringify({
        ...params,
        [paramsKey]: state,
        page: 1
      })
    });
  };

  const isCurrentPage = comparePaths(route, pathname);

  const items = Object.entries(stats || {}).map(([key, value]) => ({
    children: (
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        {t(translates[key]?.label)}
        {value as string}
      </Space>
    ),
    value: key
  }));

  return (
    <>
      {isLoading ? (
        <LoadingContainer styles={{ item: { display: 'flex' } }} direction="vertical" size={24}>
          {Array.from({ length: 6 }).map((_, count) => (
            <Skeleton.Button active block key={count} />
          ))}
        </LoadingContainer>
      ) : (
        <SelectionCollapse
          title={title}
          items={items}
          onChange={(e) => onDocumentItemClick(e.target.value)}
          value={isCurrentPage && !params[paramsKey] ? 'total' : params[paramsKey]}
          collapsed={isCurrentPage}
        />
      )}
    </>
  );
};
