import styled from '@emotion/styled';

import { SortUp } from 'icons';
import { palette } from 'utils';

export interface SortingControllerProps {
  active: boolean;
  reverse: boolean;
  onClick: () => void;
}

const StyledSortingController = styled.div<SortingControllerProps>`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: 0.2s;
  cursor: pointer;

  color: ${(props) => (props.active ? 'white' : palette['grey-50'])};

  &:hover {
    color: ${palette['grey-30']};
  }

  .icon {
    color: ${(props) => (props.active ? palette['purple-100'] : 'inherit')};
    transform: ${(props) => (props.reverse ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: 0.2s;
  }
`;

export const SortingController = (props: React.PropsWithChildren<SortingControllerProps>) => {
  const { children } = props;

  return (
    <StyledSortingController {...props}>
      {children}
      <SortUp className="icon" fontSize={16} />
    </StyledSortingController>
  );
};
