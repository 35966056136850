/** @jsxImportSource @emotion/react */
import { APIDocument, Highlight } from 'types';
import { DocumentMapItem } from './DocumentMapItem';
import { stylesInit } from './DocumentContent.styles';

interface DocumentMapProps {
  document: APIDocument;
  images: string[];
}

const transformCoordinates = (item: Highlight, thread_id: string, key: number) => {
  return {
    item: key + 1,
    thread_id,
    width: (item?.coordinates && (item.coordinates[2].x - item.coordinates[0].x) * 100) || 0,
    height: (item?.coordinates && (item.coordinates[2].y - item.coordinates[0].y) * 100) || 0,
    coordinateY: (item?.coordinates && item.coordinates[0].y * 100) || 0,
    coordinateX: (item?.coordinates && item.coordinates[0].x * 100) || 0
  };
};

export const DocumentMap = ({ images, document }: DocumentMapProps) => {
  const styles = stylesInit();
  const coordinates = Object.values(document.comments).map((item, key) => {
    return {
      coordinates: transformCoordinates(item[0].additional_info.highlight, item[0].thread_id, key),
      page_number: item[0].additional_info.highlight?.page_number
    };
  });

  return (
    <div css={styles.Container}>
      {images.map((item, key) => (
        <div key={key} css={styles.ImageDrawItem}>
          <DocumentMapItem
            image={item}
            coordinates={coordinates
              .filter((i) => (i.page_number ? i.page_number === key + 1 : images.length === 1))
              .map((i) => i.coordinates)}
          />
        </div>
      ))}
    </div>
  );
};
