import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { usersApi } from '../api';
import { ActiveUserRole, UserType } from '../types';
import { ClientUserProfile, PartnerUserProfile, SuperUser } from '../api/users.types';
import { isLogged } from '../utils';

interface UserContextInterface {
  data?: ClientUserProfile | SuperUser | PartnerUserProfile;
  isLoading: boolean;
  role: {
    role?: UserType;
  } & ActiveUserRole;
  isAdminOrganization: boolean;
  setRole: Dispatch<SetStateAction<UserType | undefined>>;
}

export const UserContext = createContext<UserContextInterface>({
  role: {
    isAdmin: false,
    isPartner: false,
    isClient: false
  },
  setRole: () => null,
  isAdminOrganization: false,
  isLoading: false
});

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [role, setRole] = useState<UserType>();

  const { data, isLoading } = useQuery({
    ...usersApi.getLoggedUserData(),
    onSuccess: (data) => setRole(data.role),
    enabled: isLogged
  });

  const isAdminOrganization = !!(
    [UserType.admin, UserType.partner].includes(role!) || data?.is_organization_administrator
  );

  return (
    <UserContext.Provider
      value={{
        data,
        isLoading,
        role: {
          role,
          isAdmin: role === UserType.admin,
          isClient: role === UserType.client,
          isPartner: role === UserType.partner
        },
        isAdminOrganization,
        setRole
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
