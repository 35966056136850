/** @jsxImportSource @emotion/react */

import { CSSProperties, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'contexts';
import {
  Check,
  CircleCheckOutline,
  Clock,
  CloseBig,
  CloudClose,
  CloudUp,
  Delegate,
  GlassHour,
  Info2Outline,
  LayersAlt,
  MoreHorizontal,
  Replay,
  Spacer,
  TrashFull
} from 'icons';
import { Text } from '../Typography';
import { DocumentStatus } from 'types';

import { stylesInit } from './DocumentStatusLabel.styles';

interface DocumentStatusLabelInterface {
  status: DocumentStatus;
  style?: CSSProperties;
  unfilled?: boolean;
}

export const DocumentStatusLabel = ({
  status,
  style,
  unfilled = true
}: DocumentStatusLabelInterface) => {
  const { t } = useTranslation();

  const neededProperties = statusPropertiesProvider[status];
  const { currentTheme } = useContext(ThemeContext);

  const styles = stylesInit(currentTheme);
  
  if (!neededProperties) return null;

  return unfilled ? (
    <div
      css={styles}
      className={status}
      style={{
        ...style,
        backgroundColor: 'inherit',
        padding: 0,
        border: 'inherit'
      }}
    >
      {neededProperties?.icon}
      <Text
        ellipsis={{ tooltip: { title: t(neededProperties.label), overlayClassName: 'sm-tooltip' } }}
      >
        {t(neededProperties?.label)}
      </Text>
    </div>
  ) : (
    <div css={styles} className={status} style={style}>
      {neededProperties?.icon ? neededProperties?.icon : null}
      <Text
        ellipsis={{
          tooltip: { title: t(neededProperties?.label), overlayClassName: 'sm-tooltip' }
        }}
        size="md"
      >
        {t(neededProperties?.label)}
      </Text>
    </div>
  );
};

const statusPropertiesProvider = {
  [DocumentStatus.pending]: {
    icon: <MoreHorizontal fontSize={16} />,
    label: 'documentPending'
  },
  [DocumentStatus.uploaded]: {
    icon: <CloudUp fontSize={16} />,
    label: 'documentUploaded'
  },
  [DocumentStatus.processing]: {
    icon: <GlassHour fontSize={16} />,
    label: 'documentProcessing'
  },
  [DocumentStatus.done]: {
    icon: <CircleCheckOutline fontSize={16} />,
    label: 'documentDone'
  },
  [DocumentStatus.needReplacement]: {
    icon: <Replay />,
    label: 'documentNeedReplacement'
  },
  [DocumentStatus.needInfo]: {
    icon: <Info2Outline />,
    label: 'documentNeedInfo'
  },
  [DocumentStatus.failed]: {
    icon: <CloudClose />,
    label: 'documentFailed'
  },
  [DocumentStatus.deleted]: {
    icon: <TrashFull />,
    label: 'documentDeleted'
  },
  [DocumentStatus.rejected]: {
    icon: <CloseBig />,
    label: 'documentRejected'
  },
  [DocumentStatus.merged]: {
    icon: <LayersAlt />,
    label: 'documentMerged'
  },
  [DocumentStatus.split]: {
    icon: <Spacer />,
    label: 'documentSplit'
  },
  [DocumentStatus.total]: {
    icon: <Check />,
    label: 'documentTotal'
  },
  [DocumentStatus.pendingApproval]: {
    icon: <Clock />,
    label: 'pendingApproval'
  },
  [DocumentStatus.delegateApproval]: {
    icon: <Delegate />,
    label: 'delegateApproval'
  }
};
