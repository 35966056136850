import { SortingControllerProps } from './SortingController';

export const makeSortingControllerHelper = (
  ordering: string | undefined,
  setOrdering: React.Dispatch<string | undefined>
) => {
  return (orderBy: string): SortingControllerProps => {
    const isActive = ordering?.replace(/^-/, '') === orderBy;
    const isReverse = isActive && ordering.startsWith('-');
    return {
      active: isActive,
      reverse: isReverse,
      onClick: () => {
        if (isReverse) {
          setOrdering(undefined);
        } else if (isActive) {
          setOrdering('-' + orderBy);
        } else {
          setOrdering(orderBy);
        }
      }
    };
  };
};
