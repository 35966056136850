import { createContext, PropsWithChildren, useMemo } from 'react';
import { notification } from 'antd';
import { NotificationInstance, ArgsProps } from 'antd/lib/notification/interface';
import { CircleCheck, CloseSmall, Error } from 'icons';
import { palette } from 'utils';

export const NotificationContext = createContext({
  notification: {} as NotificationInstance
});

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const [notificationApi, contextHolder] = notification.useNotification({
    placement: 'bottomRight'
  });

  const overridenNotificationApi = useMemo((): NotificationInstance => {
    const closeIcon = <CloseSmall color={palette['grey-50']} fontSize={20} />;
    const open = (args: ArgsProps) => notificationApi.open({ closeIcon, ...args });

    return {
      ...notificationApi,
      open,
      info: (args) => open({ type: 'info', ...args }),
      error: (args) =>
        open({
          type: 'error',
          icon: <Error fontSize={24} color={palette['red-100']} />,
          ...args
        }),
      warning: (args) => open({ type: 'warning', ...args }),
      success: (args) =>
        open({
          type: 'success',
          icon: <CircleCheck fontSize={24} color={palette['green-100']} />,
          ...args
        })
    };
  }, [notificationApi]);

  return (
    <NotificationContext.Provider value={{ notification: overridenNotificationApi }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
