import styled from '@emotion/styled';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { Avatar, Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NotificationDropdown, Dropdown, ChangePasswordModal, LanguageSelect } from 'components';
import { users, usersApi } from 'api';
import { ThemeContext, UserContext } from 'contexts';
import { MainLayoutStyled } from './MainLayoutStyled';
import { getLogOutRedirectRouteName, getRoute, removeSavedTokens } from 'utils';
import { ChevronDown, Forms, LogOut, ShortRight, UserCircle } from 'icons';
import { MenuSection } from './MenuSection';
import { ClientsLabels } from './utils';
import { Legend } from './Legend';
import { Text } from '../Typography';
import { getMainRouteName, getProfileRouteName } from 'utils';
import { UserType } from 'types';
import { routes } from 'routes';
import { MainLayoutInterface } from './MainLayout';

import { ReactComponent as PortalLogo } from 'assets/logos/dark_gradient.svg';

const ContentWrapper = styled.div`
  width: 100%;
  overflow: hidden auto;
`;

export const MainLayoutNEW = ({
  children,
  pageHeading,
  onChange,
  pageActions,
  menuSections
}: PropsWithChildren<MainLayoutInterface>) => {
  const params = useParams<{ domain: string }>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const {
    data: userData,
    isLoading: isLoadingUser,
    role: { isPartner, isClient, isAdmin },
    isAdminOrganization
  } = useContext(UserContext);

  const [headerTitle, setHeaderTitle] = useState(pageHeading);
  const [showResetPassModal, setShowResetPassModal] = useState(false);

  const domain = userData?.domain_prefix || params.domain;

  const { data: clientData } = useQuery({
    ...usersApi.getClientLogo(domain || ''),
    enabled: !!domain
  });

  useEffect(() => {
    setHeaderTitle(pageHeading);
  }, [pageHeading]);

  const Components = useMemo(() => MainLayoutStyled(currentTheme), [currentTheme]);

  const MenuLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    svg {
      font-size: 20px;
      color: ${currentTheme['grey-50']};
    }
  `;

  const MenuSpaceWrapper = useMemo(
    () => styled(Space)`
      width: 100%;
      & > * {
        border-top: 1px solid ${currentTheme['grey-70']};
        &:first-child {
          border-top: none;
        }
      }
    `,
    [currentTheme]
  );

  const onLogOutClick = async () => {
    removeSavedTokens();

    const redirectRouteName = getLogOutRedirectRouteName({
      isAdmin,
      isClient,
      isPartner
    });

    await queryClient.invalidateQueries(usersApi.getLoggedUserData().queryKey);

    removeSavedTokens();

    navigate(getRoute(redirectRouteName, { domain: userData?.domain_prefix }));
  };

  const menuLinks = routes.map(({ menuComponent, path, isAdminOrganization: isAdminOrg }) => {
    if (!menuComponent) return null;
    if (!(isClient || isAdmin || isPartner)) return null;
    if (isClient && menuComponent.userType !== UserType.client) return null;
    if (isPartner && menuComponent.userType !== UserType.partner) return null;
    if (isAdmin && menuComponent.userType !== UserType.admin) return null;
    if (!isAdminOrganization && isAdminOrg) return null;

    return (
      <NavLink
        key={path}
        to={path}
        style={({ isActive }) =>
          isActive
            ? {
                color: currentTheme['grey-white'],
                fontWeight: 600
              }
            : {
                color: currentTheme['grey-40'],
                fontWeight: 400
              }
        }
      >
        {t(menuComponent.title)}
      </NavLink>
    );
  });

  return (
    <div
      style={{
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <Components.Header>
        <Space
          style={{
            justifyContent: 'space-between',
            width: 'calc(100% - 2.5rem)',
            padding: '1.25rem'
          }}
        >
          <Space styles={{ item: { display: 'flex' } }} size={20}>
            <PortalLogo
              onClick={() =>
                navigate(getRoute(getMainRouteName({ isAdmin, isPartner, isClient }), params))
              }
            />
            <Space size={24}>{menuLinks}</Space>
          </Space>
          <Space styles={{ item: { display: 'flex', alignItems: 'baseline' } }} align="center">
            <div style={{ marginRight: 10, display: 'flex' }}>
              <LanguageSelect />
            </div>

            <Legend />

            <NotificationDropdown />

            <Dropdown
              placement="bottomRight"
              overlayStyle={{ minWidth: 'unset' }}
              trigger={['click']}
              menu={{
                items: [
                  {
                    key: '1',
                    label: (
                      <MenuLabel
                        onClick={() =>
                          navigate(
                            getRoute(
                              getProfileRouteName({
                                isAdmin,
                                isPartner,
                                isClient
                              }),
                              params
                            )
                          )
                        }
                      >
                        <UserCircle />
                        {t('yourProfile')}
                      </MenuLabel>
                    )
                  },
                  isClient
                    ? {
                        key: '2',
                        label: (
                          <MenuLabel onClick={() => setShowResetPassModal(true)}>
                            <Forms />
                            {t('changePassword')}
                          </MenuLabel>
                        )
                      }
                    : null,
                  {
                    key: '3',
                    label: (
                      <MenuLabel onClick={onLogOutClick}>
                        <LogOut />
                        {t('logOut')}
                      </MenuLabel>
                    )
                  }
                ]
              }}
            >
              <Space
                style={{
                  color: currentTheme['grey-40'],
                  marginLeft: 20,
                  cursor: 'pointer'
                }}
                styles={{ item: { display: 'flex' } }}
              >
                <Spin spinning={isLoadingUser}>
                  <Text size="lg" style={{ color: currentTheme['grey-white'] }}>
                    {userData?.name}
                  </Text>
                </Spin>
                <ChevronDown fontSize={20} />
              </Space>
            </Dropdown>

            <Avatar src={userData?.logo} shape="square" />
          </Space>
        </Space>
      </Components.Header>

      <Components.Header>
        <Space
          style={{
            justifyContent: 'space-between',
            width: 'calc(100% - 2.5rem)',
            padding: '1.25rem'
          }}
        >
          <Space styles={{ item: { display: 'flex' } }}>
            <Typography.Title level={4} style={{ margin: 0 }}>
              {headerTitle}
            </Typography.Title>
          </Space>

          <Space styles={{ item: { display: 'flex' } }}>{pageActions}</Space>
        </Space>
      </Components.Header>

      <div style={{ display: 'flex', height: 'calc(100% - 166px)' }}>
        <Components.SideBar>
          <Space
            style={{
              justifyContent: 'space-between',
              width: 'calc(100% - 2.5rem)',
              padding: '1.25rem'
            }}
            styles={{ item: { display: 'flex', width: 'auto' } }}
          >
            {clientData?.logo && (
              <Space styles={{ item: { display: 'flex' } }} size={15}>
                <ShortRight fontSize={20} fill={currentTheme['grey-50']} />

                <Avatar src={clientData.logo} shape="square" size={26} />
              </Space>
            )}
          </Space>

          <MenuSpaceWrapper direction="vertical">
            {menuSections}

            {/* {isClient && (
              <>
                <MenuSection
                  title={t("documents").toUpperCase()}
                  query={documentsQueries.getClientStats}
                  paramsKey="documents_state"
                  route={getRoute("DocumentsPage", params)}
                  translates={DocumentsLabels}
                  onChange={onChange}
                />

                <MenuSection
                  title={t("partners").toUpperCase()}
                  query={usersApi.getPartnersStats}
                  paramsKey="state"
                  route={getRoute("PartnersPage", params)}
                  translates={PartnersLabels}
                  onChange={onChange}
                />
              </>
            )} */}
            {/* {isPartner && (
              <MenuSection
                title={t("documents").toUpperCase()}
                query={documentsQueries.getPartnerStats}
                paramsKey="documents_state"
                route={getRoute("PartnerDocumentsPage", params)}
                translates={DocumentsLabels}
                onChange={onChange}
              />
            )} */}
            {isAdmin && (
              <MenuSection
                title={t('clients').toUpperCase()}
                query={usersApi.getClientsStats}
                paramsKey="clients_state"
                route={getRoute('ClientsPage', params)}
                translates={ClientsLabels}
                onChange={onChange}
              />
            )}
          </MenuSpaceWrapper>
        </Components.SideBar>

        <ContentWrapper>
          {children}

          <ChangePasswordModal
            open={showResetPassModal}
            setOpen={setShowResetPassModal}
            call={users.requestClientPassword}
          />
        </ContentWrapper>
      </div>
    </div>
  );
};
