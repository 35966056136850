/** @jsxImportSource @emotion/react */

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { NotFound, Window } from 'icons';
import { ThemeContext, UserContext } from 'contexts';
import { getRoute, isLogged } from 'utils';

import { stylesInit } from 'components/NotFoundPage.styles';

import { ReactComponent as PortalLogo } from 'assets/logos/dark_gradient_text.svg';

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    role: { isPartner, isClient, isAdmin }
  } = useContext(UserContext);
  const { currentTheme } = useContext(ThemeContext);
  const styles = stylesInit(currentTheme);

  const getMainRouteName = () => {
    if (isAdmin) return 'ClientsPage';

    if (isClient) return 'ClientProfilePage';

    if (isPartner) return 'PartnerDocumentsPage';

    return 'LogInPage';
  };

  return (
    <div css={styles.notFoundBgImage}>
      <Space
        style={{
          justifyContent: 'space-between',
          width: 'calc(100% - 2.5rem)',
          padding: '1.25rem',
          cursor: 'pointer'
        }}
        styles={{ item: { display: 'flex' } }}
      >
        <PortalLogo onClick={() => navigate(getRoute(getMainRouteName()))} />
      </Space>

      <div css={styles.notFoundContainer}>
        <Space css={styles.notFoundWrapper} size={40}>
          <div css={styles.iconWrapper}>
            <Window fontSize={80} color={currentTheme['purple-125']} />
            <div css={styles.notFoundCode}>
              <NotFound />
            </div>
          </div>

          <div style={{ textAlign: 'center' }}>
            <Typography.Title level={1} style={{ margin: '0 0 8px 0' }}>
              {t('notFoundPageTitle')}
            </Typography.Title>

            <Typography.Text
              style={{
                textAlign: 'center',
                fontSize: '16px',
                color: `${currentTheme['grey-40']}`
              }}
            >
              {t('notFoundPageDescription')}
            </Typography.Text>
          </div>

          {isLogged && <Button onClick={() => navigate(-1)}>{t('backToDashboard')}</Button>}
        </Space>
      </div>
    </div>
  );
};
