/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Space } from 'antd';

import { ShortLeft } from 'icons';
import { getRoute, palette } from 'utils';
import { useFilters } from 'hooks';
import { ClientDocumentsFiltersValue } from 'types';

import { DocumentsContentWrapper } from '../components';

export const GroupPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { filters, onFiltersChange } = useFilters<ClientDocumentsFiltersValue>(
    {
      search: '',
      page: 1,
      per_page: 8,
      documents_state: undefined,

      ordering: undefined,
      sent_at_after: '',
      sent_at_before: ''
    },
    { keyword: 'client-documents-filters' }
  );

  return (
    <DocumentsContentWrapper
      showFolderColumn={false}
      filters={filters}
      onFiltersChange={onFiltersChange}
      pageHeading={
        <Space styles={{ item: { display: 'flex' } }}>
          <Link
            onClick={(e) => {
              if (window.history.length > 1) {
                e.preventDefault();
                navigate(-1);
              }
            }}
            css={css`
              display: inline-flex;
            `}
            to={getRoute('PartnerDocumentsPage', params)}
          >
            <ShortLeft color={palette['purple-100']} />
          </Link>
          {params.group}
        </Space>
      }
    />
  );
};
