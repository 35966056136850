/** @jsxImportSource @emotion/react */

import { CSSProperties, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { PartnerStatuses } from '../utils';
import { Text } from '../Typography';

import { ThemeContext } from 'contexts';
import { Check, GlassHour, CloseBig, CircleCheckOutline, TrashFull } from 'icons';
import { UserStatuses } from 'types';

import { stylesInit } from './statusLabel.styles';

interface UserStatusInterface {
  status?: UserStatuses;
  style?: CSSProperties;
  unfilled?: boolean;
}

export const UserStatusLabel = ({
  status = UserStatuses.inactive,
  style,
  unfilled = true
}: UserStatusInterface) => {
  const { t } = useTranslation();
  const mapping = useMemo(() => getStatusMapping(t), [t]);
  const neededProperties = mapping[status];
  const { currentTheme } = useContext(ThemeContext);

  const styles = stylesInit(currentTheme);

  return unfilled ? (
    <div
      css={styles}
      className={status}
      style={{
        ...style,
        backgroundColor: 'inherit',
        padding: 0,
        border: 'inherit'
      }}
    >
      {neededProperties.icon} <Text>{t(neededProperties.label)}</Text>
    </div>
  ) : (
    <div css={styles} className={status} style={style}>
      {neededProperties.icon} <Text>{t(neededProperties.label)}</Text>
    </div>
  );
};

const getStatusMapping = (t: TFunction) => ({
  [PartnerStatuses.active]: {
    icon: <CircleCheckOutline fontSize="1rem" />,
    label: t('activeUser')
  },
  [PartnerStatuses.pending]: {
    icon: <GlassHour fontSize="1rem" />,
    label: t('pendingUser')
  },
  [PartnerStatuses.inactive]: {
    icon: <CloseBig fontSize="1rem" />,
    label: t('inactiveUser')
  },
  [PartnerStatuses.deleted]: {
    icon: <TrashFull fontSize="1rem" />,
    label: t('deletedUserLabel')
  },
  [PartnerStatuses.total]: {
    icon: <Check fontSize="1rem" />,
    label: t('totalUser')
  }
});
