export enum UserStatuses {
  active = 'active',
  pending = 'pending',
  inactive = 'inactive',
  deleted = 'deleted'
}

export enum UserType {
  admin = 'admin',
  client = 'client',
  partner = 'partner'
}

export type ActiveUserRole = {
  isAdmin: boolean;
  isClient: boolean;
  isPartner: boolean;
};
