/** @jsxImportSource @emotion/react */
import { APIDocument } from 'types';
import { stylesInit } from './DocumentContent.styles';

interface DocumentImageProps {
  document: APIDocument;
}

export const DocumentImage = ({ document }: DocumentImageProps) => {
  const styles = stylesInit();

  return (
    <div css={styles.Container}>
      <img src={document.file} alt={document.title} />
    </div>
  );
};
