import { PropsWithChildren, ReactNode, useContext } from 'react';

import { onFilterChange } from 'hooks';
import { $Object } from 'types';
import { UserContext } from 'contexts';
import { FullpageSpinner } from 'components';

import { MainLayoutNEW } from './MainLayoutNEW';
import { MainLayoutOLD } from './MainLayoutOLD';

export interface MainLayoutInterface {
  filters?: $Object;
  pageHeading?: ReactNode;
  onChange?: onFilterChange<$Object>;
  pageActions?: ReactNode;
  menuSections?: ReactNode;
}

export const MainLayout = ({ ...props }: PropsWithChildren<MainLayoutInterface>) => {
  const {
    role: { isClient },
    isLoading
  } = useContext(UserContext);

  if (isLoading) return <FullpageSpinner />;

  return isClient ? <MainLayoutNEW {...props} /> : <MainLayoutOLD {...props} />;
};
