import styled from '@emotion/styled';

import { TooltipProps } from 'recharts';

import { Text, Title } from 'components/Typography';
import { palette } from 'utils';

export interface CustomTooltipProps extends TooltipProps<number, string> {
  staticLabel?: string;
}

const StyledCustomTooltip = styled.div`
  .label {
    color: ${palette['grey-60']};
  }

  .value {
    margin: 0 !important;
    color: black !important;
  }

  background-color: ${palette['grey-white']};
  padding: 12px 16px;
  border-radius: 16px;
  border-bottom-left-radius: 0px;
`;

export const CustomTooltip = ({ active, payload, label, staticLabel }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <StyledCustomTooltip>
        <Text size="sm" strong className="label">
          {staticLabel || label}
        </Text>
        <Title level={2} className="value">
          {payload[0].value}
        </Title>
      </StyledCustomTooltip>
    );
  }

  return null;
};
