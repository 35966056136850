import { axios } from './axios';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { WithPagination, Notification } from 'types';

export const notifications = {
  getList: async (params?: {
    search?: string;
    ordering?: string;
    page?: number;
    per_page?: number;
  }) => {
    const { data } = await axios.get<WithPagination<Notification>>('/notifications/', {
      params
    });

    return data;
  },
  patchMarkAllAsRead: async () => {
    const response = await axios.patch('/notifications/mark-all-as-read');

    return response.data;
  },
  patch: async (data: { id: number; is_read: boolean }) => {
    const response = await axios.patch<Notification>(`/notifications/${data.id}`, {
      is_read: data.is_read
    });

    return response.data;
  }
};

export const notificationsQueries = createQueryKeys('notification', {
  getList: (params = {}) => ({
    queryKey: [params],
    queryFn: () => notifications.getList(params)
  })
});
