import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import { Button, Divider } from 'antd';
import { Input, Text, Modal, ModalProps, Flex, DocumentTypeIcon, FoldersTree } from 'components';
import { PlusCircleOutline } from 'icons';
import { documents, documentsQueries } from 'api';
import { NotificationContext, ThemeContext, UserContext } from 'contexts';
import { getNotificationError, mimeTypeToDocumentType } from 'utils';
import { stylesInit as moveDocumentToFolderStylesInit } from 'components/MoveDocumentToFolderModal/styles';
import { APIDocument } from 'types';
import { FolderLabel } from './FolderLabel';

export interface MoveDocumentToFolderModalProps extends ModalProps {
  documentsToMove?: APIDocument[];
  onCancel: () => void;
  onMoveFolder: Dispatch<SetStateAction<APIDocument[]>>;
}

export const MoveDocumentToFolderModal = ({
  title,
  open,
  documentsToMove,
  onCancel,
  onMoveFolder,
  ...props
}: MoveDocumentToFolderModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { notification } = useContext(NotificationContext);
  const { currentTheme } = useContext(ThemeContext);
  const {
    isLoading,
    role: { isPartner }
  } = useContext(UserContext);

  const directoryId = isPartner
    ? documentsToMove?.[0]?.directory?.id
    : documentsToMove?.[0]?.directory?.client_directory?.id!;

  const { data: foldersData } = useQuery({
    ...documentsQueries.getDirectoryById(directoryId!),
    enabled: !!directoryId && !isLoading
  });

  const [folderNameValue, setFolderNameValue] = useState('');

  const [isAddingNewFolder, setIsAddingNewFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [folderId, setFolderId] = useState<number>();
  const [folderTitle, setFolderTitle] = useState<string>();

  const isMultipleFiles = documentsToMove && documentsToMove?.length > 1;
  const documentsToMoveKeys = documentsToMove?.map(({ id }) => id);

  const moveDocumentToFolderStyles = moveDocumentToFolderStylesInit(currentTheme);

  const createFolderMutation = useMutation(documents.createDirectory, {
    onSuccess: () => {
      notification.success({
        message: t('folderCreateNotification'),
        placement: 'topRight'
      });

      invalidateDocumentQueries();
    },

    onError: (error: AxiosError) => {
      notification.error({
        message: getNotificationError(error)
      });
    }
  });

  const onAddFolder = () => {
    createFolderMutation.mutate({ name: newFolderName, parent_id: folderId });
    setIsAddingNewFolder(false);
    setNewFolderName('');
  };

  const onCancelAddingFolderName = () => {
    setIsAddingNewFolder(false);
    setNewFolderName('');
  };

  const onClickCancel = () => {
    onCancel();
    setNewFolderName('');
    setIsAddingNewFolder(false);
  };

  const invalidateDocumentQueries = () => {
    queryClient.invalidateQueries(documentsQueries.getDirectoriesSearchList().queryKey);
    queryClient.invalidateQueries(documentsQueries.getDirectoriesList().queryKey);
    queryClient.invalidateQueries(documentsQueries.getPartnerStats().queryKey);
    queryClient.invalidateQueries(documentsQueries.getList().queryKey);
  };

  const moveToFolderMutation = useMutation(
    (folderId: number) =>
      documents.changeFolder({
        ids: documentsToMoveKeys ? documentsToMoveKeys : [],
        folder_id: folderId
      }),
    {
      onSuccess: () => {
        notification.success({
          message: t('movedToFolderNotification', { folder: folderTitle }),
          placement: 'topRight'
        });

        onMoveFolder?.([]);
        invalidateDocumentQueries();
        onClickCancel();
      },

      onError: (error: AxiosError) => {
        notification.error({
          message: getNotificationError(error)
        });
      }
    }
  );

  return (
    <Modal
      title={
        <Flex direction="column" style={moveDocumentToFolderStyles.folderModalHeader}>
          {title}

          {!isMultipleFiles && (
            <Flex gap={4} align="center" style={{ marginTop: '8px' }}>
              <DocumentTypeIcon
                size={1}
                type={mimeTypeToDocumentType?.(documentsToMove?.[0]?.title)}
              />

              <Text
                lines="1"
                ellipsis={{
                  tooltip: { title: documentsToMove?.[0]?.title, overlayClassName: 'sm-tooltip' }
                }}
              >
                {documentsToMove?.[0]?.title}
              </Text>
            </Flex>
          )}
        </Flex>
      }
      open={open}
      onCancel={onClickCancel}
      destroyOnClose
      centered
      footer={
        <Flex justify="space-between" align="center">
          <Button type="link" onClick={onClickCancel}>
            {t('cancel')}
          </Button>

          <Button
            type="primary"
            loading={moveToFolderMutation.isLoading}
            disabled={!folderId}
            onClick={() => folderId && moveToFolderMutation.mutate(folderId)}
          >
            {t('move')}
          </Button>
        </Flex>
      }
      {...props}
      className="move-document-modal"
    >
      {!isMultipleFiles && (
        <Flex direction="column" gap={8}>
          <Text size="sm">{t('fromFolder')}</Text>

          <FolderLabel name={foldersData?.name ?? ''} />
        </Flex>
      )}

      <Divider />

      <Flex align="start" direction="column" gap={8}>
        <Flex direction="column" gap={8}>
          <Flex direction="column" gap={8}>
            <Text size="sm">{t('toFolder')}</Text>

            <Input
              placeholder={t('searchInputPlaceholder')}
              suffix="search"
              value={folderNameValue}
              onChange={(e) => setFolderNameValue(e.target.value)}
            />
          </Flex>

          <FoldersTree
            onSelectTitle={(title) => setFolderTitle(title?.toString())}
            onKeyChange={(id) => setFolderId(id ? +id : undefined)}
            search={folderNameValue}
          />
        </Flex>

        {isAddingNewFolder ? (
          <Flex gap={8} direction="column">
            <Input
              placeholder={t('addNewFolderPlaceholder')}
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
            />

            <Flex gap={8}>
              <Button
                loading={createFolderMutation.isLoading}
                type="primary"
                onClick={onAddFolder}
                disabled={newFolderName.trim().length === 0}
              >
                Add
              </Button>

              <Button type="link" onClick={onCancelAddingFolderName}>
                Cancel
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Button
            type="link"
            icon={<PlusCircleOutline />}
            onClick={() => setIsAddingNewFolder(true)}
          >
            {t('addFolder')}
          </Button>
        )}
      </Flex>

      <Divider />
    </Modal>
  );
};
