import { useTranslation } from 'react-i18next';

import { onFilterChange } from 'hooks';
import { FilterContent, FoldersTree } from 'components';
import { $Object } from 'types';
import { Folder } from 'icons';

interface FoldersTreeMenuSectionInterface {
  filters: $Object;
  onFiltersChange?: onFilterChange<$Object>;
  userId?: string | number;
}

export const FoldersTreeMenuSection = ({
  filters,
  onFiltersChange,
  userId
}: FoldersTreeMenuSectionInterface) => {
  const { t } = useTranslation();

  const active = filters.documents_archive;

  return (
    <FilterContent
      active={active}
      onClick={() =>
        onFiltersChange?.({
          directory: null,
          documents_state: null,
          partner_ids: null,
          documents_archive: !active,
          
          '--selectedTree': null
        })
      }
      icon={<Folder />}
      title={t('archive').toUpperCase()}
    >
      <FoldersTree
        onKeyChange={(key, selectedTree) =>
          onFiltersChange?.({
            directory: key,
            documents_state: null,
            // partner_ids: null,
            documents_archive: true,

            '--selectedTree': selectedTree
          })
        }
        selectedKey={filters.directory}
        userId={userId}
      />
    </FilterContent>
  );
};
