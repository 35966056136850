import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

export const CommentsContext = createContext<{
  activeThread?: string;
  setActiveThread: Dispatch<SetStateAction<string | undefined>>;
}>({
  activeThread: undefined,
  setActiveThread: () => {}
});

export const CommentsProvider = ({ children }: PropsWithChildren) => {
  const [activeThread, setActiveThread] = useState<string>();
  return (
    <CommentsContext.Provider value={{ activeThread, setActiveThread }}>
      {children}
    </CommentsContext.Provider>
  );
};
