/** @jsxImportSource @emotion/react */

import { useContext, useState } from 'react';
import { Divider, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { FileBlankOutline, Group, HelpCircleOutline } from 'icons';
import { DocumentStatusLabel } from '../DocumentStatusLabel/DocumentStatusLabel';
import { Dropdown } from '../Dropdown';
import { ThemeContext, UserContext } from 'contexts';
import { Paragraph, Text, Title } from '../Typography';
import { stylesInit } from './Legend.styles';
import { DocumentStatus, UserStatuses } from 'types';
import { UserStatusLabel } from '../UserStatusLabel/UserStatusLabel';

export const Legend = () => {
  const { currentTheme } = useContext(ThemeContext);
  const {
    role: { isAdmin, isClient, isPartner }
  } = useContext(UserContext);
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const styles = stylesInit(currentTheme);

  return (
    <Dropdown
      trigger={['click']}
      dropdownRender={() => (
        <div css={styles.DropdownWrapper}>
          <Title level={5} style={{ margin: 0, marginBottom: 24 }}>
            {t('legend')}
          </Title>
          <Space size={0} align="start" styles={{ item: { height: '100%' } }}>
            {!isAdmin && (
              <div style={{ width: `272px` }}>
                <Space
                  css={styles.SectionTitle}
                  styles={{ item: { display: 'flex' } }}
                  style={{ marginBottom: '16px' }}
                >
                  <FileBlankOutline />
                  <Text size="sm">{t('documents')}</Text>
                </Space>
                {isPartner && (
                  <>
                    <DocumentStatusLabel
                      status={DocumentStatus.pendingApproval}
                      style={{ marginBottom: 8 }}
                    />
                    <Paragraph css={styles.SectionParagraph}>{t('documentIsPending')}</Paragraph>
                    <Divider style={{ margin: '16px 0' }} />
                    <DocumentStatusLabel
                      status={DocumentStatus.delegateApproval}
                      style={{ marginBottom: 8 }}
                    />
                    <Paragraph css={styles.SectionParagraph}>{t('documentWasDelegated')}</Paragraph>
                    <Divider style={{ margin: '16px 0' }} />
                  </>
                )}
                <DocumentStatusLabel status={DocumentStatus.pending} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>{t('documentIsUploading')}</Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <DocumentStatusLabel status={DocumentStatus.uploaded} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>{t('documentWasUploaded')}</Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <DocumentStatusLabel
                  status={DocumentStatus.processing}
                  style={{ marginBottom: 8 }}
                />
                <Paragraph css={styles.SectionParagraph}>{t('documentIsProcessing')}</Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <DocumentStatusLabel status={DocumentStatus.done} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>{t('documentProcessed')}</Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <DocumentStatusLabel status={DocumentStatus.needInfo} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>
                  {t('documentNeedInfoDescription')}
                </Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <DocumentStatusLabel
                  status={DocumentStatus.needReplacement}
                  style={{ marginBottom: 8 }}
                />
                <Paragraph css={styles.SectionParagraph}>
                  {t('documentNeedReplacementDescription')}
                </Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <DocumentStatusLabel status={DocumentStatus.rejected} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>{t('documentNotNeeded')}</Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <DocumentStatusLabel status={DocumentStatus.failed} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>{t('documentCantBePassed')}</Paragraph>
              </div>
            )}

            {isClient && <Divider type="vertical" style={{ margin: '0 24px', height: '100%' }} />}

            {isClient && (
              <div style={{ width: `272px` }}>
                <Space
                  css={styles.SectionTitle}
                  styles={{ item: { display: 'flex' } }}
                  style={{ marginBottom: '16px' }}
                >
                  <Group />
                  <Text size="sm">{t('partners').toUpperCase()}</Text>
                </Space>
                <Divider style={{ margin: '8px 0' }} />
                <UserStatusLabel status={UserStatuses.active} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>{t('partnerActiveDescription')}</Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <UserStatusLabel status={UserStatuses.pending} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>
                  {t('partnerPendingDescription')}
                </Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <UserStatusLabel status={UserStatuses.inactive} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>
                  {t('partnerInactiveDescription')}
                </Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <UserStatusLabel status={UserStatuses.deleted} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>
                  {t('partnerDeletedDescription')}
                </Paragraph>
              </div>
            )}

            {isAdmin && (
              <div style={{ width: `272px` }}>
                <Space
                  css={styles.SectionTitle}
                  styles={{ item: { display: 'flex' } }}
                  style={{ marginBottom: '16px' }}
                >
                  <Group />
                  <Text size="sm">{t('clients').toUpperCase()}</Text>
                </Space>
                <UserStatusLabel status={UserStatuses.active} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>{t('clientActiveDescription')}</Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <UserStatusLabel status={UserStatuses.pending} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>{t('clientPendingDescription')}</Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <UserStatusLabel status={UserStatuses.inactive} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>
                  {t('clientInactiveDescription')}
                </Paragraph>
                <Divider style={{ margin: '16px 0' }} />
                <UserStatusLabel status={UserStatuses.deleted} style={{ marginBottom: 8 }} />
                <Paragraph css={styles.SectionParagraph}>{t('clientDeletedDescription')}</Paragraph>
              </div>
            )}
          </Space>
        </div>
      )}
      onOpenChange={(open) => setIsOpen(open)}
      css={{ cursor: 'pointer' }}
    >
      <HelpCircleOutline
        fill={isOpen ? currentTheme['colorPrimary'] : currentTheme['grey-50']}
        fontSize={20}
      />
    </Dropdown>
  );
};
