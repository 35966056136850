import { useTranslation } from 'react-i18next';

import { onFilterChange } from 'hooks';
import { FilterContent, RangeDataFilter } from 'components';
import { ClientDocumentsFiltersValue } from 'types';
import { UploadOutline } from 'icons';

interface UploadedDocumentsMenuSectionInterface {
  filters: ClientDocumentsFiltersValue;
  onFiltersChange: onFilterChange<ClientDocumentsFiltersValue>;
}

export const UploadedDocumentsMenuSection = ({
  filters,
  onFiltersChange
}: UploadedDocumentsMenuSectionInterface) => {
  const { t } = useTranslation();

  return (
    <FilterContent icon={<UploadOutline />} title={t('uploaded').toUpperCase()}>
      <RangeDataFilter
        filters={filters}
        onFiltersChange={onFiltersChange}
        afterKey="sent_at_after"
        beforeKey="sent_at_before"
      />
    </FilterContent>
  );
};
