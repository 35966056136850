import $axios from 'axios';
import { load, save } from 'react-cookies';
import { session } from './session';
import { getRoute, removeSavedTokens } from '../utils';

export const axios = $axios.create();

axios.defaults.headers['Accept-Language'] = load('localization') || 'en';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

let isRefreshing = false;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if ($axios.isCancel(error)) {
      return Promise.reject({
        cancel: true,
        message: 'The endpoint was cancelled'
      });
    }

    if (error.response) {
      if (error.response.status === 401) {
        const refreshToken = load('--refresh-token--');

        if (!isRefreshing && refreshToken && refreshToken !== 'undefined') {
          isRefreshing = true;

          return await session
            .tokenRefresh({ refresh: refreshToken })
            .then((res) => {
              save('--access-token--', res.access, { path: '/' });

              isRefreshing = false;

              window.location.reload();
            })
            .catch((err) => {
              console.log(err, 'an error ocured while refreshing token');

              removeSavedTokens();

              window.location.replace(getRoute('BasePage'));
            });
        }

        if (!isRefreshing && !refreshToken) {
          window.location.replace(getRoute('BasePage'));
        }

        console.error('No refresh token was found');

        return Promise.reject(error.response);
      }

      return Promise.reject(error.response);
    }

    return Promise.reject(error);
  }
);

export const axiosHeadersUpdater = (): void => {
  const token = load('--access-token--');

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else if (axios.defaults.headers.common.Authorization) {
    delete axios.defaults.headers.common.Authorization;
  }
};
