import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

export interface StatusTagProps {
  icon: ReactNode;
  /**
   * Should be in hex format, for background is appended opacity `#aabbcc` -> `#aabbcc33`
   */
  color: string;

  /**
   * Override the default background color behavior
   */
  backgroundColor?: string;

  small?: boolean;
}

const StyledStatusTag = styled.div<StatusTagProps>`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  padding: ${(props) => (props.small ? '4px 10px 4px 6px' : '6px 12px 6px 6px')};

  font-size: ${(props) => (props.small ? '12px' : '14px')};
  line-height: ${(props) => (props.small ? '16px' : '18px')};

  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor || props.color + '33'}; /* 20% opacity */
`;

export const StatusTag = (props: React.PropsWithChildren<StatusTagProps>) => {
  const { icon, children } = props;
  return (
    <StyledStatusTag {...props}>
      {icon}
      {children}
    </StyledStatusTag>
  );
};
