import styled from '@emotion/styled';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Space, Spin } from 'antd';
import { documents, documentsQueries } from 'api';
import { AxiosError } from 'axios';
import { DocumentTypeIcon, Text } from 'components';
import { NotificationContext, ThemeContext, UserContext } from 'contexts';
import { Edit, FolderFill } from 'icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { Directory, DirectoryAction, DirectoryType } from 'types';
import { getNotificationError } from 'utils';

interface EditFolderNameProps {
  id: number;
  type: Directory['type'];
  userId: number;
  allowEdit?: boolean;
}

const StyledText = styled(Text)`
  margin: 0 !important;
  inset-inline-start: 0 !important;
`;

export const EditFolderName = ({
  id,
  type,
  userId,
  children,
  allowEdit: defaultAllowEdit = true
}: React.PropsWithChildren<EditFolderNameProps>) => {
  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);
  const { isAdminOrganization, data } = useContext(UserContext);

  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState<string>();
  const isClickEnter = useRef(false);
  const queryClient = useQueryClient();

  const notifyError = (error: AxiosError) => {
    notification.error({
      message: getNotificationError(error)
    });
  };

  const handleSuccess = () => [
    queryClient.invalidateQueries(documentsQueries.getDirectoriesList().queryKey),
    queryClient.invalidateQueries(documentsQueries.getDirectoriesSearchList().queryKey)
  ];

  const editMutation = useMutation((body: DirectoryAction) => documents.editDirectory(id, body), {
    onError: notifyError,
    onSuccess: handleSuccess
  });

  useEffect(() => {
    if (!value || !isClickEnter.current) return;

    editMutation.mutate({ name: value });

    isClickEnter.current = false;
  }, [value, isClickEnter, editMutation]);

  const allowEdit = !isAdminOrganization ? userId === data?.id : defaultAllowEdit;

  return (
    <Space
      align="center"
      onClick={(e) => {
        editing && e.preventDefault();
      }}
    >
      <DocumentTypeIcon bgColor={currentTheme['grey-40']} icon={<FolderFill />} />
      <Spin spinning={editMutation.isLoading}>
        <StyledText
          size="lg"
          editable={
            type === DirectoryType.SIMPLE && allowEdit
              ? {
                  editing,
                  icon: <Edit color={currentTheme['grey-white']} />,
                  onStart: () => setEditing(true),
                  onChange: (value) => {
                    setValue(value);
                    setEditing(false);
                  },
                  tooltip: false,
                  onEnd: () => (isClickEnter.current = true)
                }
              : false
          }
        >
          {children}
        </StyledText>
      </Spin>
    </Space>
  );
};
