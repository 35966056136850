import { css } from '@emotion/react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType) => css`
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  width: fit-content;
  padding: 6px 12px 6px 6px;

  &.active {
    color: ${theme['green-100']};
    background: rgba(57, 198, 116, 0.2);
  }

  &.pending {
    color: ${theme['grey-40']};
    background-color: ${theme['grey-80']};
    border: 0.5px solid ${theme['grey-60']};
    font-size: 14px;
  }

  &.inactive {
    color: #fc8303;
    background: rgba(252, 131, 3, 0.2);
  }

  &.uploaded {
    color: ${theme['purple-100']};
    background-color: ${theme['purple-100']}20;
  }

  &.processing {
    color: ${theme['orange-100']};
    background-color: ${theme['orange-100']}20;
  }

  &.done {
    color: ${theme['green-100']};
    background-color: ${theme['green-100']}20;
  }

  &.need_info,
  &.merged,
  &.split {
    color: ${theme['yellow-100']};
    background-color: ${theme['yellow-100']}20;
  }

  &.need_replacement {
    color: ${theme['blue-75']};
    background-color: ${theme['blue-100']}20;
  }

  &.failed,
  &.rejected {
    color: ${theme['red-100']};
    background-color: ${theme['red-100']}20;
  }

  &.deleted {
    color: ${theme['blue-100']};
    background-color: ${theme['blue-100']}20;
  }
`;
