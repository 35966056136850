import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'contexts';
import { CircleCheckOutline, CloseCircle, GlassHour, TrashFull } from 'icons';
import { ThemeType, UserStatuses } from 'types';

import { StatusTag, Text } from '../.';

interface AccessLevelStatusLabelInterface {
  status: UserStatuses;
}

export const AccessLevelStatusLabel = ({ status }: AccessLevelStatusLabelInterface) => {
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const neededProperties = statusPropertiesProvider(currentTheme)[status];

  return (
    <StatusTag
      icon={neededProperties?.icon ? neededProperties.icon : null}
      color={neededProperties.color}
      backgroundColor="transparent"
    >
      <Text size="md">{t(neededProperties?.label).toUpperCase()}</Text>
    </StatusTag>
  );
};

export const statusPropertiesProvider = (theme: ThemeType) => ({
  [UserStatuses.active]: {
    icon: <CircleCheckOutline />,
    label: 'activeUser',
    color: theme['green-100']
  },
  [UserStatuses.inactive]: {
    icon: <CloseCircle />,
    label: 'inactiveUser',
    color: theme['grey-60']
  },
  [UserStatuses.deleted]: {
    icon: <TrashFull />,
    label: 'deletedUserLabel',
    color: theme['red-100']
  },
  [UserStatuses.pending]: {
    icon: <GlassHour fontSize="1rem" />,
    label: 'pendingUser',
    color: '#BBE900'
  }
});
