import styled from '@emotion/styled';

import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PublicSectionBackground } from 'assets/svg/public-section-background.svg';
import { AutomationsOutline, CursorDefaultClick, Show } from 'icons';

import { DescriptionStyledComponents } from './DescriptionStyledComponents';

export const Description = () => {
  const { t } = useTranslation();

  const Components = DescriptionStyledComponents();

  return (
    <Components.DescriptionContainer>
      <Components.TitleWrapper>
        <Typography.Title level={1} style={{ margin: '0 0 1em 0' }}>
          SMART PORTAL
        </Typography.Title>
        <Typography.Text style={{ fontSize: '1.25rem' }}>{t('portalDescription1')}</Typography.Text>
      </Components.TitleWrapper>
      <Components.BorderedSection>
        <Space align="start" style={{ width: '100%', justifyContent: 'space-between' }}>
          <div>
            <Typography.Title level={4} style={{ margin: '0 0 0.5rem 0' }}>
              {t('portalDescription2')}
            </Typography.Title>
            <Typography.Text style={{ display: 'block' }}>
              {t('portalDescription3')}
            </Typography.Text>
          </div>

          <div>
            <AutomationsOutline fontSize={24} />
          </div>
        </Space>
      </Components.BorderedSection>

      <Components.BorderedSection>
        <Space align="start" style={{ width: '100%', justifyContent: 'space-between' }}>
          <div>
            <Typography.Title level={4} style={{ margin: '0 0 0.5rem 0' }}>
              {t('portalDescription4')}
            </Typography.Title>
            <Typography.Text style={{ display: 'block' }}>
              {t('portalDescription5')}
            </Typography.Text>
          </div>

          <div>
            <Show fontSize={24} />
          </div>
        </Space>
      </Components.BorderedSection>

      <Components.BorderedSection>
        <Space align="start" style={{ width: '100%', justifyContent: 'space-between' }}>
          <div>
            <Typography.Title level={4} style={{ margin: '0 0 0.5rem 0' }}>
              {t('portalDescription6')}
            </Typography.Title>
            <Typography.Text style={{ display: 'block' }}>
              {t('portalDescription7')}
            </Typography.Text>
          </div>

          <div>
            <CursorDefaultClick fontSize={24} />
          </div>
        </Space>
      </Components.BorderedSection>

      <StyledPublicSectionBackground style={{}} />
    </Components.DescriptionContainer>
  );
};

const StyledPublicSectionBackground = styled(PublicSectionBackground)`
  width: 710px;
  top: -212px;
  left: -34px;
  height: 1244px;
  position: absolute;
  opacity: 0.5;
  mix-blend-mode: soft-light;
`;
