import { Col, Row, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { CommentsProvider } from 'contexts';
import { ShortLeft } from 'icons';
import {
  MainLayout,
  ActionHeadTitle,
  DocumentHeader,
  DocumentContent,
  DocumentComments,
  DelegateDocumentFooter
} from 'components';
import { documentsQueries } from 'api';
import { DocumentsMenuSection } from 'features/common/components';

export const DocumentItemPage = () => {
  const { id } = useParams();

  const { data: document, isLoading } = useQuery({
    ...documentsQueries.getById(Number(id)),
    enabled: !!id
  });

  return (
    <MainLayout
      pageHeading={document?.title && <ActionHeadTitle Icon={ShortLeft} label={document.title} />}
      menuSections={[<DocumentsMenuSection />]}
    >
      {document && (
        <Spin spinning={isLoading}>
          <DocumentHeader document={document} />
          <CommentsProvider>
            <Row align="top" justify="space-between">
              <Col flex={1}>
                <DocumentContent document={document} />
              </Col>
              <Col>
                <DocumentComments document={document} />
              </Col>
            </Row>
          </CommentsProvider>

          {!!document?.delegated_approvals?.length && (
            <DelegateDocumentFooter delegatedDocument={document.delegated_approvals} />
          )}
        </Spin>
      )}
    </MainLayout>
  );
};
