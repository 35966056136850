import { useQuery } from '@tanstack/react-query';

import { PropsWithChildren, ReactNode, useContext } from 'react';
import { Avatar, Space } from 'antd';
import { useParams } from 'react-router-dom';

import { ShortRight } from 'icons';
import { ThemeContext } from 'contexts';
import { usersApi } from 'api';

import { ReactComponent as PortalLogo } from 'assets/logos/dark_gradient_text.svg';
import { LanguageSelect } from '../LanguageSelect/LanguageSelect';

interface PublicLayoutInterface {
  rightSide: ReactNode;
}

export const PublicLayout = ({ children, rightSide }: PropsWithChildren<PublicLayoutInterface>) => {
  const params = useParams();

  const { currentTheme } = useContext(ThemeContext);

  const { data: clientData } = useQuery({
    ...usersApi.getClientLogo(params.domain || ''),
    enabled: Boolean(params.domain)
  });

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '20px'
        }}
      >
        <Space
          style={{
            justifyContent: 'space-between',
            width: 'inherit'
          }}
          styles={{ item: { display: 'flex' } }}
        >
          <PortalLogo />

          {clientData?.logo && (
            <Space styles={{ item: { display: 'flex' } }} size={15}>
              <ShortRight fontSize={20} fill={currentTheme['grey-50']} />

              <Avatar src={clientData.logo} shape="square" size={26} />
            </Space>
          )}
        </Space>

        <LanguageSelect showLabel />
      </div>
      <div
        style={{
          height: 'calc(100vh - 72px)',
          width: 'calc(100vw)',
          display: 'flex'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>{children}</div>

        <div>{rightSide}</div>
      </div>
    </div>
  );
};
