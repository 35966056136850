import { AxiosError, AxiosResponse } from 'axios';
import { SOMETHING_WENT_WRONG_MESSAGE } from 'app-constants';

export const getNotificationError = (error: AxiosError | AxiosResponse) => {
  if (error instanceof AxiosError) {
    return error.response?.data;
  }

  if (Array.isArray(error.data)) {
    return error?.data[0]?.[Object.keys(error.data[0])?.[0]] || SOMETHING_WENT_WRONG_MESSAGE;
  }

  return error?.data[Object.keys(error.data)?.[0]] || SOMETHING_WENT_WRONG_MESSAGE;
};
