import { Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';
import { List, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import {
  AccessLevelStatusLabel,
  Actions,
  AvatarGroup,
  EmptyField,
  NoContentPlaceholder,
  NoDataPlaceholder,
  Table,
  Text
} from 'components';
import { onFilterChange } from 'hooks';
import { firstLetters, format, hasActiveFilters } from 'utils';
import { AccessLevelsPageFiltersValue } from '../pages/AccessLevelsPage';
import { usersApi } from 'api';
import { AccessLevel } from 'api/users.types';
import { UserStatuses } from 'types';
import { UserContext } from 'contexts';

interface AccessLevelsTableInterface {
  filters: AccessLevelsPageFiltersValue;
  onFiltersChange: onFilterChange<AccessLevelsPageFiltersValue>;
  selectedLevels: number[];
  setSelectedLevels: Dispatch<SetStateAction<number[]>>;
  onEdit: Dispatch<SetStateAction<AccessLevel | undefined>>;
  onDelete: Dispatch<SetStateAction<AccessLevel | undefined>>;
  addButton: ReactNode;
}

export const AccessLevelsTable = ({
  filters,
  onFiltersChange,
  selectedLevels,
  setSelectedLevels,
  onEdit,
  onDelete,
  addButton
}: AccessLevelsTableInterface) => {
  const { t } = useTranslation();
  const { isAdminOrganization } = useContext(UserContext);

  const [hoverId, setHoverId] = useState<number | null>(null);

  const { data, isLoading } = useQuery(usersApi.getAccessLevelsList({ ...filters }));

  const columns = useMemo<ColumnsType<AccessLevel>>(
    () => [
      { title: t('name'), dataIndex: 'name' },
      {
        title: t('access'),
        dataIndex: 'users',
        render: (users: AccessLevel['users'] = [], record) => (
          <EmptyField hidden={!users.length}>
            <Tooltip
              title={
                <List
                  style={{ maxHeight: '500px', overflow: 'auto' }}
                  bordered={false}
                  itemLayout="horizontal"
                  dataSource={users}
                  renderItem={(user) => (
                    <List.Item>
                      <Text
                        lines="1"
                        ellipsis={{
                          tooltip: { title: user.name, overlayClassName: 'sm-tooltip' }
                        }}
                      >
                        {user.name}
                      </Text>
                    </List.Item>
                  )}
                />
              }
              placement="right"
              overlayClassName="sm-tooltip"
            >
              <>
                <AvatarGroup
                  list={users.map(({ name, logo }) => ({
                    src: logo,
                    name: firstLetters(name)
                  }))}
                  totalSize={record.users_count}
                />
              </>
            </Tooltip>
          </EmptyField>
        )
      },
      { title: t('lists'), dataIndex: 'access_level_groups_count' },
      {
        title: t('created'),
        dataIndex: 'created_at',
        render: (createdAt) => <Text size="lg">{format(createdAt, 'DD MMM, YYYY HH:mm')}</Text>
      },
      {
        title: t('status'),
        dataIndex: 'is_active',
        render: (isActive) => (
          <AccessLevelStatusLabel status={isActive ? UserStatuses.active : UserStatuses.inactive} />
        )
      },
      {
        width: 70,
        dataIndex: 'id',
        render: (id, obj) =>
          hoverId === id && isAdminOrganization ? (
            <Actions
              actions={[
                <Typography.Text onClick={() => onEdit(obj)}>{t('edit')}</Typography.Text>,
                <Typography.Text onClick={() => onDelete(obj)}>{t('delete')}</Typography.Text>
              ]}
            />
          ) : null
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, data?.results, hoverId, isAdminOrganization]
  );

  // Waiting API ------------------>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rowSelection: TableRowSelection<AccessLevel> = {
    selectedRowKeys: selectedLevels,
    onChange: (selectedRowKeys) => setSelectedLevels(selectedRowKeys as number[]),
    preserveSelectedRowKeys: true,
    getCheckboxProps: (record) => ({ disabled: !record.is_active })
  };

  return (
    <NoContentPlaceholder
      show={!data?.results.length && !isLoading && !hasActiveFilters(filters)}
      placeholder={
        <NoDataPlaceholder
          mainIcon="file-list"
          leftIcon="edit"
          rightIcon="plus"
          onLeftClick={() => null}
          onRightClick={() => null}
          description={
            <div
              dangerouslySetInnerHTML={{
                __html: t('noAccessLevelsDescription')
              }}
            />
          }
          bottomContent={addButton}
        />
      }
    >
      <Table
        columns={columns}
        dataSource={data?.results}
        rowKey="id"
        pagination={{
          pageSize: filters.per_page,
          current: filters.page,
          total: data?.count,
          onChange: onFiltersChange
        }}
        loading={isLoading}
        rowClassName={(record) => (!record.is_active ? 'disabled' : '')}
        onRow={(record) => ({
          onPointerEnter: () => setHoverId(record.id),
          onPointerLeave: () => setHoverId(null)
        })}
      />
    </NoContentPlaceholder>
  );
};
