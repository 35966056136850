import { useContext, useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Form, Space } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DefaultOptionType } from 'antd/es/select';
import { TFunction } from 'i18next';

import { Modal, ModalProps, Input, Select } from 'components';
import { AccessLevel, AccessLevelPost } from 'api/users.types';
import { users, usersApi } from 'api';
import { formErrorHandler, getNotificationError, recursiveEmptyWithNull } from 'utils';
import { NotificationContext, ThemeContext } from 'contexts';
import { Dot02S } from 'icons';
import { ThemeType, UserStatuses } from 'types';
import { statusPropertiesProvider } from 'components/AccessLevelStatusLabel/AccessLevelStatusLabel';

interface AccessLevelFormModalInterface extends ModalProps {
  data?: AccessLevel;
  onCancel?: () => void;
}

const status: (theme: ThemeType, t: TFunction) => DefaultOptionType[] = (theme, t) => {
  const provider = statusPropertiesProvider(theme);
  return [
    {
      label: (
        <Space size={2} styles={{ item: { display: 'flex' } }}>
          <Dot02S color={provider.active.color} />
          {t(provider.active.label)}
        </Space>
      ),
      value: UserStatuses.active
    },
    {
      label: (
        <Space size={2} styles={{ item: { display: 'flex' } }}>
          <Dot02S color={provider.inactive.color} />
          {t(provider.inactive.label)}
        </Space>
      ),
      value: UserStatuses.inactive
    }
  ];
};

export const AccessLevelFormModal = ({
  data,
  onCancel,
  ...props
}: AccessLevelFormModalInterface) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notification } = useContext(NotificationContext);
  const { currentTheme } = useContext(ThemeContext);

  const onCancelClick = () => {
    onCancel?.();

    form.resetFields();
  };

  const workspacesMutation = useMutation(users.getWorkspaces);

  const { mutate, isLoading } = useMutation(
    (body) => (data ? users.editAccessLevel(data.id, body) : users.createAccessLevel(body)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(usersApi.getAccessLevelsList().queryKey);
        onCancelClick();
        notification.success({
          message: t(data ? 'editedAccessLevel' : 'addedAccessLevel'),
          placement: 'bottomRight'
        });
      },
      onError: (error: AxiosError) => {
        form.setFields(formErrorHandler(error));
        notification.error({ message: getNotificationError(error) });
      }
    }
  );

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      data.apollo_api_key && workspacesMutation.mutate({ apollo_api_key: data.apollo_api_key });
    } else {
      form.setFieldsValue({ is_active: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form, props.open]);

  const onValuesChange = (data: AccessLevelPost) => {
    const { apollo_api_key, workspace_tag_name } = data;
    if ('apollo_api_key' in data) {
      form.resetFields(['workspace_tag_name', 'workspace_description']);
      apollo_api_key ? workspacesMutation.mutate({ apollo_api_key }) : workspacesMutation.reset();
    }

    if ('workspace_tag_name' in data) {
      const description = workspacesMutation.data?.find(
        ({ tag_name }) => tag_name === workspace_tag_name
      )?.description;

      form.setFieldValue('workspace_description', description || undefined);
    }
  };

  const getWorkspacesOptions = (type: 'description' | 'tag_name'): DefaultOptionType[] => {
    const results = workspacesMutation.data || [];

    return results.map((data) => ({ value: data[type], label: data[type] }));
  };

  return (
    <Modal
      title={t(data ? 'editAccessLevel' : 'addAccessLevel')}
      onCancel={onCancelClick}
      footer={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button type="link" onClick={onCancelClick}>
            {t('cancel')}
          </Button>
          <Button type="primary" htmlType="submit" form="access-level-form" loading={isLoading}>
            {t('save')}
          </Button>
        </Space>
      }
      {...props}
    >
      <Divider />

      <Form
        layout="vertical"
        id="access-level-form"
        form={form}
        onFinish={(values) => mutate(recursiveEmptyWithNull(values, true))}
        onValuesChange={onValuesChange}
      >
        <Form.Item label={t('name')} name="name" required>
          <Input />
        </Form.Item>

        <Form.Item label={t('apolloApiKey')} name="apollo_api_key">
          <Input />
        </Form.Item>

        <Form.Item label={t('workspaceTagName')} name="workspace_tag_name">
          <Select options={getWorkspacesOptions('tag_name')} allowClear showSearch />
        </Form.Item>

        <Form.Item label={t('workspaceDescription')} name="workspace_description">
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t('status')}
          name="is_active"
          getValueProps={(value) => ({
            value: value ? UserStatuses.active : UserStatuses.inactive
          })}
          normalize={(value) => value === UserStatuses.active}
        >
          <Select options={status(currentTheme, t)} />
        </Form.Item>
      </Form>

      <Divider />
    </Modal>
  );
};
