import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';

import { usersApi } from 'api';
import { InfiniteQuerySelectionCollapse } from 'components';
import { getRoute } from 'utils';
import { onFilterChange } from 'hooks';
import { ClientDocumentsFiltersValue } from 'types';
import { Group } from 'icons';

interface AllPartnersMenuSectionInterface {
  onChange: onFilterChange<ClientDocumentsFiltersValue>;
}

export const AllPartnersMenuSection = ({ onChange }: AllPartnersMenuSectionInterface) => {
  const { t } = useTranslation();
  const params = useParams<{ domain: string }>();

  return (
    <InfiniteQuerySelectionCollapse
      title={
        <Space size={4} styles={{ item: { display: 'flex' } }}>
          <Group /> {t('partners').toUpperCase()}
        </Space>
      }
      query={usersApi.getPartnersList}
      paramsKey="partner_ids"
      route={getRoute('DocumentsPage', params)}
      onChange={onChange}
      showSearch
      params={{ state: 'active' }}
    />
  );
};
