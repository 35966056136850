import { ReactNode, useContext, useMemo } from 'react';
import { Collapse, Radio, RadioGroupProps, RadioProps } from 'antd';
import styled from '@emotion/styled';
import VirtualList from 'rc-virtual-list';

import { ThemeContext } from 'contexts';
import { Input } from 'components';
import { Search } from 'icons';
import { useTranslation } from 'react-i18next';

interface SelectionCollapseInterface extends RadioGroupProps {
  title?: ReactNode;
  items?: RadioProps[];
  collapsed?: boolean;
  isInfiniteScroll?: boolean;
  onSearch?: (search: string) => void;
}

const collapseKey = 'selection-collapse';

const StyledDivWrapper = styled.div`
  padding-bottom: 20px;
  &:last-child {
    margin-bottom: -20px;
  }
`;

export const SelectionCollapse = ({
  title,
  items = [],
  collapsed,
  isInfiniteScroll,
  onSearch,
  ...props
}: SelectionCollapseInterface) => {
  const { currentTheme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const StyledRadioGroup = useMemo(
    () => styled(Radio.Group)`
      width: 100%;
      .ant-radio-wrapper {
        width: 100%;
        margin-right: 0;
        color: ${currentTheme['grey-40']};
        font-weight: 400;
        font-size: 0.875rem;
        &-checked {
          color: ${currentTheme['grey-white']};
          font-weight: 600;
        }

        span:last-child {
          flex: 1;
          max-width: 170px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 0;
        }
      }
    `,
    [currentTheme]
  );

  const StyledCollapse = useMemo(
    () => styled(Collapse)`
      .ant-collapse {
        &-header {
          padding: 1.25rem !important;
          color: ${currentTheme['grey-40']} !important;
          font-weight: 600;
        }

        &-content-box {
          padding: 0 1.25rem 1.25rem 1.25rem !important;
        }
      }
    `,
    [currentTheme]
  );

  return (
    <StyledCollapse
      items={[
        {
          label: title,
          key: collapseKey,
          children: (
            <StyledRadioGroup {...props}>
              {onSearch && (
                <div style={{ paddingBottom: 20 }}>
                  <Input
                    style={{ width: '100%' }}
                    prefix={<Search fontSize={20} />}
                    placeholder={t('search')}
                    onChange={(e) => onSearch(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </div>
              )}
              <VirtualList
                data={items}
                itemKey="status-infinite"
                {...(isInfiniteScroll && { itemHeight: 38, height: 208 })}
              >
                {(props) => (
                  <StyledDivWrapper>
                    <Radio {...props} />
                  </StyledDivWrapper>
                )}
              </VirtualList>
            </StyledRadioGroup>
          )
        }
      ]}
      expandIconPosition="end"
      size="small"
      bordered={false}
      style={{ background: 'transparent' }}
      defaultActiveKey={collapsed ? collapseKey : undefined}
    />
  );
};
