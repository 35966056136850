/** @jsxImportSource @emotion/react */

import { InputProps } from 'antd';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../contexts';
import { Icon } from '../Icon/Icon';
import { PasswordProps } from 'antd/lib/input';

interface STInputInterface {
  Input: any;
}

export const STInput = ({
  value,
  onChange,
  prefix,
  Input,
  ...props
}: STInputInterface & InputProps & PasswordProps) => {
  const { currentTheme } = useContext(ThemeContext);

  const [hasValues, setHasValue] = useState(false);

  useEffect(() => {
    const onSetInitialHasValues = () => {
      if (value) {
        setHasValue(true);
      }
    };

    onSetInitialHasValues();
  }, [value]);

  const onInputChange = (val: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(val);

    if (val.target.value) {
      setHasValue(true);

      return;
    }

    setHasValue(false);
  };

  return (
    <Input
      value={value}
      onChange={onInputChange}
      classNames={{
        prefix: hasValues ? 'ant-input-prefix-has-value' : undefined
      }}
      prefix={
        typeof prefix === 'string' ? (
          <Icon type={prefix} fill={hasValues ? currentTheme.colorPrimary : ''} />
        ) : (
          prefix
        )
      }
      allowClear={{
        clearIcon: <Icon type="close-small" />
      }}
      {...props}
    />
  );
};
