import { SetStateAction, useMemo, useState } from 'react';

export function useModalState<Data>(initialData?: Data) {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<Data | undefined>(initialData);

  return useMemo(
    () => ({
      isOpen,
      data,
      open: () => setIsOpen(true),
      openWith: (value: SetStateAction<Data | undefined>) => {
        setData(value);
        setIsOpen(true);
      },
      setData,
      close: () => {
        setIsOpen(false);
      },
      reset: () => {
        setIsOpen(false);
        setData(undefined);
      }
    }),
    [isOpen, data]
  );
}
