import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Space } from 'antd';
import {
  ActionHeadTitle,
  ChangePasswordModal,
  DetailsCard,
  MainLayout,
  ProfilePageTemplate,
  Put,
  Text,
  UserStatusLabel
} from 'components';
import { ThemeContext } from 'contexts';
import { BarChart, Info2Outline, Refresh02, ShortLeft, UserCircle } from 'icons';
import { dateTimeRowFormat, format, toGBDecrease } from 'utils';
import { users, usersApi } from 'api';

export const ClientProfilePage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { currentTheme } = useContext(ThemeContext);

  const [showResetPassModal, setShowResetPassModal] = useState(false);

  const { data: userData } = useQuery({
    ...(id ? usersApi.getClient(Number(id)) : {})
  });

  const updateMutate = useMutation((data: FormData) => users.updateClient(userData?.id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(usersApi.getClient(Number(id)).queryKey);
    },
    onError: (err) => {
      console.log(err, 'err');
    }
  });

  return (
    <MainLayout pageHeading={<ActionHeadTitle Icon={ShortLeft} label={userData?.name} />}>
      <ProfilePageTemplate
        data={userData}
        mutation={updateMutate}
        footer={
          <Space
            style={{
              borderTop: `.5px solid ${currentTheme['grey-70']}`,
              width: 'calc(100% - 64px)',
              justifyContent: 'space-between',
              padding: '18px 32px'
            }}
          >
            <Space size={16} styles={{ item: { display: 'flex' } }}>
              <Text size="lg" strong>
                {t('clientPassword')}
              </Text>

              {userData?.requested_reset_password && (
                <Space
                  size={8}
                  styles={{ item: { display: 'flex' } }}
                  style={{
                    color: currentTheme['yellow-100'],
                    padding: '6px 12px',
                    backgroundColor: `${currentTheme['yellow-100']}20`,
                    borderRadius: 8
                  }}
                >
                  <Info2Outline />
                  <Text>{t('clientRequestedPassword')}</Text>
                </Space>
              )}
            </Space>
            <Space
              size={8}
              styles={{ item: { display: 'flex' } }}
              style={{ color: currentTheme['purple-100'], cursor: 'pointer' }}
              onClick={() => setShowResetPassModal(true)}
            >
              <Text size="lg">{t('changePassword')}</Text>
              <Refresh02 />
            </Space>
          </Space>
        }
      >
        <DetailsCard
          title={t('clientDetails')}
          Icon={UserCircle}
          items={[
            { label: t('companyCif'), value: <Put>{userData?.cif}</Put> },
            { label: t('department'), value: <Put>{userData?.department}</Put> },
            { label: t('emailAddress'), value: <Put>{userData?.email}</Put> },
            {
              label: t('domainPrefix'),
              value: <Put>{userData?.domain_prefix}</Put>
            }
          ]}
        />

        <Divider type="vertical" style={{ height: 'auto', margin: 0 }} />

        <DetailsCard
          title={t('activity')}
          Icon={BarChart}
          items={[
            {
              label: t('profileStatus'),
              value: <UserStatusLabel unfilled status={userData?.state} />
            },
            {
              label: t('profileCreatedAt'),
              value: userData?.created_at && format(userData.created_at, dateTimeRowFormat)
            },
            {
              label: t('lastActivity'),
              value: userData?.last_login && format(userData.last_login, dateTimeRowFormat)
            },
            {
              label: t('storageSpace'),
              value: toGBDecrease(userData?.storage_limit)
            }
          ]}
        />
      </ProfilePageTemplate>

      <ChangePasswordModal
        open={showResetPassModal}
        setOpen={setShowResetPassModal}
        call={() => users.sendClientPassword(Number(id))}
        text={t('changePasswordDescription')}
      />
    </MainLayout>
  );
};
