import { css } from '@emotion/react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType) => ({
  Container: css`
    width: 256px;
    min-height: 110vh;
    display: inline-block;
    border-left: 0.5px solid ${theme['grey-70']};
  `,
  Header: css`
    padding: 26px 32px;
    border-bottom: 0.5px solid ${theme['grey-70']};
    color: ${theme['grey-white']};
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;

    span {
      color: ${theme['grey-50']};
    }
  `,
  InfoIcon: css`
    color: ${theme['orange-100']};
    float: right;
    width: 20px;
    height: 20px;
  `,
  Body: css`
    .ant-collapse {
      border: none;

      .ant-collapse-item {
        background-color: ${theme.colorBgBase};
        padding: 20px 32px;
        border-bottom: 0.5px solid ${theme['grey-70']};
        border-radius: 0;

        .ant-collapse-header {
          padding: 0;
        }

        &.ant-collapse-item-active {
          background-color: ${theme['grey-90']};
          padding: 20px 32px;

          .ant-collapse-header.ant-collapse-header-collapsible-only {
            .ant-collapse-header-text {
              span {
                background-color: ${theme['orange-100']};
              }
            }
          }
        }

        .ant-collapse-content {
          padding: 0;

          &.ant-collapse-content-active {
            background-color: ${theme['grey-90']};
            border: none;
          }

          .ant-collapse-content-box {
            padding: 0;
          }
        }
      }
    }
  `,
  CollapseItemLabel: css`
    display: flex;
    line-height: 18px;
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    gap: 8px;
  `,
  CollapseKey: css`
    border-radius: 100%;
    background-color: ${theme['grey-50']};
    width: 24px;
    height: 24px;
    display: block;
    text-align: center;
    line-height: 24px;
    font-size: 14px;
  `,
  Chevron: css`
    &.ant-collapse-arrow {
      color: ${theme['grey-50']} !important;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  `,
  CommentItem: css`
    margin-top: 16px;
    padding-top: 16px;
    border-top: 0.5px solid ${theme['grey-70']};
  `,
  CommentItemAuthor: css`
    color: ${theme['grey-50']};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    span {
      font-weight: 600;
    }

    &:first-letter {
      text-transform: capitalize;
    }
  `,
  CommentItemText: css`
    color: ${theme['grey-20']};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 4px 0;
  `,
  CommentItemDate: css`
    font-size: 12px;
    color: ${theme['grey-50']};
  `,
  CommentItemForm: css`
    margin-top: 16px;

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-extra {
      position: absolute;
      right: 0;
      top: 7.5px;
    }
  `,
  CommentItemFormButton: css`
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;

    svg {
      cursor: pointer;
      color: ${theme['purple-100']};
      width: 16px;
      height: 16px;
    }
  `
});
