import { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Actions, DeleteConfirmModal, Text } from 'components';
import { NotificationContext, ThemeContext, UserContext } from 'contexts';
import { documents, documentsQueries } from 'api';
import { dateTimeHyphenFormat, getNotificationError } from 'utils';
import { APIDocument, Directory, DirectoryType } from 'types';
import { useDownloadFiles } from 'hooks';

interface ActionsFoldersInterface {
  folder: APIDocument & Directory;
  hideDelete?: boolean;
  hideDownload?: boolean;
}

export const ActionsFolders = ({ folder, hideDelete, hideDownload }: ActionsFoldersInterface) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { currentTheme } = useContext(ThemeContext);
  const { isAdminOrganization, data, role } = useContext(UserContext);
  const { notification } = useContext(NotificationContext);

  const notifyError = (error: AxiosError) => {
    notification.error({
      message: getNotificationError(error)
    });
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries(documentsQueries.getDirectoriesList().queryKey);
    queryClient.invalidateQueries(documentsQueries.getDirectoriesSearchList().queryKey);
  };

  const deleteMutation = useMutation(() => documents.deleteDirectory(folder.id), {
    onError: notifyError,
    onSuccess: handleSuccess
  });

  const { handleDownloadFiles } = useDownloadFiles();

  const downloadMutation = useMutation(
    () =>
      documents.downloadFiles({
        directory: folder.id,
        name: `SmartPortal-export-${dayjs().format(dateTimeHyphenFormat)}.zip`
      }),
    {
      onSuccess: (data) => {
        handleDownloadFiles(data);
      }
    }
  );

  const allowDelete =
    (!isAdminOrganization ? folder.user === data?.id && !folder.created_by_client : true) &&
    folder.type === DirectoryType.SIMPLE;

  return (
    <Spin spinning={downloadMutation.isLoading || deleteMutation.isLoading}>
      <Actions
        dropdownItems={[
          hideDownload
            ? null
            : {
                label: <Text size="lg">{t('download')}</Text>,
                key: 'download',
                onClick: () => downloadMutation.mutate()
              },
          hideDelete || !allowDelete
            ? null
            : {
                label: <Text size="lg">{t('delete')}</Text>,
                key: 'delete',
                onClick: () => setIsDeleteModalOpen(true)
              }
        ]}
      />

      <DeleteConfirmModal
        mutation={deleteMutation}
        title={t('deleteFolder')}
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
      >
        <Typography.Text style={{ color: currentTheme['grey-40'], fontSize: '0.875rem' }}>
          {t(
            role.isClient && isAdminOrganization
              ? 'permanentlyDeletedAdminFolder'
              : 'permanentlyDeletedFolder'
          )}{' '}
          <Text
            style={{
              color: currentTheme['blue-75'],
              fontSize: '0.875rem',
              display: 'block'
            }}
          >
            {folder.name}
          </Text>
        </Typography.Text>
      </DeleteConfirmModal>
    </Spin>
  );
};
