import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, Dropdown, Space } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { DataNode } from 'antd/es/tree';

import { useQueryParams } from 'hooks';
import { ChevronBigRight, Folder, MoreHorizontal } from 'icons';

import { withValues } from 'utils';
import { FoldersContext } from 'contexts';

interface FoldersBreadcrumbProps {}

const resetFilters = { documents_state: null, partner_ids: null };

export const FoldersBreadcrumb = ({
  children
}: React.PropsWithChildren<FoldersBreadcrumbProps>) => {
  const query = useQueryParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const { formattedData, folderData } = useContext(FoldersContext);

  const selectedTreeParams = query['--selectedTree'];
  const selectedTree = Array.isArray(selectedTreeParams)
    ? selectedTreeParams
    : [selectedTreeParams];

  const items = useMemo(() => {
    if (!selectedTreeParams || !folderData || !formattedData.length) return [];

    let foundItems = { children: formattedData };

    const items = selectedTree
      .map((value) => {
        const foundItem = foundItems?.children?.find(
          (directory) => `${directory.key}` === `${value}`
        );

        foundItems = foundItem as { children: DataNode[] };

        return { key: foundItem?.key, label: foundItem?.title, icon: <Folder /> } as ItemType;
      })
      .filter((v) => v?.key !== folderData?.id);

    return items;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderData?.id, selectedTreeParams, formattedData]);

  const StyledSpan = styled.span`
    cursor: ${query.directory ? 'pointer' : 'default'};
  `;

  const getSelectedTreeParams = (key: string) => {
    const index = selectedTree.findIndex((value) => `${value}` === `${key}`);

    return selectedTree.slice(0, index + 1).join(',');
  };

  return (
    <Space align="center">
      <StyledSpan
        onClick={() =>
          setSearchParams(
            withValues({
              ...query,
              directory: null,
              '--selectedTree': null,
              ...resetFilters
            })
          )
        }
      >
        {children}
      </StyledSpan>

      {!!items.length && (
        <Dropdown
          trigger={['click']}
          menu={{
            items,
            onClick: ({ key }) =>
              setSearchParams(
                withValues({
                  ...query,
                  directory: key,
                  '--selectedTree': getSelectedTreeParams(key),
                  ...resetFilters
                })
              )
          }}
        >
          <Button type="text" icon={<MoreHorizontal />} />
        </Dropdown>
      )}

      {folderData?.name && (
        <Space align="center" styles={{ item: { display: 'flex' } }}>
          {!items.length && <ChevronBigRight />}
          {folderData?.name}
        </Space>
      )}
    </Space>
  );
};
