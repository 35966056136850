import { css } from '@emotion/react';

import { palette } from 'utils';

export const paginationStyles = css`
  .ant-pagination {
    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      min-width: 40px;
      height: 40px;
      border-radius: 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: ${palette['grey-60']};

      a,
      svg {
        color: ${palette['grey-60']};
      }

      &:not(.ant-pagination-disabled):hover {
        color: ${palette['grey-50']};
        background-color: ${palette['grey-90']};
      }
    }

    .ant-pagination-item-active {
      border-color: ${palette['grey-60']};
      &:hover {
        border-color: ${palette['grey-60']};
        background-color: ${palette['grey-90']};
      }
      & a,
      &:hover a {
        color: ${palette['grey-20']};
      }
    }
  }
`;
