import { useMutation } from '@tanstack/react-query';

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, Tooltip, Typography, Upload } from 'antd';

import { AxiosError } from 'axios';
import { Dropdown } from 'components';
import { NotificationContext, ThemeContext } from 'contexts';
import { ChevronDown, DownloadOutline, HelpCircleOutline } from 'icons';
import { users } from 'api';
import { RcFile } from 'antd/lib/upload';
import { downloadFile, getNotificationError } from 'utils';

interface AddPartnerBtnInterface {
  onManualClick: () => void;
  onImportClick: () => void;
}

export const AddPartnerBtn = ({ onManualClick, onImportClick }: AddPartnerBtnInterface) => {
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const { mutate, isLoading } = useMutation(users.importPartnersList, {
    onSuccess: () =>
      notification.success({
        message: t('partnerSuccessfullyAdded'),
        placement: 'bottomRight'
      }),
    onError: (error: AxiosError) => {
      notification.error({
        message: getNotificationError(error),
        placement: 'bottomRight'
      });
    }
  });

  const { mutate: excelExampleMutation } = useMutation(users.getInsertExcelExample, {
    onSuccess: (data) => downloadFile(data, 'example.xlsx')
  });

  const onUpload = ({ file, filename }: { file: RcFile; filename: string } & any) => {
    const formData = new FormData();

    formData.append(filename, file);

    mutate(formData);
  };

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 1,
            label: (
              <Space styles={{ item: { display: 'flex' } }}>
                <Upload customRequest={onUpload}>
                  <Typography.Text onClick={onImportClick}>{t('uploadList')}</Typography.Text>
                </Upload>
                <Tooltip
                  title={
                    <Space>
                      <Button
                        type="primary"
                        size="small"
                        style={{ marginTop: '5px' }}
                        onClick={() => excelExampleMutation()}
                      >
                        {t('example')}
                        <DownloadOutline />
                      </Button>
                    </Space>
                  }
                  overlayClassName="sm-tooltip"
                  placement="bottomLeft"
                >
                  <HelpCircleOutline fontSize={20} fill={currentTheme['grey-60']} />
                </Tooltip>
              </Space>
            )
          },
          {
            key: 2,
            label: <Typography.Text onClick={onManualClick}>{t('manual')}</Typography.Text>
          }
        ]
      }}
    >
      <Button type="primary" loading={isLoading}>
        {t('add')}
        <ChevronDown color={currentTheme['grey-white']} fontSize={20} />
      </Button>
    </Dropdown>
  );
};
