import { Typography as AntdTypography } from 'antd';
import { Title } from './Title';
import { Text } from './Text';

const { Paragraph, Link } = AntdTypography;

export { Paragraph, Link, Text, Title };
export const Typography = { Paragraph, Link, Text, Title };

/**
 * TODO: Replace instances of antd typography with ours
 * TODO: Replace hardcoded text sizes (styles) with <Text size="md|lg|sm" />
 */
