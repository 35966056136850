import { css } from '@emotion/react';

import { palette } from 'utils';

export const progressStyles = css`
  .ant-progress .ant-progress-bg,
  .ant-progress.ant-progress-status-success .ant-progress-bg {
    background-color: ${palette['purple-100']};
  }
`;
