import { Dispatch, SetStateAction, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Tooltip, Upload } from 'antd';
import { useTranslation } from 'react-i18next';

import { Actions, Text } from 'components';
import { NotificationContext } from 'contexts';
import { documents, documentsQueries, users } from 'api';
import { axios } from 'api/axios';
import { getFileDirectory, getNotificationError } from 'utils';
import { APIDocument } from 'types';

interface ActionsDocumentInterface {
  document: APIDocument;
  hideDelete?: boolean;
  hideReplace?: boolean;
  isMoveDisabled?: boolean;
  hasReadonlyFile?: boolean;
  setDocumentToDelete?: Dispatch<SetStateAction<APIDocument | undefined>>;
  setDocumentToMove?: Dispatch<APIDocument[]>;
  setProgress?: Dispatch<SetStateAction<{ [x: string]: number }>>;
  setSelectedDocuments?: Dispatch<SetStateAction<APIDocument[]>>;
}

export const ActionsDocument = ({
  document,
  hideReplace = false,
  hideDelete = false,
  isMoveDisabled = false,
  hasReadonlyFile = false,
  setDocumentToDelete,
  setDocumentToMove,
  setProgress,
  setSelectedDocuments
}: ActionsDocumentInterface) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { notification } = useContext(NotificationContext);

  const notifyError = (error: AxiosError) => {
    notification.error({
      message: getNotificationError(error)
    });
  };

  const cannotMoveReadonlyMessage = hasReadonlyFile ? t('cannotMoveReadonlyMessage') : null;
  const cannotMoveMessage = isMoveDisabled ? t('selectPartnerMessage') : null;

  const uploadProps = {
    customRequest: (data: any) => {
      const uploadedFileDirectory = getFileDirectory(
        data.file.webkitRelativePath || data.file.name
      );

      return users
        .getSignedUrls([
          {
            name: [uploadedFileDirectory, data.file.name].filter(Boolean).join('/'),
            content_type: data.file.type,
            size: data.file.size,
            operation_id: document?.additional_info?.data
              ? document?.additional_info?.data[0]?.operation_id
              : undefined,
            folder_id: document?.directory?.id
          }
        ])
        .then((res) => {
          queryClient.invalidateQueries(documentsQueries.getList().queryKey);
          axios
            .put(res[0].signed_url, data.file, {
              headers: {
                'Content-Type': data.file.type
              },
              onUploadProgress: (progressEvent: any) => {
                if (progressEvent?.total) {
                  setProgress?.((prev: any) => ({
                    ...prev,
                    [`${data?.file?.name}`]: (progressEvent?.loaded * 100) / progressEvent?.total
                  }));
                }
              }
            })

            .then(() => documents.delete(document?.id))
            .then(async () => {
              await documents.confirmUpload({
                ids: res?.[0]?.id
              });
              await queryClient.invalidateQueries(documentsQueries.getList().queryKey);
              await queryClient.invalidateQueries(documentsQueries.getPartnerStats().queryKey);
              await setSelectedDocuments?.((prev) => prev?.filter(({ id }) => id !== document?.id));
            })
            .catch(notifyError);
        })
        .catch(notifyError);
    }
  };

  return (
    <Actions
      dropdownItems={[
        hideReplace
          ? null
          : {
              label: (
                <Upload multiple {...uploadProps}>
                  <Text size="lg">{t('replace')}</Text>
                </Upload>
              ),
              key: 'replace'
            },
        hideDelete
          ? null
          : {
              label: <Text size="lg">{t('delete')}</Text>,
              key: 'delete',
              onClick: () => setDocumentToDelete?.(document)
            },
        {
          label: (
            <Tooltip
              title={cannotMoveReadonlyMessage || cannotMoveMessage}
              placement="topLeft"
              overlayClassName="sm-tooltip"
            >
              <Text size="lg">{t('moveFile')}</Text>
            </Tooltip>
          ),
          key: 'moveFile',
          disabled: isMoveDisabled || hasReadonlyFile,
          onClick: () => setDocumentToMove?.([document])
        }
      ]}
    />
  );
};
