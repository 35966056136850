import { css } from '@emotion/react';

import { palette } from 'utils';

export const checkboxStyles = css`
  .ant-checkbox .ant-checkbox-inner {
    border-radius: 4px;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    height: 3px;
    border-radius: 2px;
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: ${palette['grey-50']};
  }

  .ant-checkbox-wrapper:focus-within:not(.ant-checkbox-wrapper-disabled) {
    .ant-checkbox-inner {
      border-color: ${palette['grey-50']};
      box-shadow: 0 0 0 4px rgba(171, 112, 255, 0.2);
    }
  }

  .ant-checkbox-disabled + span {
    color: ${palette['grey-40']};
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${palette['grey-80']};
    border-color: transparent;
  }
  .ant-checkbox-disabled.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${palette['grey-50']};
  }
  .ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: ${palette['grey-50']};
  }
`;
