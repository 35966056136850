import { Progress as AntProgress, ProgressProps } from 'antd';
import { ThemeContext } from 'contexts';
import { useContext, useMemo } from 'react';

interface ProgressInterface extends ProgressProps {}

export const Progress = ({ ...props }: ProgressInterface) => {
  const { currentTheme } = useContext(ThemeContext);

  const color = useMemo(() => {
    let color: string | undefined = undefined;
    const { percent = 0 } = props;

    if (percent >= 0 && percent < 50) {
      color = currentTheme['green-100'];
    } else if (percent >= 50 && percent < 75) {
      color = currentTheme['blue-100'];
    } else if (percent >= 75) {
      color = currentTheme['red-100'];
    } else {
      color = undefined;
    }

    return color;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTheme, props.percent]);

  return <AntProgress showInfo={false} strokeColor={color} {...props} />;
};
