import { AccessLevelStatuses, PartnerStatuses } from '../utils';
import {
  CircleCheckOutline,
  Clock,
  CloseBig,
  CloudClose,
  CloudUp,
  Delegate,
  FileBlankOutline,
  GlassHour,
  Group,
  InfoOutline,
  MoreHorizontal,
  Replay,
  TrashFull,
  UserCheck,
  UserClose,
  UserMinus,
  UserPending
} from 'icons';
import { DocumentStatus } from 'types';

export const DocumentsLabels = {
  [DocumentStatus.total]: {
    label: 'total',
    icon: FileBlankOutline,
    menuLabel: 'totalLabel'
  },
  [DocumentStatus.pending]: {
    label: 'pending',
    icon: MoreHorizontal,
    menuLabel: 'pendingLabel'
  },
  [DocumentStatus.uploaded]: {
    label: 'uploaded',
    icon: CloudUp,
    menuLabel: 'uploadedLabel'
  },
  [DocumentStatus.processing]: {
    label: 'processing',
    icon: GlassHour,
    menuLabel: 'processingLabel'
  },
  [DocumentStatus.needReplacement]: {
    label: 'needReplacement',
    icon: Replay,
    menuLabel: 'needReplacementLabel'
  },
  [DocumentStatus.needInfo]: {
    label: 'needInfo',
    icon: InfoOutline,
    menuLabel: 'needInfoLabel'
  },
  [DocumentStatus.done]: {
    label: 'done',
    icon: CircleCheckOutline,
    menuLabel: 'doneLabel'
  },
  [DocumentStatus.failed]: {
    label: 'failed',
    icon: CloudClose,
    menuLabel: 'failedLabel'
  },
  [DocumentStatus.deleted]: {
    label: 'deleted',
    icon: TrashFull,
    menuLabel: 'deletedLabel'
  },
  [DocumentStatus.rejected]: {
    label: 'rejected',
    icon: CloseBig,
    menuLabel: 'rejectedLabel'
  }
};

export const DocumentLabelsForPartner = {
  ...DocumentsLabels,
  [DocumentStatus.pendingApproval]: {
    label: 'pendingApproval',
    icon: Clock,
    menuLabel: 'pendingApprovalLabel'
  },
  [DocumentStatus.delegateApproval]: {
    label: 'delegateApproval',
    icon: Delegate,
    menuLabel: 'delegateApprovalLabel'
  }
};

export const PartnersLabels = {
  [PartnerStatuses.total]: {
    label: 'all',
    icon: Group,
    menuLabel: 'allPartners'
  },
  [PartnerStatuses.active]: {
    label: 'active',
    icon: UserCheck,
    menuLabel: 'activePartnersLabel'
  },
  [PartnerStatuses.inactive]: {
    label: 'inactive',
    icon: UserClose,
    menuLabel: 'inactivePartnersLabel'
  },
  [PartnerStatuses.pending]: {
    label: 'pendingUser',
    icon: UserPending,
    menuLabel: 'pendingPartnersLabel'
  },
  [PartnerStatuses.deleted]: {
    label: 'deletedUser',
    icon: UserMinus,
    menuLabel: 'deletedPartnersLabel'
  }
};

export const ClientsLabels = {
  [PartnerStatuses.total]: {
    label: 'all',
    icon: Group,
    menuLabel: 'allClients'
  },
  [PartnerStatuses.active]: {
    label: 'active',
    icon: UserCheck,
    menuLabel: 'activeClientsLabel'
  },
  [PartnerStatuses.inactive]: {
    label: 'inactive',
    icon: UserClose,
    menuLabel: 'inactiveClientsLabel'
  },
  [PartnerStatuses.pending]: {
    label: 'pendingUser',
    icon: UserPending,
    menuLabel: 'pendingClientLabel'
  },
  [PartnerStatuses.deleted]: {
    label: 'deletedUser',
    icon: UserMinus,
    menuLabel: 'deletedClientsLabel'
  }
};

export const AccessLevelsLabels = {
  [AccessLevelStatuses.total]: {
    label: 'all',
    icon: Group,
    menuLabel: 'allPartners'
  },
  [AccessLevelStatuses.active]: {
    label: 'active',
    icon: UserCheck,
    menuLabel: 'activePartnersLabel'
  },
  [AccessLevelStatuses.inactive]: {
    label: 'inactive',
    icon: UserClose,
    menuLabel: 'inactivePartnersLabel'
  }
};


export const statusOrder: DocumentStatus[] = [
  DocumentStatus.total,
  DocumentStatus.pendingApproval,
  DocumentStatus.delegateApproval,
  DocumentStatus.done,
  DocumentStatus.rejected,
  DocumentStatus.processing,
  DocumentStatus.needInfo,
  DocumentStatus.uploaded,
  DocumentStatus.pending,
  DocumentStatus.failed,
];

export const getOrderStatus = (data?: Record<DocumentStatus, number>) => {
  if (!data) return {};

  const restStatus = Object.fromEntries(
    Object.entries(data).filter(([key]) => !statusOrder.includes(key as DocumentStatus))
  );

  const newData = statusOrder
    .map((value) => (value in data ? { [value]: data[value] } : undefined))
    .filter((v) => v)
    .reduce((prev, next) => ({ ...prev, ...next }), {});

  return { ...newData, ...restStatus };
};