import { css } from '@emotion/react';

import { palette } from 'utils';

export const switchStyles = css`
  .ant-switch {
    height: unset;
    min-height: 16px;

    background-color: transparent;
    border: 1px solid ${palette['grey-70']};

    &,
    &-inner {
      min-width: 24px;
    }

    & &-handle {
      width: unset;
      height: calc(100% - 4px);
      aspect-ratio: 1/1;
      top: 50%;
      transform: translateY(-50%);
      &::before {
        border-radius: 999px;
      }
    }
    &:not(.ant-switch-checked) {
      &::before {
        background-color: ${palette['grey-70']};
      }
    }

    &.ant-switch-checked .ant-switch-handle {
      inset-inline-start: 100%;
      transform: translateX(calc(-1 * 100% - 2px)) translateY(-50%);
    }

    .ant-switch-inner {
      padding-inline-start: calc(4px + var(--icon-size));
      padding-inline-end: 4px;
    }
    &.ant-switch-checked .ant-switch-inner {
      padding-inline-start: 4px;
      padding-inline-end: calc(4px + var(--icon-size));
    }

    .ant-switch-inner-checked,
    .ant-switch-inner-unchecked {
      display: flex;
      align-items: center;
      height: 100%;
      line-height: 1;
    }

    .ant-switch-inner-checked {
      opacity: 0;
      margin-top: 1px;
    }
    .ant-switch-inner .ant-switch-inner-unchecked {
      margin-top: -16.2px;
    }

    &.ant-switch-checked {
      .ant-switch-inner-checked {
        opacity: 1;
      }
      .ant-switch-inner-unchecked {
        opacity: 0;
      }
    }

    .ant-switch.ant-switch-disabled {
      opacity: unset;
    }
    &.ant-switch-disabled {
      background-color: ${palette['grey-90']};

      .ant-switch-handle::before {
        background-color: ${palette['grey-70']};
      }
    }
    &:focus {
      box-shadow: 0 0 0 4px rgba(171, 112, 255, 0.2);
    }
  }
`;
