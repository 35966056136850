import { css } from '@emotion/react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType) => ({
  placeholderContainer: css`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  placeholderWrapper: css`
    display: flex;
    flex-direction: column;
    justify-items: center;
  `,
  iconWrapper: css`
    border-radius: 40px;
    border: 1px solid ${theme['grey-70']};
    background-color: ${theme['grey-90']};
    padding: 40px;
    position: relative;
  `,
  leftAction: css`
    position: absolute;
    left: -22px;
    top: calc(50% - 22px);
    width: 24px;
    height: 24px;
    border-radius: 20px;
    padding: 8px;
    border: 1px solid ${theme['grey-70']};
    background-color: ${theme['grey-80']};
  `,
  rightAction: css`
    position: absolute;
    right: -22px;
    top: calc(50% - 22px);
    width: 24px;
    height: 24px;
    padding: 8px;
    border-radius: 20px;
    background-color: ${theme['grey-white']};
  `
});
