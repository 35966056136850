import { ThemeType } from '../../types';
import { css } from '@emotion/react';

export const stylesInit = (theme: ThemeType) => ({
  sideMenuItem: css`
    color: ${theme['grey-50']};
    --vertical-padding: 0.625rem;
    padding: var(--vertical-padding) 1.25rem;
    --font-size: 0.875rem;
    font-size: var(--font-size);
    justify-content: space-between;
    width: calc(100% - 2.5rem);
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    svg {
      fill: ${theme['grey-50']};
    }

    &.selected {
      background-color: ${theme.colorPrimary};
      position: relative;
      color: ${theme['grey-white']};
      font-weight: 600;

      &:hover {
        opacity: 1;
      }

      &:after {
        content: '';
        width: 0.5rem;
        height: calc(1.5 * var(--font-size) + 2 * var(--vertical-padding));
        background-color: ${theme.colorPrimary};
        position: absolute;
        right: 1px; // Avoid gap appearing when having zoom
        transform: translateX(100%);
        z-index: 1;
        border-radius: 0 0.5rem 0.5rem 0;
      }

      svg {
        fill: ${theme['grey-white']};
      }
    }
  `,
  sectionTitle: css`
    padding: 1.25rem;
    border-top: 1px solid ${theme['grey-70']};
    font-size: 0.75rem;
    color: ${theme['grey-60']};
  `
});
