import { ReactNode, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { Avatar } from 'antd';

import { ThemeContext } from 'contexts';

interface AvatarGroupInterface {
  list?: { src?: string; name?: ReactNode }[];
  count?: number;
  totalSize?: number;
}

export const AvatarGroup = ({ list = [], count = 5, totalSize = 0 }: AvatarGroupInterface) => {
  const { currentTheme } = useContext(ThemeContext);

  const CustomAvatar = useMemo(
    () => styled(Avatar)`
      background-color: ${currentTheme['grey-20']};
      color: ${currentTheme['colorBgBase']};
      font-weight: 400;
    `,
    [currentTheme]
  );

  const size = totalSize || list.length;

  return (
    <Avatar.Group size="small">
      {list.slice(0, count).map(({ src, name }, idx) => (
        <CustomAvatar src={src} key={idx} style={{ backgroundColor: currentTheme['grey-40'] }}>
          {name}
        </CustomAvatar>
      ))}
      {size > count && <CustomAvatar>{size - count}</CustomAvatar>}
    </Avatar.Group>
  );
};
