import styled from '@emotion/styled';
import { ThemeType } from 'types';

export const MainLayoutStyled = (theme: ThemeType) => ({
  SideBar: styled.div`
    width: 15rem;
    min-width: 15rem;
    background-color: ${theme['grey-90']};
    border-right: 1px solid ${theme['grey-70']};
    overflow: hidden auto;
  `,
  Header: styled.div`
    width: 100%;
    border-bottom: 1px solid ${theme['grey-70']};
    height: 82px;
    display: flex;
    align-items: center;
  `
});
