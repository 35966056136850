import { css } from '@emotion/react';

import { palette } from 'utils';

export const scrollBarStyles = css`
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${palette['grey-70']};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${palette['grey-40']};
    &:hover {
      background: ${palette['grey-30']};
    }
  }
`;
