import { css } from '@emotion/react';

import { palette } from 'utils';

export const selectStyles = css`
  .ant-select-dropdown {
    background-color: ${palette['grey-80']};
  }
  .ant-select {
    &,
    & .ant-select-arrow,
    & .ant-select-clear {
      color: ${palette['grey-60']};
    }

    &,
    &-md {
      &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 40px;
        padding-right: 10px;
        padding-left: 18px;
      }

      &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        & .ant-select-selection-search-input,
        & .ant-select-selection-item,
        & .ant-select-selection-placeholder {
          height: 38px; // 40 - 2
          line-height: 38px;
        }
      }
      &.ant-select-single.ant-select-show-arrow {
        & .ant-select-selection-item,
        & .ant-select-selection-placeholder {
          padding-inline-end: 28px;
        }
      }

      .ant-select-arrow {
        font-size: 20px;
      }
    }
    &-sm {
      font-size: 14px;

      /* padding: 0 4px; */
      &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 32px;
        padding-left: 16px;
        border-radius: 8px;
      }

      &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        & .ant-select-selection-search-input,
        & .ant-select-selection-item,
        & .ant-select-selection-placeholder {
          height: 30px; // 32px - 2
          line-height: 30px;
        }
      }
      &.ant-select-single.ant-select-show-arrow {
        & .ant-select-selection-item,
        & .ant-select-selection-placeholder {
          padding-inline-end: 24px;
        }
      }
      .ant-select-arrow {
        font-size: 16px;
      }
    }
    &-lg {
      &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding-left: 18px;
        padding-right: 12px;
        height: 48px;
      }
      &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        & .ant-select-selection-search-input,
        & .ant-select-selection-item,
        & .ant-select-selection-placeholder {
          height: 46px;
          line-height: 46px;
        }
      }
      &.ant-select-multiple .ant-select-selector {
        border-radius: 8px;
      }
    }

    &.ant-select-focused:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
      )
      .ant-select-selector {
      border-color: ${palette['grey-50']};
      box-shadow: 0 0 0 4px rgba(171, 112, 255, 0.2);
    }

    &-disabled {
      &,
      & .ant-select-arrow,
      & .ant-select-clear {
        color: ${palette['grey-70']};
      }
      &&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: ${palette['grey-90']};
        color: ${palette['grey-70']};
      }
    }
  }
  .ant-select-clear {
    // It's not aligned with the dropdown arrow and it's seen under the clear icon
    transform: translateX(-3px);
  }

  &.ant-select.ant-select-open {
    .ant-select-selector {
      border: 1.5px solid ${palette['grey-50']} !important;
    }
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1.5px solid ${palette['grey-70']};
  }

  &.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover {
    .ant-select-selector {
      border-color: ${palette['grey-50']};
    }
  }
`;
