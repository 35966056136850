import { InputNumber, Space } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { FilterContent } from 'components';
import { $Object } from 'types';
import { onFilterChange } from 'hooks';

const CustomInput = styled(InputNumber)`
  width: 100%;
`;

interface RangeInputProps {
  filters?: $Object;
  onFiltersChange?: onFilterChange<$Object>;
  gteKey?: string;
  lteKey?: string;
}

export const RangeInput = ({ filters, gteKey, lteKey, onFiltersChange }: RangeInputProps) => {
  const { t } = useTranslation();

  if (!gteKey || !lteKey) return null;

  return (
    <FilterContent small icon={null} title={t('partnersCount').toUpperCase()}>
      <Space style={{ width: '100%' }} styles={{ item: { width: '50%' } }}>
        <CustomInput
          min={0}
          value={filters?.[gteKey]}
          onChange={(value) =>
            value !== null && value >= filters?.[lteKey]
              ? onFiltersChange?.({ [gteKey]: filters?.[lteKey] })
              : onFiltersChange?.({ [gteKey]: value })
          }
        />
        <CustomInput
          min={0}
          value={filters?.[lteKey]}
          onChange={(value) =>
            value !== null && value <= filters?.[gteKey]
              ? onFiltersChange?.({ [lteKey]: filters?.[gteKey] })
              : onFiltersChange?.({ [lteKey]: value })
          }
        />
      </Space>
    </FilterContent>
  );
};
