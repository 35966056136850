import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export const CleanedPathNavigate = () => {
  const location = useLocation();

  const newPathname = useMemo(() => {
    const pathAsArray = location.pathname.split('/');
    // Remove the first part of the URL, ex.: /domain_prefix/partner/documents -> /partner/documents
    pathAsArray.splice(1, 1);
    return pathAsArray.join('/');
  }, [location.pathname]);

  return (
    <Navigate
      to={{
        ...location,
        pathname: newPathname
      }}
      replace
    />
  );
};
