import React, { useMemo } from 'react';
import { FoldersBreadcrumb, HeadTitle, MainLayout } from 'components';
import { PartnerDocumentsTable } from 'components';
import { DocumentLabelsForPartner } from 'components/MainLayout/utils';
import { useFilters, useQueryParams } from 'hooks';
import { useTranslation } from 'react-i18next';
import { DocumentsMenuSection } from 'features/common/components';
import { PARTNER_DOCUMENTS_FILTERS } from 'app-constants';

export interface DocumentsPageFiltersInterface {
  search: string;
  page: number;
  per_page: number;
  documents_state?: string;
  ordering?: string;
  created_at_after?: string;
  created_at_before?: string;
  directory?: string | number | null;
  documents_archive?: boolean;
  '--selectedTree'?: string[];
}

export const DocumentsPage = () => {
  const params = useQueryParams();
  const { t } = useTranslation();

  const { filters, onFiltersChange } = useFilters<DocumentsPageFiltersInterface>(
    {
      search: '',
      page: 1,
      per_page: 8,
      documents_state: undefined,
      ordering: undefined,
      created_at_after: '',
      created_at_before: '',
      directory: undefined,
      documents_archive: undefined,
      '--selectedTree': undefined
    },
    { keyword: PARTNER_DOCUMENTS_FILTERS, useQueryParams: true }
  );

  const PageHeading = useMemo(() => {
    const foundItem =
      DocumentLabelsForPartner[
        (params.documents_state as keyof typeof DocumentLabelsForPartner) || 'total'
      ];

    return (
      <HeadTitle
        Icon={foundItem.icon}
        label={<FoldersBreadcrumb> {t(foundItem.menuLabel)}</FoldersBreadcrumb>}
      />
    );
  }, [params.documents_state, t]);

  return (
    <MainLayout
      filters={filters}
      pageHeading={PageHeading}
      onChange={onFiltersChange}
      menuSections={[<DocumentsMenuSection onChange={onFiltersChange} />]}
    >
      <PartnerDocumentsTable filters={filters} onFiltersChange={onFiltersChange} />
    </MainLayout>
  );
};
