import { css } from '@emotion/react';

import { palette } from 'utils';

export const buttonStyles = css`
  .ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.ant-btn-default {
      &:not(:disabled) {
        color: ${palette['grey-white']};
        border-color: ${palette['purple-100']};

        svg {
          color: ${palette['purple-100']};
        }
      }

      &:not(:disabled):not(.ant-btn-disabled):hover {
        border-color: ${palette['purple-75']};
        color: ${palette['grey-white']};
      }

      &:disabled {
        background-color: transparent;
        color: ${palette['grey-60']};
      }
    }
    &.ant-btn-primary {
      box-shadow: none;

      &:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: ${palette['purple-125']};
      }
      &:disabled {
        border-color: ${palette['grey-70']};
        background-color: ${palette['grey-70']};
        color: ${palette['grey-40']};
      }
    }

    span {
      display: inline-block;
    }

    &.ant-btn-lg {
      padding: 0px 16px;
      height: 48px;
      span {
        margin: 0 8px;
      }
    }

    &,
    &.ant-btn-md {
      padding: 0px 12px;
      height: 40px;
      span {
        margin: 0 8px;
      }
    }

    &.ant-btn-sm {
      padding: 0px 8px;
      height: 32px;
      border-radius: 8px;
      font-size: 14px;

      span {
        margin: 0 4px;
      }
    }

    &-link {
      padding-left: 0;
      padding-right: 0;
      color: ${palette['purple-100']};
      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: ${palette['purple-100']};
        border-bottom: 1px solid ${palette['purple-100']};
        border-radius: 0;
      }
    }
  }
`;
