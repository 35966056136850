import { useCallback } from 'react';
import { ParsedQuery, default as queryString } from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryUpdate = () => {
  const push = useNavigate();
  const { pathname, search } = useLocation();

  const updateQuery = useCallback(
    (filters: ParsedQuery<number | string>) => {
      const pushURL = `${pathname}?${queryString.stringify(filters, {
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: 'comma'
      })}`;
      if (pathname + search !== pushURL.replace(/\?+$/g, '')) {
        push(pushURL, { replace: true });
      }
    },
    [push, pathname, search]
  );

  return {
    updateQuery
  };
};
