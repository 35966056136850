import { Key, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Space, Spin, Typography } from 'antd';
import { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import styled from '@emotion/styled';
import {
  MainLayout,
  Dropdown,
  Table,
  UserStatusLabel,
  Input,
  NoContentPlaceholder,
  Pagination,
  NoDataPlaceholder,
  FiltersSection,
  makeSortingControllerHelper,
  SortingController,
  DeleteConfirmModal,
  HeadTitle,
  EmptyField
} from 'components';
import { users, usersApi } from 'api';
import { NotificationContext, ThemeContext } from 'contexts';
import { defaultPaginationProp, format, getRoute, hasActiveFilters } from 'utils';
import { useFilters, useQueryParams } from 'hooks';
import { ClientFormModal } from './components';
import { MoreVertical } from 'icons';
import { ClientsLabels } from 'components/MainLayout/utils';
import { ClientUserProfile } from 'api/users.types';
import { UserStatuses } from 'types';
import { useTranslation } from 'react-i18next';

export const ClientsPage = () => {
  const queryClient = useQueryClient();
  const params = useQueryParams();
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const [isClientFormOpen, setClientFormOpen] = useState(false);
  const [selectedEditClient, setSelectedEditClient] = useState<number>();
  const [selectedDeleteClient, setSelectedDeleteClient] = useState<any>();
  const [isBulkDeleteModalOpen, setBulkDeleteModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const { filters, onFiltersChange } = useFilters<{
    search: string;
    page: number;
    per_page: number;
    clients_state?: string;
    ordering?: string;
    partners_count_gte?: number;
    partners_count_lte?: number;
  }>(
    {
      ...defaultPaginationProp,
      search: '',
      clients_state: undefined,
      ordering: undefined,
      partners_count_gte: undefined,
      partners_count_lte: undefined
    },
    { keyword: 'clients-filters' }
  );

  const { data: clients, isLoading } = useQuery(
    usersApi.getClientsList({ ...filters, state: params.clients_state })
  );

  const clientStatsQuery = useQuery(usersApi.getClientsStats());

  const getSortingControllerProps = makeSortingControllerHelper(filters.ordering, (value) =>
    onFiltersChange({ ordering: value })
  );

  const columns = useMemo<ColumnsType<ClientUserProfile>>(
    () => [
      {
        title: (
          <SortingController {...getSortingControllerProps('name')}>
            {t('clientName')}
          </SortingController>
        ),
        dataIndex: 'name',
        render: (name, i) => (
          <div>
            <Link to={getRoute('ClientPage', { id: i.id })}>{name}</Link>
            <ClientDescription>{i.department}</ClientDescription>
          </div>
        )
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('apollo_api_key')}>
            {t('apolloId')}
          </SortingController>
        ),
        dataIndex: 'apollo_api_key'
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('state')}>
            {t('profileStatus')}
          </SortingController>
        ),
        dataIndex: 'state',
        render: (state) => <UserStatusLabel status={state} />
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('partners_count')}>
            {t('partnersCount')}
          </SortingController>
        ),
        dataIndex: 'partners_count'
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('created_at')}>
            {t('createProfile')}
          </SortingController>
        ),
        dataIndex: 'created_at',
        render: (createdAt) => (
          <>
            <div>{format(createdAt)}</div>
            <ClientDescription>{format(createdAt, 'HH:mm A')}</ClientDescription>
          </>
        )
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('last_login')}>
            {t('lastActivity')}
          </SortingController>
        ),
        dataIndex: 'last_login',
        render: (lastLogin) => (
          <EmptyField hidden={!lastLogin}>
            <div>{format(lastLogin)}</div>
            <ClientDescription>{format(lastLogin, 'HH:mm A')}</ClientDescription>
          </EmptyField>
        )
      },
      {
        width: 70,
        dataIndex: 'id',
        render: (id, obj) => (
          <Dropdown
            disabled={[UserStatuses.deleted].includes(obj?.state)}
            menu={{
              items: [
                {
                  key: 1,
                  label: (
                    <Typography.Text
                      onClick={() => {
                        setSelectedEditClient(id);
                        setClientFormOpen(true);
                      }}
                    >
                      {t('edit')}
                    </Typography.Text>
                  )
                },
                {
                  key: 2,
                  label: (
                    <Typography.Text onClick={() => setSelectedDeleteClient(obj)}>
                      {t('delete')}
                    </Typography.Text>
                  )
                }
              ]
            }}
            trigger={['click']}
          >
            <StyledMoreVertical />
          </Dropdown>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clients?.results, t]
  );

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<ClientUserProfile> = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
    getCheckboxProps: (record) => ({
      disabled: [UserStatuses.deleted].includes(record.state)
    })
  };

  const StyledMoreVertical = styled(MoreVertical)`
    padding: 5px;
    cursor: pointer;

    &:hover {
      path {
        fill: ${currentTheme['grey-white']};
      }
    }
  `;

  const ClientDescription = styled.div`
    font-size: 0.75rem;
    color: ${currentTheme['grey-60']};
  `;

  const invalidateClientsQueries = () => {
    queryClient.invalidateQueries(usersApi.getClientsList().queryKey);
    queryClient.invalidateQueries(usersApi.getClientsStats().queryKey);
  };

  const deleteMutation = useMutation(() => users.deleteClient(selectedDeleteClient?.id), {
    onSuccess: () => {
      invalidateClientsQueries();
      setSelectedDeleteClient(undefined);

      notification.success({
        message: t('deletedClient'),
        placement: 'bottomRight'
      });
    }
  });

  const deleteBulkMutation = useMutation(() => users.bulkClientDelete({ ids: selectedRowKeys }), {
    onSuccess: () => {
      invalidateClientsQueries();
      setSelectedRowKeys([]);
      setBulkDeleteModalOpen(false);
      notification.success({
        message: t('deletedClients'),
        placement: 'bottomRight'
      });
    }
  });

  const PageHeading = useMemo(() => {
    const foundItem =
      ClientsLabels[(params.clients_state as keyof typeof ClientsLabels) || 'total'];

    return <HeadTitle Icon={foundItem.icon} label={t(foundItem.menuLabel)} />;
  }, [params.clients_state, t]);

  return (
    <MainLayout pageHeading={PageHeading} filters={filters} onChange={onFiltersChange}>
      <ClientFormModal
        open={isClientFormOpen}
        id={selectedEditClient}
        onSuccess={() =>
          notification.success({
            message: t(selectedEditClient ? 'editedClient' : 'addedClient'),
            placement: 'bottomRight'
          })
        }
        onCancel={() => {
          setClientFormOpen(false);

          if (selectedEditClient) setSelectedEditClient(undefined);
        }}
      />

      <DeleteConfirmModal
        title={t('deleteClients')}
        mutation={deleteBulkMutation}
        open={isBulkDeleteModalOpen}
        onCancel={() => setBulkDeleteModalOpen(false)}
      >
        <Typography.Text style={{ color: currentTheme['grey-40'], fontSize: '0.875rem' }}>
          {t('bulkDeleteClientQuestion', { count: selectedRowKeys?.length })}
        </Typography.Text>
      </DeleteConfirmModal>

      <DeleteConfirmModal
        title={t('deleteClient')}
        mutation={deleteMutation}
        open={!!selectedDeleteClient}
        onCancel={() => setSelectedDeleteClient(undefined)}
      >
        <Typography.Text style={{ color: currentTheme['grey-40'], fontSize: '0.875rem' }}>
          {t('deleteClientQuestion')}
          <Typography.Link href={`/partners/${selectedDeleteClient?.id}`}>
            "{selectedDeleteClient?.name}"
          </Typography.Link>
          ?
        </Typography.Text>
      </DeleteConfirmModal>

      <NoContentPlaceholder
        show={
          !clients?.results.length &&
          !isLoading &&
          !hasActiveFilters(filters) &&
          clientStatsQuery.data?.total === 0
        }
        placeholder={
          <NoDataPlaceholder
            mainIcon="user"
            leftIcon="edit"
            onLeftClick={() => null}
            rightIcon="upload-outline"
            onRightClick={() => null}
            description={<div dangerouslySetInnerHTML={{ __html: t('noClientDescription') }} />}
            bottomContent={
              <Button type="primary" onClick={() => setClientFormOpen(true)}>
                {t('add')}
              </Button>
            }
          />
        }
      >
        <FiltersSection
          leftSide={
            <Input
              prefix="search"
              style={{ width: 420 }}
              placeholder={t('searchClient')}
              value={filters.search}
              onChange={(e) => onFiltersChange({ search: e.target.value })}
            />
          }
          rightSide={
            <Space size={16}>
              {Boolean(selectedRowKeys.length) && (
                <Button onClick={() => setBulkDeleteModalOpen(true)}>{t('delete')}</Button>
              )}

              <Button type="primary" onClick={() => setClientFormOpen(true)}>
                {t('add')}
              </Button>
            </Space>
          }
        />

        <Spin spinning={isLoading}>
          <Table
            scrollSize={72}
            dataSource={clients?.results}
            columns={columns}
            rowSelection={rowSelection}
            rowKey="id"
            pagination={false}
            showSorterTooltip={{ children: <>test</> }}
          />
        </Spin>

        <Pagination
          pageSize={filters.per_page}
          current={filters.page}
          total={clients?.count}
          onChange={onFiltersChange}
        />
      </NoContentPlaceholder>
    </MainLayout>
  );
};
