/** @jsxImportSource @emotion/react */

import { useContext, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Spin, Tree, TreeDataNode, TreeProps } from 'antd';
import { css } from '@emotion/react';

import { FoldersContext, ThemeContext } from 'contexts';
import { documentsQueries } from 'api';

import { doublePerPage } from 'utils';

interface FoldersTreeProps extends TreeProps {
  selectedKey?: React.Key;
  onKeyChange?: (key?: React.Key, selectedTree?: React.Key[]) => void;
  search?: string;
  userId?: string | number;
  onSelectTitle?: (title?: React.ReactNode | ((data: TreeDataNode) => React.ReactNode)) => void;
  searchFolders?: boolean;
}

const CONTAINER_HEIGHT = 400;

const CustomWrapper = ({
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) => {
  const { currentTheme } = useContext(ThemeContext);

  const styles = css`
    .ant-tree {
      background-color: transparent;

      color: ${currentTheme['grey-40']};

      &-iconEle {
        display: inline-flex !important;
        align-items: center;
      }

      &-treenode-selected,
      &-treenode-switcher-open {
        color: ${currentTheme['grey-white']};
      }

      &-node-selected {
        background-color: transparent !important;
      }

      &-indent-unit {
        width: 12px;
      }
    }
  `;

  return <div css={styles} {...props} />;
};

export const FoldersTree = ({
  selectedKey,
  onKeyChange,
  search,
  onSelectTitle,
  userId,
  searchFolders,
  ...props
}: FoldersTreeProps) => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(selectedKey ? [selectedKey] : []);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>(selectedKey ? [selectedKey] : []);

  const {
    fetchNextFolderPage,
    formattedData: defaultFormattedData,
    isFolderMutateLoading,
    renderCount,
    setData,
    icon,
    folderData,
    handleExpand
  } = useContext(FoldersContext);

  const [formattedData, setFormattedData] = useState(defaultFormattedData);

  useEffect(() => {
    // Timeout for loading tree data ------------>
    setTimeout(() => {
      setFormattedData([...defaultFormattedData]);
    }, 500);
  }, [defaultFormattedData, folderData]);

  const autoExpand = useRef(true);

  type FunctionSearchType = typeof documentsQueries.getDirectoriesSearchList;

  const fn = (
    searchFolders ? documentsQueries.getDirectoriesSearchList : documentsQueries.getDirectoriesList
  ) as FunctionSearchType;

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching } =
    useInfiniteQuery({
      ...fn({
        per_page: doublePerPage,
        search,
        user_id: userId
      }),
      getNextPageParam: (lastPage) =>
        lastPage.current_page < lastPage.total_pages ? lastPage.current_page + 1 : undefined,
      keepPreviousData: true,
      select: (data) => ({
        ...data,
        pages: data?.pages.flatMap(({ results }): TreeDataNode[] => {
          return results.map(({ id, name }) => ({
            title: name,
            key: id,
            icon,
            className: `key-id--${id}`,
            children: [{ key: `key-${id}`, style: { display: 'none' } }]
          }));
        })
      })
    });

  useEffect(() => {
    setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setSelectedKeys(selectedKey ? [selectedKey] : []);
    setExpandedKeys(selectedKey ? [selectedKey] : []);
    autoExpand.current = true;
  }, [selectedKey]);

  useEffect(() => {
    search
      ? setExpandedKeys(data?.pages.map(({ key }) => key) || [])
      : setExpandedKeys(selectedKey ? [selectedKey] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isFetching]);

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    const dataSetId = (document.querySelector(`div[data-id]`) as HTMLDivElement)?.dataset?.id;
    if (dataSetId) {
      fetchNextFolderPage(undefined, +dataSetId);
    }

    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === CONTAINER_HEIGHT) {
      if (hasNextPage) {
        fetchNextPage();
      }
    }
  };

  return (
    <CustomWrapper key={search}>
      <Spin spinning={isFetchingNextPage || isLoading || isFolderMutateLoading}>
        {!isLoading && (
          <Tree
            key={renderCount}
            autoExpandParent={autoExpand.current}
            virtual
            onScroll={onScroll}
            height={CONTAINER_HEIGHT}
            treeData={[...formattedData]}
            defaultExpandedKeys={selectedKeys}
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
            showIcon
            onSelect={(key, info) => {
              const editedKeys = key.map((v) => +String(v).split('--')[0]);
              onSelectTitle?.(info.node.title);
              setSelectedKeys(editedKeys);

              let child = { children: formattedData };

              const keys: React.Key[] = [];

              info.node.pos
                .split('-')
                .splice(1)
                .forEach((value) => {
                  keys.push(child?.children[+value]?.key);

                  child = { children: child?.children[+value]?.children as TreeDataNode[] };
                });

              onKeyChange?.(editedKeys[0], keys);
            }}
            defaultExpandParent
            onExpand={(value, info) => {
              autoExpand.current = false;
              setExpandedKeys(value);
              if (info.expanded) {
                handleExpand({ id: +info.node.key });
              }
            }}
            {...props}
          />
        )}
      </Spin>
    </CustomWrapper>
  );
};
