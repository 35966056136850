import { ThemeType } from '../../types';
import { css } from '@emotion/react';

export const stylesInit = (theme: ThemeType) => ({
  Container: css`
    margin: 23px 32px;
    background-color: ${theme.colorPrimary};
    padding: 40px;
  `,
  AvatarWrapper: css`
    padding: 24px;
    background-color: white;
    border-radius: 8px;
    position: relative;
  `,
  ColorPikerWrapper: css`
    border-radius: 50%;
    padding: 6px;
    display: flex;

    &:hover {
      background-color: ${theme['grey-10']}40;
      cursor: pointer;
    }
  `,
  ActionsWrapper: css`
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: black;
  `,
  Action: css`
    background-color: ${theme['colorPrimary']};
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 20px;
    width: 20px;
  `
});
