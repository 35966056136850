/** @jsxImportSource @emotion/react */
import { ReactElement, useContext } from 'react';
import { ThemeContext } from 'contexts';
import { stylesInit } from './SideBar.styles';

interface SideBarTitleProps {
  title: ReactElement | string;
  icon: ReactElement;
}

export const SideBarTitle = ({ title, icon }: SideBarTitleProps) => {
  const { currentTheme } = useContext(ThemeContext);
  const styles = stylesInit(currentTheme);

  return (
    <div css={styles.Header}>
      {title}
      {icon}
    </div>
  );
};
