import { useContext } from 'react';
import { Tag as AntTag, Space, SpaceProps, TagProps } from 'antd';

import { ThemeContext } from 'contexts';

export const Tag = ({ ...props }: TagProps) => {
  const { currentTheme } = useContext(ThemeContext);

  return (
    <AntTag
      bordered={false}
      color={currentTheme['grey-70']}
      style={{ color: 'inherit', margin: 0 }}
      {...props}
    />
  );
};

const TagGroup = ({ ...props }: SpaceProps) => (
  <Space style={{ flexWrap: 'wrap' }} size={4} {...props} />
);

Tag.Group = TagGroup;
