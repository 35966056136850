import { css } from '@emotion/react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType) => ({
  Container: css`
    width: 256px;
    min-height: 110vh;
    display: inline-block;
    box-sizing: border-box;
    border-left: 0.5px solid ${theme['grey-70']};
  `,
  Header: css`
    padding: 26px 32px;
    border-bottom: 0.5px solid ${theme['grey-70']};
    color: ${theme['grey-white']};
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;

    span {
      color: ${theme['grey-50']};
    }

    svg {
      float: right;
      width: 20px;
      height: 20px;
    }
  `
});
