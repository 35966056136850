export const palette = {
  'purple-125': '#8D3DFF',
  'purple-100': '#AB70FF',
  'purple-75': '#BF92FF',
  'purple-50': '#D3B4FF80',
  'purple-25': '#EADBFF',
  'red-125': '#F53636',
  'red-100': '#FF4949',
  'red-75': '#FF6D6D',
  'red-50': '#FF9292',
  'red-25': '#FFDBDB',
  'blue-125': '#0054D1',
  'blue-100': '#0066FF',
  'blue-75': '#4992FF',
  'blue-50': '#92BEFF',
  'blue-25': '#DBE9FF',
  'green-125': '#1EA356',
  'green-100': '#39C674',
  'green-75': '#71D69B',
  'green-50': '#AAE7C3',
  'green-25': '#E3F7EB',
  'yellow-125': '#F9A606',
  'yellow-100': '#F9C206',
  'yellow-75': '#FAD34D',
  'yellow-50': '#FCE594',
  'yellow-25': '#FEF6DB',
  'orange-125': '#FC6C03',
  'orange-100': '#FC8303',
  'orange-75': '#FDA64B',
  'orange-50': '#FECA93',
  'orange-25': '#FFEDDB',
  'grey-100': '#1A1E2C',
  'grey-90': '#1B2032',
  'grey-80': '#222942',
  'grey-70': '#424D70',
  'grey-60': '#556290',
  'grey-50': '#6F7CAA',
  'grey-40': '#8F99BD',
  'grey-30': '#AFB6D0',
  'grey-20': '#CFD3E3',
  'grey-10': '#EDEFF4',
  'grey-white': '#FFFFFF'
};
