import { css } from '@emotion/react';
import { ThemeType } from 'types';
import { palette } from 'utils';

export const stylesInit = (theme: ThemeType) => ({
  Container: css`
    padding: 18px 32px;
    border-bottom: 0.5px solid ${theme['grey-70']};
    box-sizing: border-box;
  `,
  Title: css`
    color: ${theme.colorWhite};
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    padding: 8px 0;
  `,
  Button: css`
    direction: rtl;
  `,
  RejectButton: css`
    &.ant-btn.ant-btn-default:not(:disabled) {
      border-color: ${palette['red-125']};

      &:hover {
        border-color: ${palette['red-100']};
      }
    }
  `
});
