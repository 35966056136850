import type { CSSProperties } from 'react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType, isSelected?: boolean) => ({
  folderLabelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme['grey-90'],
    padding: '10px',
    borderRadius: '8px',
    cursor: 'pointer',
    border: `1px solid ${isSelected ? theme['purple-100'] : 'transparent'}`,
    overflow: 'hidden'
  },

  fullWidth: {
    width: '100%'
  },
  folderListContainer: {
    width: 'calc(100% - 10px)',
    maxHeight: '142px',
    paddingRight: '10px',
    overflowY: 'auto'
  } as CSSProperties,
  folderModalHeader: {
    marginBottom: '40px'
  }
});
