/** @jsxImportSource @emotion/react */

import { Space, Pagination as AntPagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';

import { Select } from 'components';
import { $Object } from 'types';
import { perPageList } from 'utils';
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'icons';
import { ThemeContext } from 'contexts';

import { stylesInit } from './pagination.styles';
import styled from '@emotion/styled';

export interface PaginationProps {
  pageSize: number;
  total?: number;
  current?: number;
  onChange: (val: $Object) => void;
}

export const Pagination = ({ pageSize = 8, total, current, onChange }: PaginationProps) => {
  const { t } = useTranslation();
  const { currentTheme } = useContext(ThemeContext);

  const styles = stylesInit(currentTheme);

  const StyledPagination = styled(AntPagination)`
    .ant-pagination-item {
      a {
        color: ${currentTheme['grey-40']};
      }
      &-active {
        background-color: ${currentTheme['grey-70']} !important;
        border: none;
        a {
          color: ${currentTheme['grey-20']};
        }
      }
    }
  `;

  const StyledSelect = styled(Select)`
    .ant-select-selector {
      background-color: ${currentTheme['grey-70']} !important;
    }

    &.ant-select-open {
      .ant-select-selection-item {
        color: ${currentTheme['grey-white']};
      }
    }
  `;

  return (
    <div css={styles}>
      <Space style={{ justifyContent: 'space-between', width: '100%' }}>
        <Space size={16}>
          <StyledSelect
            bordered={false}
            value={pageSize}
            options={perPageList.map((item) => ({ label: item, value: item }))}
            onChange={(per_page) => onChange({ per_page, page: 1 })}
            popupMatchSelectWidth={false}
          />
          {t('resultsPerPage')}
        </Space>
        <StyledPagination
          prevIcon={<ChevronLeft fontSize={20} />}
          nextIcon={<ChevronRight fontSize={20} />}
          jumpPrevIcon={<MoreHorizontal fontSize={20} />}
          jumpNextIcon={<MoreHorizontal fontSize={20} />}
          pageSize={pageSize}
          total={total}
          current={current}
          onChange={(page) => onChange({ page })}
          showSizeChanger={false}
          showLessItems
        />
      </Space>
    </div>
  );
};
