import { $Object } from '../types';

export const withValues = (obj: $Object): $Object =>
  Object.keys(obj)
    .filter((i) => {
      const value = obj[i];

      return value !== null && value !== undefined && value !== '';
    })
    .reduce((acc, current) => ({ ...acc, [current]: obj[current] }), {});

export function getOnlyExistingKeys<T extends $Object>(obj1: $Object, obj2: $Object): T {
  const obj1Keys = Object.keys(obj1);

  return Object.keys(obj2).reduce(
    (acc, i) => ({ ...acc, ...(obj1Keys.includes(i) ? { [i]: obj2[i] } : {}) }),
    {} as T
  );
}

// rewrites types of default Object.keys function
export function keys<O extends object>(o: O): (keyof O)[] {
  return Object.keys(o) as (keyof O)[];
}

export function stringifyObjectValues(object: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(object).map((entry) => [
      entry[0],
      entry[1] instanceof Object ? encodeURIComponent(JSON.stringify(entry[1])) : entry[1]
    ])
  );
}

export function recursiveEmptyWithNull<T extends $Object>(obj: T, setNull?: boolean) {
  const iter = (value: $Object) => {
    for (let key in value) {
      const item = value[key];
      if (typeof item === 'string' && !item.trim()) {
        value[key] = null;
      } else if (setNull && item === undefined) {
        value[key] = null;
      } else if (typeof item === 'object') {
        iter(item);
      }
    }
  };

  iter(obj);
  return obj;
}
