import { Skeleton, Space } from 'antd';
import { PropsWithChildren, useContext } from 'react';
import styled from '@emotion/styled';
import { ThemeContext } from 'contexts';

interface MenuLoadingInterface {
  lines?: number;
  loading?: boolean;
}

export const MenuLoading = ({
  children,
  lines = 4,
  loading = false
}: PropsWithChildren<MenuLoadingInterface>) => {
  const { currentTheme } = useContext(ThemeContext);

  const LoadingContainer = styled(Space)`
    padding: 0.625rem 1.25rem;
    font-size: 14px;
    color: ${currentTheme['grey-50']};
    justify-content: space-between;
    width: calc(100% - 2.5rem);
  `;

  return (
    <>
      {loading ? (
        <LoadingContainer styles={{ item: { display: 'flex' } }} direction="vertical" size={24}>
          {Array.from({ length: lines }).map((_, count) => (
            <Skeleton.Button active block key={count} />
          ))}
        </LoadingContainer>
      ) : (
        children
      )}
    </>
  );
};
