import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ActionHeadTitle,
  DetailsCard,
  MainLayout,
  ProfilePageTemplate,
  UserStatusLabel
} from 'components';
import { users, usersApi } from 'api';
import { UserContext } from 'contexts';
import { dateTimeRowFormat, format } from 'utils';
import { BarChart, ShortLeft } from 'icons';

export const MyProfilePage = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data: userData } = useContext(UserContext);

  const updateMutate = useMutation((data: FormData) => users.updateAdmin(data, userData?.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(usersApi.getLoggedUserData().queryKey);
    },
    onError: (err) => {
      console.log(err, 'err');
    }
  });

  return (
    <MainLayout pageHeading={<ActionHeadTitle Icon={ShortLeft} label={t('yourProfile')} />}>
      <ProfilePageTemplate data={userData} mutation={updateMutate}>
        <DetailsCard
          title={t('activity')}
          Icon={BarChart}
          items={[
            {
              label: t('profileStatus'),
              value: <UserStatusLabel unfilled status={userData?.state} />
            },
            {
              label: t('profileCreatedAt'),
              value: userData?.created_at && format(userData.created_at, dateTimeRowFormat)
            },
            {
              label: t('lastActivity'),
              value: userData?.last_login && format(userData.last_login, dateTimeRowFormat)
            }
          ]}
        />
      </ProfilePageTemplate>
    </MainLayout>
  );
};
