import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Divider, Row, Space, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { Link, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useIntersectionObserver } from '@react-hookz/web';

import { documentsQueries, users, usersApi } from 'api';
import {
  ActionHeadTitle,
  BarChart,
  DocumentStatusLabel,
  DocumentTypeIcon,
  EditFolderName,
  EmptyField,
  FiltersSection,
  FoldersBreadcrumb,
  FoldersTreeMenuSection,
  Input,
  MainLayout,
  makeSortingControllerHelper,
  NoContentPlaceholder,
  NoDataPlaceholder,
  Pagination,
  PartnerDetails,
  Put,
  Select,
  SignedUpload,
  SortingController,
  Table,
  Text
} from 'components';
import { DocumentsLabels } from 'components/MainLayout/utils';
import { AnalyticsOutline, Calendar, ShortLeft } from 'icons';
import { useFilters, useQueryParams } from 'hooks';
import { FoldersContext, NotificationContext, ThemeContext } from 'contexts';
import { chartDateFilterOptions } from 'features/client/pages/utils';
import { defaultPaginationProp, format, getRoute, keys, mimeTypeToDocumentType } from 'utils';
import { APIDocument, Directory, DocumentStatus, PartnerDocumentsFiltersValue } from 'types';
import { PartnersMenuSection } from 'features/common/components';

const StyledResponsiveContainerWrapper = styled.div`
  margin: 32px;
  margin-left: 16px;
`;

export const PartnerPage = () => {
  const { id } = useParams();
  const params = useQueryParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const lastItemRef = useRef<HTMLDivElement>(null);

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);
  const { fetchNextFolderPage, folderData, isFolderMutateLoading, setSelectedTreeFilter } =
    useContext(FoldersContext);

  const [isFilesUploading, setIsFilesUploading] = useState(false);

  const { data: partner, isLoading: isLoadingPartner } = useQuery({
    ...usersApi.getPartnerProfile(Number(id)),
    enabled: !!id
  });

  const { filters, onFiltersChange: onChange } = useFilters<PartnerDocumentsFiltersValue>(
    {
      ...defaultPaginationProp,
      search: '',
      documents_state: undefined,
      ordering: undefined,
      last_x_months: chartDateFilterOptions[0].value,
      charts_enabled: true,
      created_at_after: '',
      created_at_before: '',
      directory: undefined,
      documents_archive: undefined,
      '--selectedTree': undefined
    },
    { keyword: 'documents-filters', objectKeys: ['partner'], useQueryParams: true }
  );

  const onFiltersChange = (data: Partial<PartnerDocumentsFiltersValue>, reset?: boolean) =>
    onChange?.({ ...data, documents_archive: false, directory: null }, reset);

  const { data, isLoading } = useQuery({
    ...documentsQueries.getList({
      ...filters,
      partner: id,
      user_id: id,
      state: filters.documents_state === DocumentStatus.total ? undefined : filters.documents_state
    })
  });

  const { data: chartData, isLoading: isLoadingChart } = useQuery({
    ...documentsQueries.getCharts({
      ...filters,
      user_id: id,
      state: filters.documents_state === DocumentStatus.total ? undefined : filters.documents_state
    }),
    enabled: Boolean(filters.charts_enabled)
  });

  const { data: foldersListData, isLoading: isFoldersLoadingListDefault } = useQuery({
    ...documentsQueries.getDirectoriesList({
      parent: filters.directory,
      per_page: 1000,
      search: filters.search,
      user_id: id,
      ordering: filters.ordering?.replace('title', 'name')
    }),
    select: (data) => {
      return {
        children: data?.results?.map(({ children, ...rest }) => ({ ...rest, children: undefined }))
      };
    },
    enabled: !!filters.documents_archive && !filters.directory
  });

  const foldersData = useMemo(
    () =>
      filters.directory
        ? {
            children: folderData?.children.map((directory) => ({
              ...directory,
              children: directory.children
            }))
          }
        : foldersListData,
    [filters.directory, folderData, foldersListData]
  );

  const isFoldersLoading =
    ((!filters.directory && isFoldersLoadingListDefault) ||
      (!!filters.directory && isFolderMutateLoading)) &&
    !!filters.documents_archive;

  useEffect(() => {
    onFiltersChange({
      ...defaultPaginationProp,
      ...params,
      last_x_months: chartDateFilterOptions[0].value
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSortingControllerProps = makeSortingControllerHelper(filters.ordering, (value) =>
    onFiltersChange({ ordering: value })
  );

  const intersection = useIntersectionObserver(lastItemRef.current);

  useEffect(() => {
    if (!intersection?.isIntersecting || !(filters.documents_archive && filters.directory)) return;
    fetchNextFolderPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersection?.isIntersecting, filters.documents_archive, filters.directory]);

  const columns = useMemo<ColumnsType<any>>(
    () => [
      {
        className: 'absolute',
        render: (_, __, index) =>
          foldersData && index + 1 === foldersData?.children?.length ? (
            <div ref={lastItemRef} />
          ) : null
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('title')}>
            {t('documentName')}
          </SortingController>
        ),
        dataIndex: 'title',
        render: (title, { size, name, ...record }) =>
          !('children' in record) ? (
            <Link to={getRoute('PartnerDocumentItemPage', { id: record.id })}>
              <Space align="center">
                <DocumentTypeIcon type={mimeTypeToDocumentType(title)} />
                <div>
                  <div>{title}</div>
                  <PartnerDescription>{size}</PartnerDescription>
                </div>
              </Space>
            </Link>
          ) : (
            <Link
              to={getRoute('PartnerPage', {
                id,
                query: {
                  page: filters.page,
                  per_page: filters.per_page,
                  directory: record.id,
                  documents_archive: filters.documents_archive,
                  '--selectedTree': setSelectedTreeFilter(id!, filters)
                }
              })}
            >
              <EditFolderName id={record.id} type={record.type} userId={record.user}>
                {name}
              </EditFolderName>
            </Link>
          ),
        width: '50%'
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('directory')}>Folder</SortingController>
        ),
        width: '150px',
        dataIndex: ['directory', 'client_directory', 'name'],
        render: (folderName: string, record) => (
          <>
            <Text size="lg" title={folderName}>
              <Put condition={!!folderName}>
                <Link
                  to={getRoute('PartnerPage', {
                    id,
                    query: {
                      page: filters.page,
                      per_page: filters.per_page,
                      directory: record?.directory?.client_directory?.id
                    }
                  })}
                >
                  {folderName}
                </Link>
              </Put>
            </Text>
          </>
        )
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('sent_at')}>
            {t('uploadDate')}
          </SortingController>
        ),
        dataIndex: 'sent_at',
        render: (sentAt) => (
          <EmptyField hidden={!sentAt}>
            <div>{format(sentAt)}</div>

            <PartnerDescription>{format(sentAt, 'HH:mm A')}</PartnerDescription>
          </EmptyField>
        )
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('state')}>
            {t('documentState')}
          </SortingController>
        ),
        dataIndex: 'state',
        render: (state, record) =>
          !('children' in record) ? <DocumentStatusLabel status={state} /> : null
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.results, foldersData]
  );

  const onChartRangeChange = (option: number) =>
    onFiltersChange({
      last_x_months: option
    });
  const onChartEnabledToggle = () =>
    onFiltersChange({
      charts_enabled: !filters.charts_enabled
    });

  const onStatusChange = (status: DocumentStatus) => {
    onFiltersChange({ documents_state: status });
  };

  const PartnerDescription = useMemo(
    () => styled.div`
      font-size: 0.75rem;
      color: ${currentTheme['grey-60']};
    `,
    [currentTheme]
  );

  const PartnerBody = useMemo(
    () => styled.div`
      overflow: hidden;
      width: 100%;
      flex: 1;

      .ant-input-affix-wrapper:before {
        display: none;
      }
    `,
    []
  );

  const showFoldersData =
    filters.page === 1 &&
    !(filters.documents_state && !filters.directory) &&
    filters.documents_archive;

  return (
    <MainLayout
      pageHeading={
        partner && (
          <FoldersBreadcrumb>
            <ActionHeadTitle Icon={ShortLeft} label={partner.name} />
          </FoldersBreadcrumb>
        )
      }
      menuSections={[
        <PartnersMenuSection onChange={onFiltersChange} />,
        <FoldersTreeMenuSection userId={id} filters={filters} onFiltersChange={onChange} />
      ]}
    >
      <Spin spinning={isLoadingPartner || isFoldersLoading}>
        <NoContentPlaceholder
          show={false}
          placeholder={
            <NoDataPlaceholder
              mainIcon="file-black-outline"
              leftIcon="document-text"
              onLeftClick={() => null}
              rightIcon="document-pdf"
              onRightClick={() => null}
              description={<div dangerouslySetInnerHTML={{ __html: t('noPartnerDocuments') }} />}
            />
          }
        >
          <Row align="top" justify="space-between">
            <Col flex={1}>
              <PartnerBody>
                <FiltersSection
                  leftSide={
                    <Space size={16}>
                      <Select
                        dropdownStyle={{ minWidth: 160 }}
                        defaultValue={DocumentStatus.total}
                        value={filters.documents_state || DocumentStatus.total}
                        onChange={onStatusChange}
                        options={keys(DocumentsLabels).map((item) => ({
                          label: t(DocumentsLabels[item].label),
                          value: item
                        }))}
                      />
                      <Input
                        prefix="search"
                        style={{ width: 420 }}
                        placeholder={t('searchDocuments')}
                        value={filters.search}
                        onChange={(e) => onChange({ search: e.target.value })}
                      />
                    </Space>
                  }
                  rightSide={
                    <Space size={16} styles={{ item: { display: 'flex' } }}>
                      <AnalyticsOutline
                        fontSize={24}
                        fill={
                          filters.charts_enabled
                            ? currentTheme['purple-100']
                            : currentTheme['grey-70']
                        }
                        onClick={onChartEnabledToggle}
                        style={{ cursor: 'pointer' }}
                      />
                      <Select
                        value={filters.last_x_months}
                        onChange={onChartRangeChange}
                        prefixIcon={<Calendar />}
                        popupMatchSelectWidth={false}
                        options={chartDateFilterOptions}
                      />
                      <SignedUpload
                        beforeUploadCall={() => setIsFilesUploading(true)}
                        apiCall={(data) => users.getPartnerSignedUrls(Number(id), data)}
                        afterUploadCall={() => {
                          queryClient.invalidateQueries(
                            documentsQueries.getPartnerStats().queryKey
                          );
                          queryClient.invalidateQueries(documentsQueries.getList().queryKey);

                          notification.success({
                            message: t('documentsAppearAfterProcessing')
                          });

                          setIsFilesUploading(false);
                        }}
                      >
                        <Button type="primary" loading={isFilesUploading}>
                          {t('upload')}
                        </Button>
                      </SignedUpload>
                    </Space>
                  }
                />

                <Divider style={{ marginTop: '0' }} />

                {filters.charts_enabled && (
                  <Spin spinning={isLoadingChart}>
                    <StyledResponsiveContainerWrapper>
                      <ResponsiveContainer width="100%" height="100%" minHeight={400}>
                        <BarChart staticLabel={t('documents')} data={chartData || []} />
                      </ResponsiveContainer>
                    </StyledResponsiveContainerWrapper>
                  </Spin>
                )}
                <Spin spinning={isLoading}>
                  <Table
                    scrollSize={72 + 25}
                    dataSource={
                      [
                        ...(showFoldersData ? foldersData?.children || [] : []),
                        ...(data?.results || [])
                      ] as (APIDocument & Directory)[]
                    }
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    rowClassName={(_, index) =>
                      foldersData && index + 1 === foldersData?.children?.length ? 'last-item' : ''
                    }
                  />
                </Spin>
                <Pagination
                  pageSize={filters.per_page}
                  current={filters.page}
                  total={data?.count}
                  onChange={onFiltersChange}
                />
              </PartnerBody>
            </Col>
            <Col>{partner && <PartnerDetails partner={partner} />}</Col>
          </Row>
        </NoContentPlaceholder>
      </Spin>
    </MainLayout>
  );
};
