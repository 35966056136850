import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../contexts';
import { getMainRouteName, getRoute, isLogged } from '../utils';

import { FullpageSpinner } from './FullpageSpinner';

export const IndexRedirect = () => {
  const navigate = useNavigate();

  const { role } = useContext(UserContext);

  useEffect(() => {
    const initialRedirectCheck = () => {
      if (role.role) return navigate(getRoute(getMainRouteName(role)));

      if (!isLogged) return navigate(getRoute('ClientLogInPage'));
    };

    initialRedirectCheck();
  }, [role, navigate]);

  return <FullpageSpinner />;
};
