import { css } from '@emotion/react';
import { ThemeType } from 'types';
import notFoundBg from 'assets/svg/not-found-background.svg';

export const stylesInit = (theme: ThemeType) => ({
  notFoundBgImage: css`
    height: 100vh;
    background-image: url(${notFoundBg});
    background-size: cover;
  `,
  notFoundContainer: css`
    height: 88vh;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  notFoundWrapper: css`
    display: flex;
    flex-direction: column;
    justify-items: center;
  `,
  iconWrapper: css`
    border: 1px solid ${theme['grey-70']};
    padding: 40px;
    border-radius: 40px;
    position: relative;
  `,
  notFoundCode: css`
    position: absolute;
    left: 35%;
    top: calc(50% - 20px);
    width: 24px;
    height: 24px;
    padding: 8px;
  `
});
