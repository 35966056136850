/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { documentColor, DocumentType, DocumentTypeIcon, Flex } from 'components';
import { ThemeContext } from 'contexts';
import { mimeTypeToDocumentType } from 'utils';
import { APIDocument } from 'types';
import { stylesInit } from './DelegateDocumentHeader.styles';

interface DelegateDocumentHeaderProps {
  document: APIDocument;
}

const documentTypes: { [key in DocumentType]: string } = {
  pdf: 'Acrobat DPF',
  doc: 'Text doc',
  img: 'Imagine',
  unknown: 'necunoscut'
};

export const DelegateDocumentHeader = ({ document }: DelegateDocumentHeaderProps) => {
  const { currentTheme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const styles = stylesInit(currentTheme);
  const documentType = mimeTypeToDocumentType(document.title) || 'unknown';

  return (
    <Flex gap="8px" justify="space-between" css={styles.Container}>
      <Flex gap="8px">
        <DocumentTypeIcon
          bgColor={'transparent'}
          color={documentColor[documentType]}
          size={2}
          type={documentType}
        />
        <span css={styles.Title}>
          {t('thisIsDocument')} {documentTypes[documentType]}
        </span>
      </Flex>
    </Flex>
  );
};
