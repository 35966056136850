import { useState } from 'react';
import { BarChart as ExternalBarChart, XAxis, YAxis, Tooltip, Bar, Cell } from 'recharts';

import { format, monthFormat, palette } from 'utils';

import { CustomTooltip } from './CustomTooltip';
import styled from '@emotion/styled';
import { DocumentsCharts } from 'types';

export interface BarChartProps extends React.ComponentProps<typeof ExternalBarChart> {
  data: DocumentsCharts[];
  /**
   * Make the label of the tooltip a static value instead of the one from data
   */
  staticLabel?: string;
}

const StyledBarChart = styled(ExternalBarChart)`
  outline: none;

  .recharts-text.recharts-cartesian-axis-tick-value {
    fill: ${palette['grey-50']};
    font-size: 14px;
  }

  .recharts-layer.recharts-bar-rectangle {
    rect {
      transition: 0.2s;
    }
  }
`;

export const BarChart = ({ data, children, staticLabel, ...props }: BarChartProps) => {
  const [hoveredBarIndex, setHoveredBarIndex] = useState<number>();

  return (
    <StyledBarChart
      data={data.map((i) => ({
        name: format(i.month, monthFormat),
        value: i.count
      }))}
      onMouseMove={(state) => {
        setHoveredBarIndex(state.isTooltipActive ? state.activeTooltipIndex : undefined);
      }}
      {...props}
    >
      <XAxis padding={{ left: 16 }} dataKey="name" axisLine={false} tickLine={false} />
      <YAxis padding={{ bottom: 20 }} axisLine={false} tickLine={false} />
      <Tooltip cursor={false} content={<CustomTooltip staticLabel={staticLabel} />} />
      <Bar
        dataKey="value"
        rx={16}
        shape={(props) => (
          <>
            <rect {...props} fill={palette['grey-80']} />
            {/* Workaround for hovering */}
            <rect
              {...props}
              fill="url(#bar-chart-active-bar-gradient)"
              opacity={props.className === 'active' ? '1' : '0'}
            />
          </>
        )}
      >
        {data.map((_, idx) => (
          <Cell
            key={idx}
            // Workaround: using classname to check if the bar is being hovered in the shape={} prop of <Bar />
            className={idx === hoveredBarIndex ? 'active' : undefined}
          />
        ))}
      </Bar>
      {children}
    </StyledBarChart>
  );
};
