import { PropsWithChildren, ReactNode } from 'react';

interface NoContentPlaceholderInterface {
  show: boolean;
  placeholder: ReactNode;
}

export const NoContentPlaceholder = ({
  children,
  show,
  placeholder
}: PropsWithChildren<NoContentPlaceholderInterface>) => {
  return <div>{show ? placeholder : children}</div>;
};
