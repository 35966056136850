/** @jsxImportSource @emotion/react */
import { Button, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import mime from 'mime';
import {
  DocumentTypeIcon,
  DocumentPDF,
  DocumentImage,
  DocumentMap,
  documentColor
} from 'components';
import { mimeTypeToDocumentType } from 'utils';
import { documentsQueries } from 'api';
import { APIDocument } from 'types';
import { stylesInit } from './DocumentContent.styles';

interface DocumentContentProps {
  document: APIDocument;
  withoutImage?: boolean;
}

export const DocumentContent = ({ document, withoutImage }: DocumentContentProps) => {
  const styles = stylesInit();

  const { data: images, isLoading } = useQuery({
    ...documentsQueries.getPng(document.id),
    enabled: !withoutImage
  });

  if ((isLoading || images?.length) && !withoutImage) {
    return (
      <Spin spinning={isLoading}>
        <DocumentMap images={images || []} document={document} />
      </Spin>
    );
  }

  const fileName = document.title;
  if (fileName.endsWith('.pdf')) return <DocumentPDF document={document} />;
  const mimeType = mime.getType(fileName) || '';

  if (mimeType.toString().startsWith('image')) return <DocumentImage document={document} />;

  const documentType = mimeTypeToDocumentType(document.title) || 'unknown';

  return (
    <div css={styles.Container}>
      <div css={styles.IconContainer}>
        <DocumentTypeIcon
          bgColor={'transparent'}
          color={documentColor[documentType]}
          size={6.5}
          type={documentType}
        />
        <Button type="primary" css={styles.DownloadButton}>
          <a download target="_blank" href={document.file} rel="noreferrer">
            Descarca
          </a>
        </Button>
      </div>
    </div>
  );
};
