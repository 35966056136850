import { Space } from 'antd';
import { ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'contexts';
import styled from '@emotion/styled';

interface Props {
  Icon: any;
  label?: ReactNode;
}

export const HeadTitle = ({ Icon, label }: Props) => {
  const { currentTheme } = useContext(ThemeContext);

  const IconWrapper = styled.div`
    border-radius: 8px;
    background-color: ${currentTheme['grey-70']};
    min-width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  return (
    <Space styles={{ item: { display: 'flex' } }} size={16}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      {label}
    </Space>
  );
};

export const ActionHeadTitle = ({ Icon, label }: Props) => {
  const navigate = useNavigate();

  const { currentTheme } = useContext(ThemeContext);

  return (
    <Space styles={{ item: { display: 'flex' } }}>
      <Icon
        fill={currentTheme['colorPrimary']}
        onClick={() => navigate(-1)}
        style={{ cursor: 'pointer' }}
      />
      {label}
    </Space>
  );
};
