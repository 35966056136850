import { routes } from '../routes';
import { default as queryString } from 'query-string';

export const getRoute = (name: string, params: { [key: string]: any } = {}) => {
  const route = routes.find((item) => item.name === name);

  if (route) {
    let { path } = route;

    Object.keys(params).map(
      (key) => (path = path.replace(key === '*' ? '*' : `:${key}`, params[key]))
    );

    return params.query
      ? `${path}?${queryString.stringify(params.query, { arrayFormat: 'comma' })}`
      : path;
  }

  return '';
};

export const getActiveRouteByPath = (path: string) => {
  const activeRoute = routes.find((i) => comparePaths(i.path, path));

  return (activeRoute && activeRoute.name) || '';
};

export const comparePaths = (path1: string, path2: string) => {
  const path2Array = path2.split('/');
  let isRightPath = true;

  path1.split('/').map((i, key) => {
    if (i.includes(':') && path2Array[key]) {
      return i;
    }

    if (i !== path2Array[key]) {
      isRightPath = false;
    }

    return i;
  });

  return isRightPath;
};
