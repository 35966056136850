import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';

import { usersApi } from 'api';
import { QuerySelectionCollapse } from 'components';
import { getRoute } from 'utils';
import { onFilterChange } from 'hooks';
import { $Object } from 'types';
import { CircleCheck } from 'icons';

import { PartnersLabels, getOrderStatus } from 'components/MainLayout/utils';

interface PartnersMenuSectionInterface {
  onChange: onFilterChange<$Object>;
}

export const PartnersMenuSection = ({ onChange }: PartnersMenuSectionInterface) => {
  const { t } = useTranslation();
  const params = useParams<{ domain: string }>();

  return (
    <QuerySelectionCollapse
      title={
        <Space size={4} styles={{ item: { display: 'flex' } }}>
          <CircleCheck /> {t('status').toUpperCase()}
        </Space>
      }
      query={usersApi.getPartnersStats}
      paramsKey="state"
      route={getRoute('PartnersPage', params)}
      translates={PartnersLabels}
      onChange={onChange}
      select={getOrderStatus}
    />
  );
};
