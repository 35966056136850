import { css } from '@emotion/react';

import { palette } from 'utils';

export const radioStyles = css`
  .ant-radio-wrapper:not(.ant-radio-wrapper-disabled):hover .ant-radio-inner,
  .ant-radio:not(.ant-radio-disabled):hover .ant-radio-inner {
    border-color: ${palette['grey-50']};
  }

  .ant-radio-wrapper:focus-within:not(.ant-radio-wrapper-disabled) {
    .ant-radio-inner {
      border-color: ${palette['grey-50']};
      box-shadow: 0 0 0 4px rgba(171, 112, 255, 0.2);
    }
  }

  .ant-radio-wrapper-disabled {
    color: ${palette['grey-40']};
  }

  .ant-radio-wrapper .ant-radio-disabled .ant-radio-inner {
    background-color: ${palette['grey-80']};
    border-color: transparent;
    &::after {
      background-color: ${palette['grey-60']};
    }
  }
`;
