import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';

import { documentsQueries } from 'api';
import { QuerySelectionCollapse } from 'components';
import { getRoute } from 'utils';
import { onFilterChange } from 'hooks';
import { ClientDocumentsFiltersValue } from 'types';
import { CircleCheck } from 'icons';

import { DocumentsLabels, getOrderStatus } from 'components/MainLayout/utils';
import { useContext } from 'react';
import { UserContext } from 'contexts';

interface DocumentsMenuSectionInterface {
  onChange?: onFilterChange<ClientDocumentsFiltersValue>;
  filters?: ClientDocumentsFiltersValue;
}

export const DocumentsMenuSection = ({ onChange, filters }: DocumentsMenuSectionInterface) => {
  const { t } = useTranslation();
  const params = useParams<{ domain: string }>();

  const {
    role: { isPartner, isClient }
  } = useContext(UserContext);

  if (!(isClient || isPartner)) return null;

  return (
    <QuerySelectionCollapse
      title={
        <Space size={4} styles={{ item: { display: 'flex' } }}>
          <CircleCheck /> {t('status').toUpperCase()}
        </Space>
      }
      query={isClient ? documentsQueries.getClientStats : documentsQueries.getPartnerStats}
      select={getOrderStatus}
      paramsKey="documents_state"
      route={getRoute(isClient ? 'DocumentsPage' : 'PartnerDocumentsPage', params)}
      translates={DocumentsLabels}
      onChange={onChange}
      params={{ user_id: filters?.partner_ids }}
    />
  );
};
