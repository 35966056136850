import { useContext } from 'react';
import styled from '@emotion/styled';
import { CommentsContext, ThemeContext } from 'contexts';
import { base64Image } from 'utils';

interface DocumentMapItemProps {
  image: string;
  coordinates: DrawCommentProps[];
}

interface DrawCommentProps {
  item: number;
  thread_id: string;
  width: number;
  height: number;
  coordinateX: number;
  coordinateY: number;
}

export const DocumentMapItem = ({ image, coordinates }: DocumentMapItemProps) => {
  const { activeThread } = useContext(CommentsContext);
  const { currentTheme } = useContext(ThemeContext);

  const DrawComment = styled.div<DrawCommentProps>`
    position: absolute;
    border: 2px solid
      ${(props) =>
        props.thread_id === activeThread ? currentTheme['orange-100'] : currentTheme['grey-40']};
    background-color: ${(props) =>
      props.thread_id === activeThread ? currentTheme['orange-100'] : currentTheme['grey-40']}99;
    width: ${(props) => props.width}%;
    height: ${(props) => props.height}%;
    display: block;
    top: ${(props) => props.coordinateY}%;
    left: ${(props) => props.coordinateX}%;
    transform: translate(-2px, -2px);

    &:after {
      content: '${(props) => props.item}';
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: -12px;
      left: -12px;
      border-radius: 100%;
      background-color: ${(props) =>
        props.thread_id === activeThread ? currentTheme['orange-100'] : currentTheme['grey-40']};
      text-align: center;
    }
  `;

  return (
    <div>
      <img src={base64Image(image)} alt={document.title} />
      {coordinates.map((item) => (
        <DrawComment
          id={item.thread_id}
          thread_id={item.thread_id}
          item={item.item}
          width={item.width}
          height={item.height}
          coordinateX={item.coordinateX}
          coordinateY={item.coordinateY}
        />
      ))}
    </div>
  );
};
