import { SerializedStyles } from '@emotion/utils';
import styled from '@emotion/styled';

import { ComponentProps } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

type TextSize = 'sm' | 'md' | 'lg';
type StyledTextProps = ComponentProps<typeof Text> & {
  size?: TextSize;
  styles?: SerializedStyles;
  lines?: string;
};

export const textSize: Record<TextSize, number> = {
  sm: 0.75,
  md: 0.875,
  lg: 1
};
export const textLineHeight: Record<TextSize, number> = {
  sm: 1,
  md: 1.125,
  lg: 1.25
};

const StyledText = styled(Text)<StyledTextProps>`
  font-size: ${({ size }) => textSize[size || 'md']}rem;
  line-height: ${({ size }) => textLineHeight[size || 'md']}rem;
  color: ${({ color }) => color || 'inherit'};
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines || '1'};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ styles }) => styles};
`;

export { StyledText as Text };
