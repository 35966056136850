import { useContext, useMemo, useState } from 'react';
import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { DeleteConfirmModal, HeadTitle, HeaderActions, Input, MainLayout, Text } from 'components';
import { useFilters, useModalState } from 'hooks';
import { Lock, Plus } from 'icons';
import { NotificationContext, ThemeContext, UserContext } from 'contexts';
import { AccessLevel } from 'api/users.types';
import { users, usersApi } from 'api';
import { getNotificationError } from 'utils';
import { AccessLevelStatuses } from 'components/utils';

import {
  AccessLevelFormModal,
  AccessLevelsMenuSection,
  AccessLevelsTable,
  AccessLevelsGroupsMenuSection
} from '../components';

export interface AccessLevelsPageFiltersValue {
  search: string;
  page: number;
  per_page: number;
  state?: string;
  ordering?: string;
  access_levels_state?: AccessLevelStatuses;
  is_active?: boolean;
  access_level_group?: number;
}

const isActive: Record<AccessLevelStatuses, boolean | undefined> = {
  [AccessLevelStatuses.active]: true,
  [AccessLevelStatuses.inactive]: false,
  [AccessLevelStatuses.total]: undefined
};

export const AccessLevelsPage = () => {
  const { t } = useTranslation();
  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);
  const { isAdminOrganization } = useContext(UserContext);
  const accessLevelModal = useModalState<AccessLevel>();
  const deleteAccessLevelModal = useModalState<AccessLevel>();
  const queryClient = useQueryClient();

  const [selectedLevels, setSelectedLevels] = useState<number[]>([]);

  const { filters: defaultFilters, onFiltersChange } = useFilters<AccessLevelsPageFiltersValue>(
    {
      search: '',
      page: 1,
      per_page: 8,

      access_levels_state: undefined,
      ordering: undefined,
      is_active: undefined,
      access_level_group: undefined
    },
    { keyword: 'client-access-levels-filters' }
  );

  const filters = useMemo(
    () => ({
      ...defaultFilters,
      access_levels_state: undefined,
      is_active: defaultFilters.access_levels_state
        ? isActive[defaultFilters.access_levels_state]
        : undefined
    }),
    [defaultFilters]
  );

  const selectedLevelsCount = selectedLevels.length;

  const deleteMutation = useMutation(
    () => users.deleteAccessLevel(deleteAccessLevelModal.data?.id!),
    {
      onSuccess: () => {
        deleteAccessLevelModal.reset();
        queryClient.invalidateQueries(usersApi.getAccessLevelsList().queryKey);

        notification.success({
          message: t('deletedAccessLevel'),
          placement: 'bottomRight'
        });
      },
      onError: (error: AxiosError) => notification.error({ message: getNotificationError(error) })
    }
  );

  const AddButton = () =>
    isAdminOrganization ? (
      <Button type="primary" onClick={accessLevelModal.open}>
        {t('add')}
        <Plus color={currentTheme['grey-white']} fontSize={20} />
      </Button>
    ) : null;

  return (
    <MainLayout
      pageHeading={
        !selectedLevelsCount ? (
          <HeadTitle Icon={Lock} label={t('accessLevels')} />
        ) : (
          <HeaderActions onClear={() => setSelectedLevels([])}>
            {selectedLevelsCount > 1
              ? t('selectedElements', {
                  count: selectedLevelsCount,
                  name: t('accessLevels')
                })
              : t('selectedElement', {
                  count: selectedLevelsCount,
                  name: t('accessLevel')
                })}
          </HeaderActions>
        )
      }
      pageActions={
        <Space>
          <Input
            prefix="search"
            style={{ width: 420 }}
            placeholder={t('search')}
            value={filters.search}
            onChange={(e) => onFiltersChange({ search: e.target.value })}
          />

          <AddButton />
        </Space>
      }
      menuSections={[
        <AccessLevelsMenuSection onChange={onFiltersChange} />,
        <AccessLevelsGroupsMenuSection onChange={onFiltersChange} />
      ]}
    >
      <AccessLevelsTable
        filters={filters}
        onFiltersChange={onFiltersChange}
        selectedLevels={selectedLevels}
        setSelectedLevels={setSelectedLevels}
        onEdit={accessLevelModal.openWith}
        onDelete={deleteAccessLevelModal.openWith}
        addButton={<AddButton />}
      />

      <AccessLevelFormModal
        open={accessLevelModal.isOpen}
        onCancel={accessLevelModal.reset}
        data={accessLevelModal.data}
      />

      <DeleteConfirmModal
        title={t('deleteAccessLevel')}
        mutation={deleteMutation}
        open={deleteAccessLevelModal.isOpen}
        onCancel={deleteAccessLevelModal.reset}
      >
        <Text
          style={{
            color: currentTheme['grey-40'],
            fontSize: '0.875rem',
            display: 'block'
          }}
        >
          {t('accessLevelDeleteQuestion')}{' '}
          <Text
            style={{
              color: currentTheme['blue-75'],
              fontSize: '0.875rem',
              display: 'block'
            }}
          >
            {deleteAccessLevelModal.data?.name}
          </Text>
        </Text>
      </DeleteConfirmModal>
    </MainLayout>
  );
};
