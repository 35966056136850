/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';

const CustomRangePicker = styled(DatePicker.RangePicker)`
  input {
    font-size: 0.875rem !important;
  }
`;

export const RangePicker = (props: RangePickerProps) => {
  return <CustomRangePicker {...props} dropdownClassName="st-range-picker" separator="-" />;
};
