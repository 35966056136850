import { createContext, useState } from 'react';
import { useIntervalEffect, useMountEffect } from '@react-hookz/web';
import { useMutation } from '@tanstack/react-query';
import { Button, notification, Progress, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { DownloadBackgroundZip } from 'api/documents.types';
import { documents } from 'api';
import { dateTimeHyphenFormat, downloadFileFromURI } from 'utils';

interface DownloadFilesContextInterface {
  zipFileIdKey: string;
  handleAddKeyToSessionStorage: (data: DownloadBackgroundZip) => void;
}

const ZIP_FILE_ID_KEY = 'zip_file_id';
const DELAY = 5000;

interface NotificationMessageProps {
  total: number;
  downloaded: number;
  link?: string;
}

const NotificationMessage = ({ total, downloaded, link }: NotificationMessageProps) => {
  const { t } = useTranslation();

  const percent = (downloaded / total) * 100;

  return (
    <Space styles={{ item: { display: 'flex' } }} align="start">
      <Progress percent={percent} type="circle" size={20} />
      <Space direction="vertical" styles={{ item: { display: 'flex' } }} size={0}>
        <span>{t('totalDownloadedDocuments', { downloaded, total })}</span>

        {link && (
          <Button type="link">
            <a href={link} target="_blank" rel="noreferrer">
              {t('download')}
            </a>
          </Button>
        )}
      </Space>
    </Space>
  );
};

export const DownloadFilesContext = createContext<DownloadFilesContextInterface>({
  zipFileIdKey: ZIP_FILE_ID_KEY,
  handleAddKeyToSessionStorage: () => null
});

export const DownloadFilesProvider = ({ children, ...props }: React.PropsWithChildren) => {
  const [runInterval, setRunInterval] = useState(!!sessionStorage.getItem(ZIP_FILE_ID_KEY)?.length);
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();

  const getIds = () => JSON.parse(sessionStorage.getItem(ZIP_FILE_ID_KEY) || '[]') as number[];

  const cancelZipTask = useMutation({ mutationFn: documents.cancelZipTask });

  const notify = (data: DownloadBackgroundZip) =>
    api.open({
      key: data.id,
      message: t('downloadProgress'),
      description: (
        <NotificationMessage
          total={data.total_documents}
          downloaded={data.downloaded_documents}
          link={data.zip_file}
        />
      ),
      duration: 0,
      placement: 'bottomRight',
      onClose: () => {
        const newIds = getIds().filter((id) => id !== data.id);
        sessionStorage.setItem(ZIP_FILE_ID_KEY, JSON.stringify(newIds));

        cancelZipTask.mutate(data.id);
      }
    });

  const mutation = useMutation({
    mutationFn: documents.checkZipDocument,
    onSuccess: (data) => {
      getIds().includes(data.id) && notify(data);

      if (data.zip_file) {
        const newIds = getIds().filter((id) => id !== data.id);
        sessionStorage.setItem(ZIP_FILE_ID_KEY, JSON.stringify(newIds));

        downloadFileFromURI(
          data.zip_file,
          `SmartPortal-export-${dayjs().format(dateTimeHyphenFormat)}.zip`,
          false
        );
      }
    },
    onError: () => {
      sessionStorage.removeItem(ZIP_FILE_ID_KEY);
      setRunInterval(false);
    }
  });

  const effect = () => {
    const ids = getIds();

    if (mutation.isLoading) return;

    if (!ids.length) {
      setRunInterval(false);
      return;
    }

    ids.forEach((id) => mutation.mutate(id));
  };

  useMountEffect(effect);

  useIntervalEffect(effect, runInterval ? DELAY : undefined);

  const handleAddKeyToSessionStorage = (data: DownloadBackgroundZip) => {
    if (!data.total_documents) return;

    const ids = getIds();
    sessionStorage.setItem(ZIP_FILE_ID_KEY, JSON.stringify([...ids, data.id]));

    notify(data);

    setRunInterval(true);
  };

  return (
    <DownloadFilesContext.Provider
      value={{ zipFileIdKey: ZIP_FILE_ID_KEY, handleAddKeyToSessionStorage }}
      {...props}
    >
      {contextHolder}

      {children}
    </DownloadFilesContext.Provider>
  );
};
