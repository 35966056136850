/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { useContext } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '../Dropdown';
import { Locale } from 'utils';
import { Text } from '../Typography';
import { ChevronDown } from 'icons';
import { ThemeContext } from 'contexts';
import { save } from 'react-cookies';
import { useUpdateLanguage } from '../../hooks';

interface LanguageSelectInterface {
  showLabel?: boolean;
}

export const LanguageSelect = ({ showLabel = false }: LanguageSelectInterface) => {
  const { t, i18n } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const { conditionalLanguageUpdate } = useUpdateLanguage();

  return (
    <Dropdown
      menu={{
        items: Object.values(Locale).map((i) => ({
          key: i,
          label: (
            <Space
              onClick={() => {
                conditionalLanguageUpdate(i);
                i18n.changeLanguage(i);
                save('locale', i, { path: '/' });
              }}
            >
              <CircleFlag countryCode={i} height={17} />
              {t(`${i18n.language}-${i}`)}
            </Space>
          )
        }))
      }}
      trigger={['click']}
      dropdownRender={(originNode) => (
        <div
          css={css`
            background-color: ${currentTheme['grey-80']};
            border-radius: 8px;
            border: 0.5px solid ${currentTheme['grey-70']};
            margin-top: 22px;
          `}
        >
          {originNode}
        </div>
      )}
    >
      <Space size={8} styles={{ item: { display: 'flex' } }} style={{ cursor: 'pointer' }}>
        {showLabel && (
          <>
            <Text size="lg" style={{ width: 'max-content' }}>
              {t(`${i18n.language}-${i18n.language}`)}
            </Text>
            <ChevronDown fontSize={20} fill={currentTheme['grey-50']} />
          </>
        )}
        <CircleFlag countryCode={i18n.language} height={17} />
      </Space>
    </Dropdown>
  );
};
