import { useContext, useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Form, Space } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Modal, ModalProps, Input, InfiniteQuerySelect } from 'components';
import { AccessLevel, AccessLevelGroup, ClientUserProfile, UserClientPost } from 'api/users.types';
import { users, usersApi } from 'api';
import { formErrorHandler, getNotificationError } from 'utils';
import { NotificationContext } from 'contexts';
import { WithPagination } from 'types';
import { InfiniteQuerySelectProps } from 'components/InfiniteQuerySelect';

interface UserClientFormModalInterface extends ModalProps {
  data?: ClientUserProfile;
  onCancel?: () => void;
}

type MixtAccessLevels = WithPagination<AccessLevel & AccessLevelGroup>;

enum AccessLevelKey {
  LEVEL = '_level',
  GROUP = '_group'
}

export const UserClientFormModal = ({ data, onCancel, ...props }: UserClientFormModalInterface) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notification } = useContext(NotificationContext);

  useEffect(() => {
    if (!data) return;

    const accessLevels = data.access_levels.map(({ id }) => id + AccessLevelKey.LEVEL);
    const accessGroups = data.access_level_groups.map(({ id }) => id + AccessLevelKey.GROUP);

    form.setFieldsValue({
      ...data,
      _access: [...accessLevels, ...accessGroups]
    });
  }, [data, form]);

  const onCancelClick = () => {
    onCancel?.();

    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(
    (body: UserClientPost) => (data ? users.updateClient(data.id, body) : users.createClient(body)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(usersApi.getClientsList().queryKey);
        queryClient.invalidateQueries(usersApi.getAccessLevelsList().queryKey);
        onCancelClick();
        notification.success({
          message: t(data ? 'editedClient' : 'addedClient'),
          placement: 'bottomRight'
        });
      },
      onError: (error: AxiosError) => {
        form.setFields(formErrorHandler(error));
        notification.error({ message: getNotificationError(error) });
      }
    }
  );

  const handleSubmit = (value: UserClientPost) => {
    const accessLevels: string[] = [];
    const accessGroups: string[] = [];

    value?._access?.forEach((idx: string) => {
      idx.includes(AccessLevelKey.LEVEL)
        ? accessLevels.push(idx.replace(/\D/g, ''))
        : accessGroups.push(idx.replace(/\D/g, ''));
    });

    const data = {
      ...value,
      access_levels: accessLevels,
      access_level_groups: accessGroups,

      _access: undefined
    };

    mutate(data);
  };

  const checkNextPage = (data?: WithPagination<AccessLevel | AccessLevelGroup>) => {
    if (!data) return true;
    return data.current_page < data.total_pages;
  };

  let accessLevelPrevFetch: WithPagination<AccessLevel | AccessLevelGroup>[] | undefined =
    undefined;

  const apiCall: InfiniteQuerySelectProps<AccessLevel & AccessLevelGroup>['apiCall'] = (
    params
  ) => ({
    queryKey: ['access-levels-and-groups', params],
    queryFn: async (ctx) => {
      if (!ctx.pageParam) {
        accessLevelPrevFetch = undefined;
      }

      const existAccessLevelNextPage = checkNextPage(accessLevelPrevFetch?.[0]);
      const existAccessLevelGroupNextPage = checkNextPage(accessLevelPrevFetch?.[1]);

      const data = await Promise.all([
        existAccessLevelNextPage
          ? usersApi.getAccessLevelsList(params).queryFn(ctx)
          : Promise.resolve({} as WithPagination<AccessLevel>),
        existAccessLevelGroupNextPage
          ? usersApi.getAccessLevelGroupList(params).queryFn(ctx)
          : Promise.resolve({} as WithPagination<AccessLevelGroup>)
      ]);

      accessLevelPrevFetch = data;

      const reducedData = data.reduce(
        (prev, next) => {
          return {
            results: [...(prev?.results || []), ...(next?.results || [])],
            count: Math.max(next?.count || 0, prev?.count || 0),
            current_page: Math.max(next?.current_page || 0, prev?.current_page || 0),
            per_page: Math.max(next?.per_page || 0, prev?.per_page || 0),
            total_pages: Math.max(next?.total_pages || 0, prev?.total_pages || 0)
          } as MixtAccessLevels;
        },
        {
          results: [],
          count: 0,
          current_page: 0,
          per_page: 0,
          total_pages: 0
        }
      );

      return reducedData as MixtAccessLevels;
    }
  });

  return (
    <Modal
      title={t(data ? 'editUser' : 'addUser')}
      onCancel={onCancelClick}
      footer={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button type="link" onClick={onCancelClick}>
            {t('cancel')}
          </Button>
          <Button type="primary" htmlType="submit" form="user-client-form" loading={isLoading}>
            {t('save')}
          </Button>
        </Space>
      }
      {...props}
    >
      <Divider />

      <Form layout="vertical" id="user-client-form" form={form} onFinish={handleSubmit}>
        <Form.Item label={t('name')} name="name" required>
          <Input />
        </Form.Item>

        <Form.Item label={t('emailAddressLabel')} name="email" required>
          <Input />
        </Form.Item>

        <Form.Item label={t('department')} name="department">
          <Input />
        </Form.Item>

        <Form.Item label={t('accessLevels')} name="_access" required>
          <InfiniteQuerySelect
            apiCall={apiCall}
            getOptions={(item) => ({
              label: item.name,
              value: item.access_levels
                ? item.id + AccessLevelKey.GROUP
                : item.id + AccessLevelKey.LEVEL,
              payload: item
            })}
            mode="multiple"
          />
        </Form.Item>
      </Form>

      <Divider />
    </Modal>
  );
};
