import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Button, RadioProps, SelectProps, Skeleton, Space, Spin } from 'antd';
import { QueryFunction, useInfiniteQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'contexts';
import { onFilterChange, useQueryParams, useQueryUpdate } from 'hooks';
import { $Object, WithPagination } from 'types';
import { comparePaths } from 'utils';
import { SelectionCollapse } from './SelectionCollapse';

interface LooseQueryOptionsStruct<T> {
  queryKey: any[] | readonly any[];
  queryFn: QueryFunction<WithPagination<T>, any>;
}

interface InfiniteQuerySelectionCollapseInterface<T>
  extends Omit<SelectProps<T>, 'onChange' | 'title'> {
  title: ReactNode;
  paramsKey: string;
  query: (params: $Object) => LooseQueryOptionsStruct<T>;
  route: string;
  onChange?: onFilterChange<$Object>;
  getItems?: (item: T) => RadioProps;
  showTotal?: boolean;
  showSearch?: boolean;
  params?: $Object;
}

const PAGE_SIZE = 20;

export function InfiniteQuerySelectionCollapse<T>({
  title,
  paramsKey,
  query,
  route,
  onChange,
  getItems,
  showTotal,
  showSearch,
  params: apiParams
}: InfiniteQuerySelectionCollapseInterface<T>) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const { currentTheme } = useContext(ThemeContext);

  const [trigger, setTrigger] = useState<HTMLElement | null>(null);

  const LoadingContainer = styled(Space)`
    padding: 0.625rem 1.25rem;
    font-size: 14px;
    color: ${currentTheme['grey-50']};
    justify-content: space-between;
    width: calc(100% - 2.5rem);
  `;

  const infiniteQuery = useInfiniteQuery({
    ...query({
      per_page: PAGE_SIZE,
      search,
      ...apiParams
    }),
    getNextPageParam: (lastPage) =>
      lastPage.current_page < lastPage.total_pages ? lastPage.current_page + 1 : undefined,
    keepPreviousData: true
  });

  const onDocumentItemClick = (state: string | number | 'total') => {
    state = String(state);
    const isTotal = state?.includes('total');

    const updateLocalValues = onChange || updateQuery;

    if (isCurrentPage) {
      if (isTotal) {
        return updateLocalValues({ ...params, [paramsKey]: null, page: 1 });
      }

      return updateLocalValues({ ...params, [paramsKey]: state, page: 1 });
    }

    if (isTotal) {
      return navigate({
        pathname: route,
        search: queryString.stringify({ ...params, page: 1 })
      });
    }

    navigate({
      pathname: route,
      search: queryString.stringify({
        ...params,
        [paramsKey]: state,
        page: 1
      })
    });
  };

  const isCurrentPage = comparePaths(route, pathname);

  const items = useMemo(() => {
    const data: RadioProps[] =
      infiniteQuery.data?.pages?.flatMap((page) =>
        page.results?.map(
          getItems ||
            ((item: any) => ({
              children: item?.name,
              value: item?.id
            }))
        )
      ) || [];

    const result: RadioProps[] = [
      {
        value: 'total',
        children: (
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            {t('total')}
            {showTotal && (infiniteQuery?.data?.pages?.[0]?.count || 0)}
          </Space>
        )
      },
      ...data
    ];

    if (infiniteQuery.hasNextPage)
      result.push({
        value: 0,
        children: infiniteQuery.isFetchingNextPage ? (
          <Space align="center">
            <Spin />
            {t('pending')}
          </Space>
        ) : (
          // In case ref somehow doesn't work
          <Button
            size="small"
            type="ghost"
            ref={(e) => setTrigger(e)}
            onClick={() => infiniteQuery.fetchNextPage()}
          >
            {t('loadMore')}
          </Button>
        ),
        disabled: true
      });

    return result;
  }, [getItems, infiniteQuery, showTotal, t]);

  useEffect(() => {
    if (trigger) infiniteQuery.fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (
    <>
      {infiniteQuery.isLoading ? (
        <LoadingContainer styles={{ item: { display: 'flex' } }} direction="vertical" size={24}>
          {Array.from({ length: 6 }).map((_, count) => (
            <Skeleton.Button active block key={count} />
          ))}
        </LoadingContainer>
      ) : (
        <SelectionCollapse
          title={title}
          items={items}
          onChange={(e) => onDocumentItemClick(e.target.value)}
          value={isCurrentPage && !params[paramsKey] ? 'total' : params[paramsKey]}
          collapsed={isCurrentPage}
          isInfiniteScroll
          {...(showSearch && { onSearch: setSearch })}
        />
      )}
    </>
  );
}
