import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Space, Typography } from 'antd';
import styled from '@emotion/styled';
import { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { users, usersApi } from 'api';
import {
  Actions,
  DeleteConfirmModal,
  Flex,
  InfiniteQuerySelectionCollapse,
  Text
} from 'components';
import { getNotificationError, getRoute } from 'utils';
import { onFilterChange, useModalState } from 'hooks';
import { Plus } from 'icons';
import { NotificationContext, ThemeContext } from 'contexts';
import { AccessLevelGroup } from 'api/users.types';

import { AccessLevelsPageFiltersValue } from '../pages/AccessLevelsPage';
import { AccessLevelGroupFormModal } from './AccessLevelGroupFormModal';

interface AccessLevelsGroupsMenuSectionInterface {
  onChange?: onFilterChange<AccessLevelsPageFiltersValue>;
}

export const AccessLevelsGroupsMenuSection = ({
  onChange
}: AccessLevelsGroupsMenuSectionInterface) => {
  const { t } = useTranslation();
  const params = useParams<{ domain: string }>();
  const queryClient = useQueryClient();

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const [hoverId, setHoverId] = useState<number | null>(null);
  const modalState = useModalState<AccessLevelGroup>();
  const deleteModalState = useModalState<AccessLevelGroup>();

  const IconWrapper = styled.div`
    color: ${currentTheme['colorPrimary']};
    display: flex;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      color: inherit;
    }
  `;

  const deleteMutation = useMutation(
    () => users.deleteAccessLevelGroup(deleteModalState.data?.id!),
    {
      onSuccess: () => {
        deleteModalState.reset();
        queryClient.invalidateQueries(usersApi.getAccessLevelGroupList().queryKey);

        notification.success({
          message: t('deletedAccessLevelGroup'),
          placement: 'bottomRight'
        });
      },
      onError: (error: AxiosError) => notification.error({ message: getNotificationError(error) })
    }
  );

  return (
    <>
      <InfiniteQuerySelectionCollapse
        showTotal
        title={
          <Flex justify="space-between">
            <Space size={4} styles={{ item: { display: 'flex' } }}>
              {t('lists').toUpperCase()}
            </Space>

            <IconWrapper
              onClick={(e) => {
                modalState.open();
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Plus />
            </IconWrapper>
          </Flex>
        }
        query={usersApi.getAccessLevelGroupList}
        paramsKey="access_level_group"
        route={getRoute('AccessLevelsPage', params)}
        onChange={onChange}
        getItems={(item) => {
          return {
            value: item.id,
            children: (
              <Flex
                id="ttt"
                justify="space-between"
                align="center"
                onPointerEnter={() => setHoverId(item.id)}
                onPointerLeave={(e) => {
                  const target = e.target as HTMLElement;
                  if (target.tagName === 'svg') return;
                  setHoverId(null);
                }}
              >
                <div>{item.name}</div>{' '}
                {hoverId === item.id ? (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Actions
                      disablePaddingIcon
                      actions={[
                        <Typography.Text onClick={() => modalState.openWith(item)}>
                          {t('edit')}
                        </Typography.Text>,
                        <Typography.Text onClick={() => deleteModalState.openWith(item)}>
                          {t('delete')}
                        </Typography.Text>
                      ]}
                    />
                  </div>
                ) : (
                  item.access_levels?.length
                )}
              </Flex>
            )
          };
        }}
      />

      <AccessLevelGroupFormModal
        open={modalState.isOpen}
        onCancel={modalState.reset}
        data={modalState.data}
      />

      <DeleteConfirmModal
        title={t('deleteAccessLevelGroup')}
        mutation={deleteMutation}
        open={deleteModalState.isOpen}
        onCancel={deleteModalState.reset}
      >
        <Text
          style={{
            color: currentTheme['grey-40'],
            fontSize: '0.875rem',
            display: 'block'
          }}
        >
          {t('accessLevelGroupDeleteQuestion')}{' '}
          <Text
            style={{
              color: currentTheme['blue-75'],
              fontSize: '0.875rem',
              display: 'block'
            }}
          >
            {deleteModalState.data?.name}
          </Text>
        </Text>
      </DeleteConfirmModal>
    </>
  );
};
