import { Global, css } from '@emotion/react';

import {
  buttonStyles,
  checkboxStyles,
  dividerStyles,
  formStyles,
  inputStyles,
  paginationStyles,
  progressStyles,
  radioStyles,
  selectStyles,
  switchStyles,
  notificationStyles,
  modalStyles,
  datePickerStyles,
  scrollBarStyles
} from 'styles';
import { darkTheme } from 'utils';

const theme = darkTheme;

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        html {
          font-family: 'Titillium Web';
          font-weight: 300;
          color: white;

          @media only screen and (min-width: 1921px) {
            font-size: 16px;
          }
        }

        body {
          background-color: ${theme['main-background-color']};
          margin: 0;
          padding: 0;
          height: 100vh;
        }

        ${buttonStyles}
        ${inputStyles}
        ${formStyles}
        ${selectStyles}
        ${checkboxStyles}
        ${dividerStyles}
        ${paginationStyles}
        ${progressStyles}
        ${radioStyles}
        ${switchStyles}
        ${notificationStyles}
        ${modalStyles}
        ${datePickerStyles}
        ${scrollBarStyles}

        .styled-dropdown {
          .ant-dropdown-menu {
            padding: 0.75rem;
            background-color: ${theme['grey-80']};

            .ant-dropdown-menu-item {
              padding: 6px;

              &:hover {
                background-color: ${theme['grey-90']};

                .ant-typography {
                  color: ${theme['grey-white']};
                }
              }

              .ant-typography {
                color: ${theme['grey-40']};
              }

              &-disabled {
                .ant-typography {
                  color: ${theme['grey-40']} !important;
                }
              }
            }
          }
        }

        .st-select-dropdown {
          background-color: ${theme['grey-80']};
          border: 0.5px solid ${theme['grey-70']};
          padding: 1rem;

          &.ant-select-dropdown {
            .ant-select-item {
              color: ${theme['grey-30']};
              font-weight: 300;
            }

            .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
              background-color: ${theme['grey-90']};
              border-radius: 8px;
              color: ${theme['grey-white']};
            }
          }

          .ant-btn-primary {
            box-shadow: none;
          }

          .ant-form-item .ant-form-item-label > label {
            color: ${theme['grey-50']};
          }

          .ant-form-item-required {
            display: flex;
            flex-direction: row-reverse;
            justify-content: left;
            gap: 3px;
          }

          .ant-divider-horizontal {
            border-color: ${theme['grey-70']};
          }
        }

        .ant-upload-list {
          display: none;
        }

        .sm-tooltip {
          --antd-arrow-background-color: ${theme['grey-70']};
          font-size: 14px;

          &.ant-tooltip .ant-tooltip-inner {
            background-color: ${theme['grey-70']};
          }
        }

        .st-range-picker .ant-picker-panel-container {
          background-color: ${theme['grey-80']};
        }
      `}
    />
  );
};
