import { css } from '@emotion/react';

export const stylesInit = () => ({
  Container: css`
    margin: 0;
    padding: 34px 92px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 100px;

    img {
      width: 100%;
    }
  `,
  IconContainer: css`
    margin: 64px auto;
    width: 112px;
  `,
  DownloadButton: css`
    width: 112px;
  `,
  ImageDrawItem: css`
    position: relative;
  `
});
