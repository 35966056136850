import { useContext } from 'react';
import { Button, Form, Space, Typography } from 'antd';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { PublicLayout, Input } from 'components';
import { ThemeContext } from 'contexts';
import { Description } from '../components';
import { users } from 'api';
import { NotificationContext } from 'contexts';
import { useQueryParams } from 'hooks';
import { AxiosError } from 'axios';
import { comparePaths, getNotificationError, getRoute } from 'utils';
import { EmailFastOutline } from 'icons';
import { $Object, UserType } from 'types';

export const SubmitEmailPage = () => {
  const params = useParams();
  const location = useLocation();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const { mutate, isLoading } = useMutation(
    (data: $Object) => users.sendResetPassword(data, getUserTypeByRoute(location), params.domain),
    {
      onSuccess: () => {
        notification.success({
          message: t('resetEmailWasSent')
        });

        navigate(getRoute(getPushLogInRouteName(), params));
      },
      onError: (error: AxiosError) => {
        notification.error({ message: getNotificationError(error) });
      }
    }
  );

  const getPushLogInRouteName = () => {
    if (comparePaths(getRoute('ClientSubmitEmailPage'), location.pathname))
      return 'ClientLogInPage';

    if (comparePaths(getRoute('PartnerSubmitEmailPage'), location.pathname))
      return 'PartnerLogInPage';

    if (!params.domain) return 'AdminLogInPage';

    return '404Page';
  };

  return (
    <PublicLayout rightSide={<Description />}>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Space direction="vertical" style={{ width: '20rem', gap: 0 }}>
          <Typography.Title style={{ margin: 0 }}>{t('resetPassword')}</Typography.Title>
          <Typography.Text style={{ color: currentTheme['grey-30'] }}>
            {t('pleaseInputEmail')}
          </Typography.Text>

          <Form
            style={{ marginTop: '2rem' }}
            onFinish={(values) => mutate({ token: queryParams.token, ...values })}
          >
            <Form.Item name="email">
              <Input prefix={<EmailFastOutline />} placeholder={t('inputEmail')} type="email" />
            </Form.Item>

            <Button type="primary" style={{ width: '100%' }} loading={isLoading} htmlType="submit">
              {t('receiveEmail')}
            </Button>
          </Form>
        </Space>
      </div>
    </PublicLayout>
  );
};

const getUserTypeByRoute = (location: Location) => {
  if (comparePaths(getRoute('ClientSubmitEmailPage'), location.pathname)) return UserType.client;

  if (comparePaths(getRoute('AdminSubmitEmailPage'), location.pathname)) return UserType.admin;

  if (comparePaths(getRoute('PartnerSubmitEmailPage'), location.pathname)) return UserType.partner;

  return UserType.client;
};
