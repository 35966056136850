/** @jsxImportSource @emotion/react */

import { useMutation } from '@tanstack/react-query';
import { useThrottledState } from '@react-hookz/web';

import { useContext, useEffect } from 'react';
import { Button, Form, Input as AntInput, Space } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PublicLayout, STInput, Text, Title } from 'components';
import { NotificationContext, ThemeContext } from 'contexts';
import { Description } from '../../components';
import { session } from 'api';
import { useQueryParams, useStateHandler } from 'hooks';
import { AxiosError } from 'axios';
import { comparePaths, getNotificationError, getRoute, removeSavedTokens } from 'utils';
import { Check, CloseSmall } from 'icons';
import { stylesInit } from './resetPassPage.styles';

export const ResetPassPage = () => {
  const params = useParams();
  const location = useLocation();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const [password, setPassword] = useThrottledState('', 500);

  const [passwordRules, setPasswordRules] = useStateHandler({
    isBigLetter: false,
    isSmallLetter: false,
    isNumber: false,
    isLength: false,
    isSymbol: false
  });

  const styles = stylesInit(currentTheme);

  useEffect(() => {
    removeSavedTokens();

    if (!queryParams.token) {
      notification.error({ message: 'We can not set up your password' });

      navigate(getRoute('404Page'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onPasswordChangeValidation = () =>
      setPasswordRules({
        isLength: password.length > 7,
        isBigLetter: /[A-Z]/.test(password),
        isSmallLetter: /[a-z]/.test(password),
        isNumber: /[0-9]/.test(password),
        // eslint-disable-next-line
        isSymbol: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|\-]/.test(password)
      });

    onPasswordChangeValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  const { mutate, isLoading } = useMutation(session.resetPassword, {
    onSuccess: () => {
      notification.success({
        message: 'Ti-ai setat parola cu succes, acum incearca sa te loghezi'
      });

      navigate(getRoute(getPushLogInRouteName(), params));
    },
    onError: (error: AxiosError) => {
      notification.error({ message: getNotificationError(error) });
    }
  });

  const getPushLogInRouteName = () => {
    if (comparePaths(getRoute('ClientSetUpPasswordPage'), location.pathname))
      return 'ClientLogInPage';

    if (comparePaths(getRoute('PartnerSetUpPasswordPage'), location.pathname))
      return 'PartnerLogInPage';

    if (!params.domain) return 'AdminLogInPage';

    return '404Page';
  };

  return (
    <PublicLayout rightSide={<Description />}>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Space direction="vertical" style={{ width: '20rem', gap: 0 }}>
          <Title style={{ margin: 0 }}>{t('welcome')}</Title>
          <Text style={{ color: currentTheme['grey-30'] }}>{t('pleaseSetYourPassword')}</Text>

          <Form
            style={{ marginTop: '2rem' }}
            onFinish={(values) => mutate({ token: queryParams.token, ...values })}
            onValuesChange={(_, { password }) => setPassword(password || '')}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t('thisFieldIsRequired')
                }
              ]}
            >
              <STInput
                prefix="lock"
                Input={AntInput.Password}
                placeholder={t('password')}
                iconRender={() => null}
              />
            </Form.Item>

            <div css={styles.passwordHelper}>
              <div>
                <PasswordCondition
                  label={t('aSmallLetter')}
                  isCheck={passwordRules.isSmallLetter}
                />
                <PasswordCondition label={t('aBigLetter')} isCheck={passwordRules.isBigLetter} />
                <PasswordCondition label={t('aNumber')} isCheck={passwordRules.isNumber} />
              </div>
              <div>
                <PasswordCondition label={t('aSymbol')} isCheck={passwordRules.isSymbol} />
                <PasswordCondition label={t('min8Characters')} isCheck={passwordRules.isLength} />
              </div>
            </div>

            <Form.Item
              name="password2"
              rules={[
                {
                  required: true,
                  message: t('thisFieldIsRequired')
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('newValueDoesNotMatch')));
                  }
                })
              ]}
            >
              <STInput
                prefix="lock"
                Input={AntInput.Password}
                placeholder={t('repeatPassword')}
                iconRender={() => null}
              />
            </Form.Item>

            <Button type="primary" style={{ width: '100%' }} loading={isLoading} htmlType="submit">
              {t('savePassword')}
            </Button>
          </Form>
        </Space>
      </div>
    </PublicLayout>
  );
};

const PasswordCondition = ({ label, isCheck = false }: { label: string; isCheck: boolean }) => {
  const { currentTheme } = useContext(ThemeContext);

  return (
    <Space
      size={0}
      styles={{
        item: {
          display: 'flex',
          color: isCheck ? '' : currentTheme['grey-60']
        }
      }}
    >
      {isCheck ? (
        <Check fontSize={16} fill={currentTheme['green-100']} />
      ) : (
        <CloseSmall fontSize={16} />
      )}

      <Text size="sm" style={{ marginLeft: 5 }}>
        {label}
      </Text>
    </Space>
  );
};
