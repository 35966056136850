import { APIDocument, DirectoryType, DocumentStatus } from 'types';

export const getVisibilityByDocumentStatus = (state: DocumentStatus) => {
  const hideDelete =
    state === DocumentStatus.needReplacement ||
    state === DocumentStatus.rejected ||
    state === DocumentStatus.done;

  const hideReplace = state === DocumentStatus.rejected || state === DocumentStatus.done;

  const hideActions = [
    DocumentStatus.pendingApproval,
    DocumentStatus.delegateApproval,
    DocumentStatus.processing,
    DocumentStatus.needInfo
  ].includes(state);

  return { hideDelete, hideReplace, hideActions };
};

export const hasReadOnlyDocuments = (documents?: APIDocument[], selectedDocuments?: number[]) => {
  if (!selectedDocuments?.length) return true;

  return documents
    ?.filter(
      ({ state }) =>
        state === DocumentStatus.pendingApproval ||
        state === DocumentStatus.delegateApproval ||
        state === DocumentStatus.processing ||
        state === DocumentStatus.needInfo
    )
    ?.some(({ id }) => selectedDocuments?.includes(id));
};

export const hasSendedDocuments = (documents?: APIDocument[], selectedDocuments?: number[]) => {
  return documents
    ?.filter(({ state }) => state === DocumentStatus.done)
    ?.some(({ id }) => selectedDocuments?.includes(id));
};

export const hasPrivateDocuments = (documents?: APIDocument[], selectedDocuments?: number[]) => {
  return !!documents?.some(
    ({ directory, id }) =>
      selectedDocuments?.includes(id) &&
      (directory?.type === DirectoryType.SIMPLE || !directory) &&
      !directory?.created_by_client
  );
};
