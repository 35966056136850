import { useTranslation } from 'react-i18next';

import { RangePicker } from 'components';
import dayjs, { Dayjs } from 'dayjs';
import { onFilterChange } from 'hooks';
import { $Object } from 'types';

interface RangeDataFilterInterface<T extends $Object> {
  filters: T;
  onFiltersChange: onFilterChange<T>;
  beforeKey: keyof T;
  afterKey: keyof T;
}

export function RangeDataFilter<T extends $Object>({
  filters,
  onFiltersChange,
  beforeKey,
  afterKey
}: RangeDataFilterInterface<T>) {
  const { t } = useTranslation();

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dateStrings) {
      onFiltersChange({
        [afterKey]: dateStrings[0],
        [beforeKey]: dateStrings[1]
      } as T);
    } else {
      onFiltersChange({
        [afterKey]: '',
        [beforeKey]: ''
      } as T);
    }
  };

  const getYear = (month: number) =>
    month > dayjs().month() + 1 ? dayjs().year() - 1 : dayjs().year();

  const getMonth = (month: number): [Dayjs, Dayjs] => [
    dayjs(`${getYear(month)}-${month}-01`),
    dayjs(`${getYear(month)}-${month}-${dayjs(`${getYear(month)}-${month}-01`).daysInMonth()}`)
  ];

  return (
    <RangePicker
      presets={[
        {
          label: t('last12Months'),
          value: [dayjs().add(-12, 'months'), dayjs()]
        },
        {
          label: t('last6Months'),
          value: [dayjs().add(-6, 'months'), dayjs()]
        },
        {
          label: t('last3Months'),
          value: [dayjs().add(-3, 'months'), dayjs()]
        },
        {
          label: t('lastMonth'),
          value: [dayjs().add(-1, 'month'), dayjs()]
        },
        {
          label: t('january'),
          value: getMonth(1)
        },
        {
          label: t('february'),
          value: getMonth(2)
        },
        {
          label: t('march'),
          value: getMonth(3)
        },
        {
          label: t('april'),
          value: getMonth(4)
        },
        {
          label: t('may'),
          value: getMonth(5)
        },
        {
          label: t('june'),
          value: getMonth(6)
        },
        {
          label: t('july'),
          value: getMonth(7)
        },
        {
          label: t('august'),
          value: getMonth(8)
        },
        {
          label: t('september'),
          value: getMonth(9)
        },
        {
          label: t('october'),
          value: getMonth(10)
        },
        {
          label: t('november'),
          value: getMonth(11)
        },
        {
          label: t('december'),
          value: getMonth(12)
        }
      ]}
      onChange={onRangeChange}
      defaultValue={[
        filters[afterKey] ? dayjs(filters[afterKey] as string) : null,
        filters[beforeKey] ? dayjs(filters[beforeKey] as string) : null
      ]}
      format="DD MMM YY"
      placeholder={[t('from'), t('to')]}
    />
  );
}
