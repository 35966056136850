import styled from '@emotion/styled';
import { palette } from 'utils';

export const DescriptionStyledComponents = () => ({
  TitleWrapper: styled.div`
    margin-bottom: 2.25rem;
  `,
  DescriptionContainer: styled.div`
    height: calc(100% - 140px);
    width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.25rem;
    background-color: ${palette['purple-125']};
    padding: 70px 80px;
    border-top-left-radius: 40px;
    position: relative;
    overflow: hidden;
  `,
  BorderedSection: styled.div`
    border-radius: 1.25rem;
    border: 0.5px solid ${palette['purple-50']};
    padding: 2rem;
  `,
  IconWrapper: styled.div`
    border-radius: 1rem;
    padding: 1.25rem;
    display: flex;
  `
});
