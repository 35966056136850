import { useContext } from 'react';

import { Text, Flex } from 'components';
import { CircleCheck, FolderFill } from 'icons';
import { ThemeContext } from 'contexts';
import { stylesInit } from './styles';

interface Props {
  name: string;
  isSelected?: boolean;
  onSelect?: (name: string) => void;
}

export const FolderLabel = ({ name, isSelected, onSelect }: Props) => {
  const { currentTheme } = useContext(ThemeContext);

  const styles = stylesInit(currentTheme, isSelected);

  return (
    <div onClick={() => onSelect?.(name)} style={styles.folderLabelContainer}>
      <Flex align="center" justify="space-between">
        <Flex align="center" gap={8} style={{ width: '90%' }}>
          <div>
            <FolderFill color={currentTheme['grey-40']} />
          </div>
          <Text
            lines="1"
            ellipsis={{
              tooltip: { title: name, overlayClassName: 'sm-tooltip' }
            }}
            color={currentTheme['grey-20']}
          >
            {name}
          </Text>
        </Flex>

        {isSelected && (
          <div style={{ lineHeight: 1 }}>
            <CircleCheck />
          </div>
        )}
      </Flex>
    </div>
  );
};
