/** @jsxImportSource @emotion/react */

import { Avatar, ColorPicker, Space, Upload } from 'antd';
import { Text, Title } from '../Typography';
import { Eyedrop, Refresh02, TrashEmpty } from '../../icons';
import { stylesInit } from './ProfliePageTemplate.styles';
import { PropsWithChildren, ReactNode, useContext, useState } from 'react';
import { ThemeContext } from '../../contexts';
import { ClientUserProfile, PartnerUserProfile, SuperUser } from '../../api/users.types';
import { UseMutationResult } from '@tanstack/react-query';

interface ProfilePageTemplateInterface {
  data?: ClientUserProfile | SuperUser | PartnerUserProfile;
  mutation?: UseMutationResult<any, unknown, FormData, unknown>;
  footer?: ReactNode;
}

export const ProfilePageTemplate = ({
  data,
  mutation,
  children,
  footer
}: PropsWithChildren<ProfilePageTemplateInterface>) => {
  const { currentTheme } = useContext(ThemeContext);

  const [color, setColor] = useState<string>();
  const [showLogoActions, setShowLogoActions] = useState(false);

  const styles = stylesInit(currentTheme);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: 'calc(100% - 74px)'
      }}
    >
      <div>
        <div css={styles.Container} style={{ backgroundColor: `${data?.color}` }}>
          <Space size={32} style={{ justifyContent: 'space-between', width: '100%' }}>
            <Space size={32}>
              <div
                css={styles.AvatarWrapper}
                onMouseEnter={() => mutation && setShowLogoActions(true)}
                onMouseLeave={() => setShowLogoActions(false)}
              >
                <Avatar src={data?.logo} size={48} shape="square" />

                <Space
                  css={styles.ActionsWrapper}
                  style={{ display: showLogoActions ? 'flex' : 'none' }}
                >
                  <div
                    css={styles.Action}
                    onClick={() => {
                      const formData = new FormData();

                      formData.append('logo', '');

                      mutation?.mutate(formData);
                    }}
                  >
                    <TrashEmpty fill={currentTheme['grey-white']} fontSize={20} />
                  </div>
                  <div css={styles.Action}>
                    <Upload
                      customRequest={({ file }) => {
                        const formData = new FormData();

                        formData.append('logo', file);

                        mutation?.mutate(formData);
                      }}
                    >
                      <Refresh02 fill={currentTheme['grey-white']} fontSize={20} />
                    </Upload>
                  </div>
                </Space>
              </div>
              <div>
                <Title level={2} style={{ margin: 0 }}>
                  {data?.name}
                </Title>
                <Text size="md">description</Text>
              </div>
            </Space>
            {mutation && (
              <div>
                <ColorPicker
                  onChange={(_, hex) => setColor(hex)}
                  onOpenChange={(state) => {
                    const formData = new FormData();

                    formData.append('color', color || '');

                    if (!state) mutation?.mutate(formData);
                  }}
                >
                  <div css={styles.ColorPikerWrapper}>
                    <Eyedrop fontSize={20} />
                  </div>
                </ColorPicker>
              </div>
            )}
          </Space>
        </div>

        <div
          style={{
            display: 'flex',
            borderTop: `.5px solid ${currentTheme['grey-70']}`,
            height: '100%'
          }}
        >
          {children}
        </div>
      </div>

      {footer}
    </div>
  );
};
