import styled from '@emotion/styled';
import { DocumentImage, DocumentPdf, DocumentText, FileBlankFill } from 'icons';
import { palette } from 'utils';

export interface DocumentTypeIconProps {
  type?: DocumentType;
  /**
   * Override icon
   */
  icon?: React.ReactNode;
  /**
   * Override color
   */
  color?: string;
  /**
   * Override Background color
   */
  bgColor?: string;
  /**
   * Override icon Size
   */
  size?: number;
}

export type DocumentType = 'pdf' | 'doc' | 'img' | 'unknown';

const defaultColor = palette['grey-80'];
export const documentColor: Record<DocumentType, string> = {
  doc: palette['blue-75'],
  pdf: palette['red-75'],
  img: palette['green-100'],
  unknown: defaultColor
};

const defaultIcon = <FileBlankFill />;
const documentIcon: Record<DocumentType, React.ReactNode> = {
  doc: <DocumentText />,
  pdf: <DocumentPdf />,
  img: <DocumentImage />,
  unknown: defaultIcon
};

const StyledDocumentTypeIcon = styled.span<DocumentTypeIconProps>`
  display: inline-flex;

  border-radius: 8px;
  padding: 4px;
  font-size: ${(props) => props.size || 1.5}rem;
  line-height: 1;

  background-color: ${(props) =>
    props.bgColor || documentColor[props.type || 'unknown'] || defaultColor};
  color: ${(props) => props.color || 'white'};
`;

export const DocumentTypeIcon = ({ type, icon, color, bgColor, size }: DocumentTypeIconProps) => {
  return (
    <StyledDocumentTypeIcon type={type} color={color} bgColor={bgColor} size={size}>
      {icon || documentIcon[type || 'unknown'] || defaultIcon}
    </StyledDocumentTypeIcon>
  );
};
