import { SetStateAction, useEffect, useRef, useState } from 'react';
import {
  getOnlyExistingKeys,
  withValues,
  getFilters,
  setFilters as setStorageFilters
} from 'utils';
import { useQueryParams } from './useQueryParams';
import { useQueryUpdate } from './useQueryUpdate';
import { $Object } from '../types';

export type onFilterChange<T> = (newValues: Partial<T>, reset?: boolean) => void;

export type FilterResult<T> = {
  filters: T;
  onFiltersChange: onFilterChange<T>;
};

type Options = {
  keyword: string;
  useQueryParams?: boolean;
  /**
   * Will get parsed from query with JSON.parse
   */
  objectKeys?: string[];
};

export const useFilters = <T extends $Object>(
  initialFilters: T,
  options: Options
): FilterResult<T> => {
  const storedFilters = getFilters();

  const localFilters = useRef<$Object>(storedFilters || {});

  const query = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const currentFilters = useRef<T>(
    getOnlyExistingKeys(
      initialFilters,
      Object.keys(query).length > 0 && !options.useQueryParams
        ? ({ ...initialFilters, ...query } as T)
        : localFilters.current[options.keyword]
          ? localFilters.current[options.keyword]
          : withValues(initialFilters)
    )
  );

  useEffect(() => {
    updateQuery(withValues(currentFilters.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const [filters, setFilters] = useState(currentFilters.current);

  const onFiltersChange = (newValues: $Object, reset = false): void => {
    const newFilters = {
      ...(!reset && query),
      ...newValues,
      ...(newValues.search && newValues.search !== filters.search && { page: 1 })
    };

    setFilters(newFilters as unknown as SetStateAction<T>);

    setStorageFilters({
      ...localFilters.current,
      [options.keyword]: newFilters
    });

    updateQuery(withValues(newFilters));
  };

  useEffect(() => {
    if (!options.useQueryParams) return;

    if (!Object.keys(query).length) {
      return onFiltersChange(withValues(filters));
    }
    
    onFiltersChange(withValues(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.useQueryParams, JSON.stringify(query)]);

  return { filters, onFiltersChange };
};
