import { css } from '@emotion/react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType) => ({
  CardTitle: css`
    justify-content: space-between;
    width: calc(100% - 64px);
    padding: 26px 32px;
    border-bottom: 0.5px solid ${theme['grey-70']};
  `,
  Divider: css`
    margin: 0 32px;
    width: calc(100% - 64px);
    min-width: inherit;
  `
});
