import { PropsWithChildren, ReactNode, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { Space } from 'antd';
import { ThemeContext } from 'contexts';
import { css } from '@emotion/react';

interface FilterContentInterface extends PropsWithChildren {
  icon: ReactNode;
  title: ReactNode;
  onClick?: () => void;
  small?: boolean;
  active?: boolean;
}

export const FilterContent = ({
  icon,
  title,
  onClick,
  small,
  active,
  children
}: FilterContentInterface) => {
  const { currentTheme } = useContext(ThemeContext);
  const ContentWrapper = useMemo(
    () => styled.div`
      padding: 1.25rem;

      .content-title {
        padding-bottom: 0.75rem;
        font-weight: 600;
        color: ${currentTheme['grey-40']};
        transition: cubic-bezier(0.39, 0.575, 0.565, 1);

        ${active &&
        css`
          color: ${currentTheme['grey-white']};
        `}

        ${small &&
        css`
          font-size: 0.75rem;
          color: ${currentTheme['grey-60']};
        `}

        ${!!onClick &&
        `cursor: pointer;
        :hover {
          color: ${currentTheme['grey-white']};
        }`}
      }
    `,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTheme, !!onClick, small, active]
  );

  return (
    <ContentWrapper>
      <Space
        className="content-title"
        size={4}
        styles={{ item: { display: 'flex' } }}
        onClick={onClick}
      >
        {icon} {title}
      </Space>

      {children}
    </ContentWrapper>
  );
};
