import { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { XAxis, YAxis, Tooltip, Line, LineChart as ExternalBarChart, Legend } from 'recharts';

import { format, keys, monthFormat, palette } from 'utils';
import { DocumentsCharts } from 'types';
import { ThemeContext } from 'contexts';

export interface LineChartProps extends React.ComponentProps<typeof ExternalBarChart> {
  data: DocumentsCharts[];
  /**
   * Make the label of the tooltip a static value instead of the one from data
   */
  staticLabel?: string;
  partners?: number[];
}

const StyledLineChart = styled(ExternalBarChart)`
  outline: none;

  .recharts-text.recharts-cartesian-axis-tick-value {
    fill: ${palette['grey-50']};
    font-size: 14px;
  }

  .recharts-layer.recharts-bar-rectangle {
    rect {
      transition: 0.2s;
    }
  }
`;

export const LineChart = ({ data, children, staticLabel, partners, ...props }: LineChartProps) => {
  const { currentTheme } = useContext(ThemeContext);

  const selectedPartners = useMemo(() => {
    const testArray: string[] = [];

    data.map((i) =>
      keys(i.stats_by_partner).map((item) => {
        if (!testArray.includes(i.stats_by_partner[item].name)) {
          testArray.push(i.stats_by_partner[item].name);
        }

        return i;
      })
    );

    return testArray;
  }, [data]);

  return (
    <StyledLineChart
      data={data.map((i) => ({
        name: format(i.month, monthFormat),
        Total: i.count,
        ...keys(i.stats_by_partner).reduce(
          (acc, item) => ({
            ...acc,
            [i.stats_by_partner[item].name]: i.stats_by_partner[item].value
          }),
          {}
        )
      }))}
      {...props}
    >
      <XAxis padding={{ left: 50, right: 50 }} dataKey="name" axisLine={false} tickLine={false} />
      <YAxis dataKey="Total" padding={{ bottom: 20 }} axisLine={false} tickLine={false} />
      <Tooltip />
      <Line
        dataKey="Total"
        strokeWidth={3}
        type="monotone"
        stroke={currentTheme['green-100']}
        key="total"
        activeDot={true}
      />

      {selectedPartners.map((i) => (
        <Line dataKey={i} strokeWidth={3} type="monotone" key={i} activeDot={true} />
      ))}

      <Legend />

      {children}
    </StyledLineChart>
  );
};
