/** @jsxImportSource @emotion/react */

import { QueryFunction, useInfiniteQuery } from '@tanstack/react-query';
import { useThrottledState } from '@react-hookz/web';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Space, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultOptionType, SelectProps } from 'antd/es/select';

import { Search } from 'icons';

import { $Object, WithPagination } from 'types';
import { Select } from './Select';
import { Input } from './Input/Input';

interface LooseQueryOptionsStruct<T> {
  queryKey: any[] | readonly any[];
  queryFn: QueryFunction<WithPagination<T>, any>;
}

export interface InfiniteQuerySelectProps<T> extends SelectProps<T> {
  apiCall: (params: $Object) => LooseQueryOptionsStruct<T>;
  getOptions: (item: T) => DefaultOptionType;
  searchPlaceholder?: string;
  setOptions?: (items: DefaultOptionType[]) => DefaultOptionType[];
}

const DROPDOWN_PAGE_SIZE = 20;
const DROPDOWN_SEARCH_THROTTLE_TIME = 300;

const StyledOptionsWrapper = styled.div`
  padding: 16px;
`;

export function InfiniteQuerySelect<T>({
  apiCall,
  getOptions,
  searchPlaceholder,
  setOptions = (value) => value,
  ...props
}: InfiniteQuerySelectProps<T>) {
  const { t } = useTranslation();

  const [search, setSearch] = useThrottledState('', DROPDOWN_SEARCH_THROTTLE_TIME);
  // Can't use ref here as it's not initially rendered
  const [trigger, setTrigger] = useState<HTMLElement | null>(null);

  const query = useInfiniteQuery({
    ...apiCall({
      search,
      per_page: DROPDOWN_PAGE_SIZE
    }),
    getNextPageParam: (lastPage) =>
      lastPage.current_page < lastPage.total_pages ? lastPage.current_page + 1 : undefined,
    keepPreviousData: true
  });

  const options = useMemo(() => {
    const result: SelectProps['options'] =
      query.data?.pages?.flatMap((page) => page.results?.map(getOptions)) || [];

    if (query.hasNextPage)
      result.push({
        value: 0,
        label: query.isFetchingNextPage ? (
          <Space align="center">
            <Spin />
            {t('pending')}
          </Space>
        ) : (
          // In case ref somehow doesn't work
          <Button
            size="small"
            type="ghost"
            ref={(e) => setTrigger(e)}
            onClick={() => query.fetchNextPage()}
          >
            {t('loadMore')}
          </Button>
        ),
        disabled: true
      });
    return result;
  }, [getOptions, query, t]);

  useEffect(() => {
    /**
     * Antd is using virtual list under the hood
     * So when the load more button is rendered, we trigger a page load immediately
     */
    if (trigger) query.fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (
    <Select
      popupMatchSelectWidth={320}
      size="large"
      dropdownRender={(menu) => (
        <StyledOptionsWrapper>
          <Input
            style={{ width: '100%' }}
            prefix={<Search fontSize={20} />}
            placeholder={searchPlaceholder || t('search')}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
          {query.isLoading ? (
            <Spin>
              <div
                css={css`
                  height: 60px;
                `}
              />
            </Spin>
          ) : (
            <div
              css={css`
                margin-top: ${options.length ? '8px' : '24px'};
              `}
            >
              {menu}
            </div>
          )}
        </StyledOptionsWrapper>
      )}
      options={setOptions(options)}
      allowClear
      style={{ minWidth: 160 }}
      {...props}
    />
  );
}
