import { css } from '@emotion/react';

import { palette } from 'utils';

export const dividerStyles = css`
  .ant-divider:not(.ant-divider-with-text) {
    background-color: ${palette['grey-70']};
    border-block-start: 1px solid ${palette['grey-70']};
  }
  .ant-divider.ant-divider-with-text {
    &::before,
    &::after {
      background-color: ${palette['grey-70']};
    }
  }
`;
