/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { Badge, Dropdown } from 'antd';
import { useContext } from 'react';
import { ThemeContext, UserContext } from 'contexts';
import { NotificationOutline } from 'icons';
import { NotificationDropdownContent } from './NotificationDropdownContent';
import { notificationBadge } from './notification-dropdown.styles';

export const NotificationDropdown = () => {
  const { currentTheme } = useContext(ThemeContext);
  const { data: userData } = useContext(UserContext);

  const StyledNotificationBadge = styled(Badge)`
    ${notificationBadge(currentTheme)}
  `;

  return (
    <StyledNotificationBadge status="warning" dot={Boolean(userData?.unread_notifications_count)}>
      <Dropdown
        trigger={['click']}
        autoAdjustOverflow={false}
        placement="bottomRight"
        dropdownRender={() => <NotificationDropdownContent />}
      >
        <NotificationOutline fontSize={20} />
      </Dropdown>
    </StyledNotificationBadge>
  );
};
