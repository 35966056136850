import { palette } from './palette';
import { ThemeTypes } from '../types';
import { AliasToken } from 'antd/es/theme/interface';

const commonVariables: Partial<AliasToken> = {
  fontFamily: 'Titillium Web',
  fontSizeHeading1: 40,
  fontSizeHeading2: 32,
  fontSizeHeading3: 24,
  fontSizeHeading4: 20,
  fontSizeHeading5: 18,
  lineHeight: 1.2,
  fontSize: 16,
  borderRadius: 8
};

export const darkTheme = {
  type: 'dark',
  colorPrimary: palette['purple-100'],
  colorTextSecondary: palette['grey-50'],
  colorTextLabel: palette['grey-50'],
  colorText: palette['grey-white'],
  'main-background-color': palette['grey-100'],
  colorTextBase: palette['grey-white'],
  colorTextPlaceholder: palette['grey-60'],
  colorBgBase: palette['grey-100'],
  colorBorder: palette['grey-70'],
  colorPrimaryBg: 'rgba(171, 112, 255, 0.20)',
  'grey-90': palette['grey-90'],
  'grey-80': palette['grey-80'],
  'grey-70': palette['grey-70'],
  'grey-60': palette['grey-60'],
  'grey-50': palette['grey-50'],
  'grey-40': palette['grey-40'],
  'grey-30': palette['grey-30'],
  'grey-20': palette['grey-20'],
  'grey-10': palette['grey-10'],
  'grey-white': palette['grey-white'],
  'purple-125': palette['purple-125'],
  'purple-100': palette['purple-100'],
  'purple-75': palette['purple-75'],
  'red-125': palette['red-125'],
  'red-100': palette['red-100'],
  'red-75': palette['red-75'],
  'green-100': palette['green-100'],
  'orange-100': palette['orange-100'],
  'yellow-100': palette['yellow-100'],
  'blue-100': palette['blue-100'],
  'blue-75': palette['blue-75'],
  ...commonVariables
};

export const whiteTheme = {
  type: 'white',
  colorPrimary: palette['purple-100'],
  colorTextSecondary: palette['grey-50'],
  colorTextLabel: palette['grey-50'],
  colorText: palette['grey-white'],
  'main-background-color': palette['grey-white'],
  colorTextBase: palette['grey-white'],
  colorTextPlaceholder: palette['grey-60'],
  colorBgBase: palette['grey-100'],
  colorBorder: palette['grey-70'],
  colorPrimaryBg: 'rgba(171, 112, 255, 0.20)',
  'grey-90': palette['grey-90'],
  'grey-80': palette['grey-80'],
  'grey-70': palette['grey-70'],
  'grey-60': palette['grey-60'],
  'grey-50': palette['grey-50'],
  'grey-40': palette['grey-40'],
  'grey-30': palette['grey-30'],
  'grey-20': palette['grey-20'],
  'grey-10': palette['grey-10'],
  'grey-white': palette['grey-white'],
  'purple-125': palette['purple-125'],
  'purple-100': palette['purple-100'],
  'purple-75': palette['purple-75'],
  'red-125': palette['red-125'],
  'red-100': palette['red-100'],
  'red-75': palette['red-75'],
  'green-100': palette['green-100'],
  'orange-100': palette['orange-100'],
  'yellow-100': palette['yellow-100'],
  'blue-100': palette['blue-100'],
  'blue-75': palette['blue-75'],
  ...commonVariables
};

export const themeColorTypeProvider = {
  [ThemeTypes.DARK]: darkTheme,
  [ThemeTypes.WHITE]: whiteTheme
};

export const generateRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;
