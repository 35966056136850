import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useContext, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  ActionHeadTitle,
  ChangePasswordModal,
  DetailsCard,
  MainLayout,
  ProfilePageTemplate,
  Put,
  Text,
  UserStatusLabel
} from 'components';
import { NotificationContext, ThemeContext, UserContext } from 'contexts';
import { Divider, Space } from 'antd';
import { BarChart, Refresh02, ShortLeft, UserCircle } from 'icons';
import { dateTimeRowFormat, format, getNotificationError, toGBDecrease } from 'utils';
import { users, usersApi } from 'api';

export const MyProfilePage = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data: loggedUser } = useContext(UserContext);
  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const { data: userData } = useQuery(usersApi.getClient(loggedUser?.id || 0));

  const [showResetPassModal, setShowResetPassModal] = useState(false);

  const updateMutate = useMutation(
    (data: FormData) =>
      loggedUser?.is_organization_administrator
        ? users.updateClient(userData?.id || 0, data)
        : users.updateProfilePartner(data),

    {
      onSuccess: () => {
        queryClient.invalidateQueries(usersApi.getLoggedUserData().queryKey);
        queryClient.invalidateQueries(usersApi.getClient(loggedUser?.id || 0).queryKey);
      },
      onError: (err: AxiosError) => {
        notification.error({ message: getNotificationError(err) });
      }
    }
  );

  return (
    <MainLayout pageHeading={<ActionHeadTitle Icon={ShortLeft} label={t('myProfile')} />}>
      <ProfilePageTemplate
        data={userData}
        mutation={updateMutate}
        footer={
          <Space
            style={{
              borderTop: `.5px solid ${currentTheme['grey-70']}`,
              width: 'calc(100% - 64px)',
              justifyContent: 'space-between',
              padding: '18px 32px'
            }}
          >
            <Space size={16} styles={{ item: { display: 'flex' } }}>
              <Text size="lg" strong>
                {t('password')}
              </Text>
            </Space>
            <Space
              size={8}
              styles={{ item: { display: 'flex' } }}
              style={{ color: currentTheme['purple-100'], cursor: 'pointer' }}
              onClick={() => setShowResetPassModal(true)}
            >
              <Text size="lg">{t('requestChangePassword')}</Text>
              <Refresh02 />
            </Space>
          </Space>
        }
      >
        <DetailsCard
          title={t('clientDetails')}
          Icon={UserCircle}
          items={[
            { label: t('companyCif'), value: <Put>{userData?.cif}</Put> },
            {
              label: t('department'),
              value: <Put>{userData?.department}</Put>
            },
            { label: t('emailAddress'), value: <Put>{userData?.email}</Put> },
            {
              label: t('domainPrefix'),
              value: (
                <Put condition={!!userData?.domain_prefix}>
                  <Text code>{userData?.domain_prefix}</Text>
                </Put>
              )
            }
          ]}
        />

        <Divider type="vertical" style={{ height: 'auto', margin: 0 }} />

        <DetailsCard
          title={t('activity')}
          Icon={BarChart}
          items={[
            {
              label: t('profileStatus'),
              value: <UserStatusLabel unfilled status={userData?.state} />
            },
            {
              label: t('profileCreatedAt'),
              value: userData?.created_at && format(userData.created_at, dateTimeRowFormat)
            },
            {
              label: t('lastActivity'),
              value: userData?.last_login && format(userData.last_login, dateTimeRowFormat)
            },
            {
              label: t('storageSpace'),
              value: toGBDecrease(userData?.storage_limit)
            }
          ]}
        />
      </ProfilePageTemplate>

      <ChangePasswordModal
        open={showResetPassModal}
        setOpen={setShowResetPassModal}
        call={users.requestClientPassword}
      />
    </MainLayout>
  );
};
