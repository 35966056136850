import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayOfYear from 'dayjs/plugin/dayOfYear';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(dayOfYear);

export const today = dayjs();
export const dateFormat = 'DD MMM YYYY';
export const dateFormatInput = 'dd.MM.yyyy';
export const dateTimeFormat = 'DD.MM.YYYY HH:mm';
export const dateTimeFormatInput = 'dd.MM.yyyy HH:mm';
export const dateFormatDMCMY = 'D MMM, YYYY';
export const dateTimeRowFormat = 'DD MMM YYYY, HH:mm A';
export const dateSpecialFormat = 'D MMMM YYYY';
export const timeSpecialFormat = 'HH:mm A';
export const monthFormat = 'MMM';
export const dashDateFormat = 'YYYY-MM-DD';
export const dateTimeHyphenFormat = 'YYYYMMDD-HHmm';

export const format = (date?: string, format?: string): string =>
  dayjs(date).format(format || dateFormat);

export const toISO = (date: string, withoutTime?: boolean): string => {
  const format = withoutTime ? 'DD.MM.YYYY' : dateTimeFormat;
  try {
    return dayjs(date, format).toISOString();
  } catch {
    return date;
  }
};
export const toISODate = (date: string, withoutTime?: boolean): string => {
  const format = withoutTime ? 'DD.MM.YYYY' : dateTimeFormat;
  try {
    return dayjs(date, format).format('YYYY-MM-DD');
  } catch {
    return date;
  }
};
export const getYear = (date: string): string => dayjs(date).format('YYYY');
export const getMonth = (date: string): string => dayjs(date).format('MMM');
export const getFullMonth = (date: string): string => dayjs(date).format('MMMM');
export const getDay = (date: string): string => dayjs(date).format('D');
export const getTime = (date: string): string => dayjs(date).format('HH:mm');

export const isAfter = (date: string): boolean => dayjs().isAfter(date);
export const isBefore = (date: string): boolean => dayjs().isBefore(date);
export const add = (count: number): dayjs.Dayjs => dayjs().add(count, 'day');

export const tomorrow = dayjs().add(1, 'day');
export const yesterday = dayjs().subtract(1, 'day');
export const theOtherYesterday = dayjs().subtract(2, 'day');
export const thisWeek = dayjs().startOf('week');
export const thisMonth = dayjs().startOf('month');
