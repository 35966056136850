export const firstLetters = (text?: string): string => {
  if (!text) return '?';
  const firstLettersText = text
    .split(' ')
    .map((item) => item[0]?.toUpperCase())
    .filter((item) => /^[A-Za-z\s]*$/.test(item))
    .join('');

  return firstLettersText;
};
