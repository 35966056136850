import { $Object } from 'types';
import { defaultPaginationProp } from './pagination';

export const hasActiveFilters = (filtersValue: $Object) => {
  const paginationKeys = Object.keys(defaultPaginationProp);

  return Object.entries(filtersValue)
    .filter(
      (entry) =>
        !paginationKeys.includes(entry[0]) &&
        !entry.includes('documents_state') &&
        !entry.includes('clients_state') &&
        !entry.includes('state') &&
        entry[1]
    )
    .some(Boolean);
};
