/** @jsxImportSource @emotion/react */

import { MenuLoading } from './MenuLoading';
import { comparePaths } from '../../utils';
import { Space } from 'antd';
import { default as queryString } from 'query-string';
import { useQuery } from '@tanstack/react-query';
import { TypedUseQueryOptions } from '@lukemorales/query-key-factory';
import { useContext } from 'react';
import { ThemeContext } from '../../contexts';
import { useLocation, useNavigate } from 'react-router-dom';
import { stylesInit } from './menuSection.styles';
import { useQueryParams, useQueryUpdate } from 'hooks';
import { $Object } from 'types';
import { onFilterChange } from 'hooks/useFilter';
import { useTranslation } from 'react-i18next';

interface MenuSectionInterface {
  title: string;
  paramsKey: string;
  query: (params: $Object) => TypedUseQueryOptions<any>;
  route: string;
  translates: $Object;
  onChange?: onFilterChange<$Object>;
  select?: (data: any) => any;
}

export const MenuSection = ({
  title,
  paramsKey,
  query,
  route,
  translates,
  onChange,
  select
}: MenuSectionInterface) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const { currentTheme } = useContext(ThemeContext);

  const styles = stylesInit(currentTheme);

  const { data: stats, isLoading } = useQuery({
    ...query({}),
    select
  });

  const onDocumentItemClick = (state: string | 'total') => {
    const isTotal = state?.includes('total');

    const updateLocalValues = onChange || updateQuery;

    if (isCurrentPage) {
      if (isTotal) {
        return updateLocalValues({ ...params, [paramsKey]: null, page: 1 });
      }

      return updateLocalValues({ ...params, [paramsKey]: state, page: 1 });
    }

    if (isTotal) {
      return navigate({
        pathname: route,
        search: queryString.stringify({ ...params, page: 1 })
      });
    }

    navigate({
      pathname: route,
      search: queryString.stringify({ ...params, [paramsKey]: state, page: 1 })
    });
  };

  const isCurrentPage = comparePaths(route, pathname);

  return (
    <>
      <div css={styles.sectionTitle}>{title}</div>

      <MenuLoading loading={isLoading} lines={6}>
        {stats && (
          <>
            {Object.keys(stats).map((i) => {
              const { label, icon: Icon } = translates[i] || {
                icon: null,
                label: i
              };

              return (
                <Space
                  key={i}
                  css={styles.sideMenuItem}
                  styles={{ item: { display: 'flex' } }}
                  className={
                    isCurrentPage &&
                    (i === params[paramsKey] || (i === 'total' && !params[paramsKey]))
                      ? 'selected'
                      : ''
                  }
                  onClick={() => onDocumentItemClick(i)}
                >
                  <Space size={12} styles={{ item: { display: 'flex' } }}>
                    {Icon && <Icon fontSize={16} />}
                    {t(label)}
                  </Space>
                  <span
                    style={{
                      color: stats[i] > 0 ? currentTheme['grey-white'] : 'inherit'
                    }}
                  >
                    {stats[i]}
                  </span>
                </Space>
              );
            })}
          </>
        )}
      </MenuLoading>
    </>
  );
};
