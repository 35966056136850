import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useMutation, useQuery } from '@tanstack/react-query';

import { NotificationContext } from 'contexts';
import { users, usersApi } from 'api';
import { getNotificationError, getRoute } from 'utils';
import { UserType } from 'types';
import { FullpageSpinner } from 'components';
import { AxiosError } from 'axios';

export const AnafCallbackPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { notification } = useContext(NotificationContext);

  const onNotLoggedIn = () => {
    navigate('/');
    notification.error({ message: t('eFacturaFailedEnrollmentNotLoggedIn') });
  };

  const profileQuery = useQuery({
    ...usersApi.getLoggedUserData(),
    onSuccess: (data) => {
      if (data.role === UserType.partner) {
        if (new URLSearchParams(window.location.search).get('error') === 'access_denied') {
          notification.error({ message: t('reloadBrowser') });

          navigate(
            getRoute('PartnerProfilePage', {
              domain: profileQuery.data?.domain_prefix
            }),
            {}
          );
        } else {
          anafCallbackMutation.mutate();
        }
      
        
      } else {
        onNotLoggedIn();
      }
    },
    onError: () => {
      onNotLoggedIn();
    }
  });

  const anafCallbackMutation = useMutation(() => users.sendAnafCallback(document.location.search), {
    onSettled: () => {
      navigate(
        getRoute('PartnerProfilePage', {
          domain: profileQuery.data?.domain_prefix
        }),
        {}
      );
    },
    onError: (error: AxiosError) => {
      if (error.status === 400) {
        notification.error({ message: getNotificationError(error) });
      } else {
        notification.error({ message: t('eFacturaFailedEnrollment') });
      }
    },
    onSuccess: () => {
      notification.success({ message: t('eFacturaSuccessfulEnrollment') });
    }
  });

  return <FullpageSpinner />;
};
