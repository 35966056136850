import { css } from '@emotion/react';

import { palette } from 'utils';

export const datePickerStyles = css`
  .ant-picker {
    padding: 9px 11px 9px;

    &:hover {
      border-color: ${palette['grey-50']};
    }

    &:focus {
      border-color: ${palette['grey-50']};
    }

    .ant-picker-focused {
      border-color: ${palette['grey-50']};
      box-shadow: 0 0 0 4px rgba(171, 112, 255, 0.2);
    }
  }
`;
