import styled from '@emotion/styled';

import { Typography } from 'antd';

const { Title } = Typography;

export const titleSize = [40, 32, 24, 20, 18];
export const titleLineHeight = [48, 40, 32, 24, 20];

const StyledTitle = styled(Title)`
  font-size: ${({ level }) => titleSize[(level || 1) - 1]}px;
  line-height: ${({ level }) => titleLineHeight[(level || 1) - 1]}px;
  font-weight: 600;
`;

export { StyledTitle as Title };
