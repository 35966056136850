import styled from '@emotion/styled';

import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Space, Typography } from 'antd';
import { AddFolder, Dropdown, SignedUpload } from 'components';
import { ThemeContext, UserContext } from 'contexts';
import { ChevronDown } from 'icons';
import { $Object } from 'types';
import { documentsQueries, users } from '../api';

interface AddDocumentsBtnInterface {
  filters: $Object;
  folderName?: string;
  setProgress: Dispatch<SetStateAction<{ [x: string]: number }>>;
  folderActionDisabled?: boolean;
  partnerId?: number;
}

export const AddDocumentsBtn = ({
  folderName,
  setProgress,
  folderActionDisabled,
  partnerId
}: AddDocumentsBtnInterface) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const {
    role: { isClient, isPartner }
  } = useContext(UserContext);

  const StyledArrowDown = styled(ChevronDown)`
    path {
      fill: ${currentTheme['grey-white']};
    }
  `;

  const beforeUploadCall = async () =>
    await queryClient.invalidateQueries(documentsQueries.getList().queryKey);

  const afterUploadCall = () => {
    queryClient.invalidateQueries(documentsQueries.getList().queryKey);

    queryClient.invalidateQueries(documentsQueries.getPartnerStats().queryKey);
  };

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 1,
            label: (
              <SignedUpload
                folderName={folderName}
                setProgress={setProgress}
                beforeUploadCall={beforeUploadCall}
                afterUploadCall={afterUploadCall}
                {...(isClient && {
                  apiCall: partnerId
                    ? (data) => users.getPartnerSignedUrls(partnerId, data)
                    : users.getSignedUrlsForClients
                })}
              >
                <Typography.Text>{t('document')}</Typography.Text>
              </SignedUpload>
            )
          },
          {
            key: 2,
            disabled: folderActionDisabled,
            label: !(isClient || isPartner) ? (
              <SignedUpload
                folderName={folderName}
                setProgress={setProgress}
                beforeUploadCall={beforeUploadCall}
                afterUploadCall={afterUploadCall}
                directory
              >
                <Typography.Text>{t('folder')}</Typography.Text>
              </SignedUpload>
            ) : (
              <AddFolder disabled={folderActionDisabled}>
                <Typography.Text>{t('folder')}</Typography.Text>
              </AddFolder>
            )
          }
        ]
      }}
    >
      <Button type="primary">
        <Space styles={{ item: { display: 'flex' } }}>
          {t('add')}
          <StyledArrowDown />
        </Space>
      </Button>
    </Dropdown>
  );
};
