/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import { SideBar, SideBarTitle } from 'components';
import { ThemeContext } from 'contexts';
import { BarChart, UserCircle } from 'icons';
import { dateTimeRowFormat, format, toGBDecrease } from 'utils';
import { PartnerUserProfile } from 'api/users.types';
import { UserStatuses } from 'types';
import { stylesInit } from './PartnerDetails.styles';

interface PartnerDetailsProps {
  partner: PartnerUserProfile;
}

const stateColors = {
  [UserStatuses.active]: 'green-100',
  [UserStatuses.pending]: 'orange-100',
  [UserStatuses.inactive]: 'yellow-100',
  [UserStatuses.deleted]: 'red-100'
};

export const PartnerDetails = ({ partner }: PartnerDetailsProps) => {
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);

  const styles = stylesInit(currentTheme);

  const getStateColor = (state: UserStatuses): string => {
    // @ts-ignore
    return currentTheme[stateColors[state]];
  };

  return (
    <SideBar>
      <SideBarTitle
        title={t('partnerDetails')}
        icon={<UserCircle style={{ color: currentTheme['purple-100'] }} />}
      />
      <div css={styles.DetailsBody}>
        <div css={styles.DetailsItem}>
          <span css={styles.DetailsItemLabel}>{t('companyCif')}</span>
          <span css={styles.DetailsItemValue}>{partner.cif}</span>
        </div>
        <div css={styles.DetailsItem}>
          <span css={styles.DetailsItemLabel}>{t('department')}</span>
          <span css={styles.DetailsItemValue}>{partner.department}</span>
        </div>
        <div css={styles.DetailsItem}>
          <span css={styles.DetailsItemLabel}>{t('emailAddress')}</span>
          <span css={styles.DetailsItemValue}>{partner.email}</span>
        </div>
        <div css={styles.DetailsItem}>
          <span css={styles.DetailsItemLabel}>{t('workspace')}</span>
          <span css={styles.DetailsItemValue}>{partner.workspace}</span>
        </div>
        <div css={styles.DetailsItem}>
          <span css={styles.DetailsItemLabel}>{t('domainPrefix')}</span>
          <span css={styles.DetailsItemValue}>{partner.domain_prefix}</span>
        </div>
      </div>

      <Divider style={{ margin: '0' }} />

      <SideBarTitle
        title={t('activity')}
        icon={<BarChart style={{ color: currentTheme['purple-100'] }} />}
      />
      <div css={styles.DetailsBody}>
        <div css={styles.DetailsItem}>
          <span css={styles.DetailsItemLabel}>{t('profileStatus')}</span>
          <span style={{ color: getStateColor(partner.state) }} css={styles.DetailsItemValue}>
            {partner.state}
          </span>
        </div>
        <div css={styles.DetailsItem}>
          <span css={styles.DetailsItemLabel}>{t('profileCreatedAt')}</span>
          <span css={styles.DetailsItemValue}>{format(partner.created_at, dateTimeRowFormat)}</span>
        </div>
        {partner.last_login && <div css={styles.DetailsItem}>
          <span css={styles.DetailsItemLabel}>{t('lastActivity')}</span>
          <span css={styles.DetailsItemValue}>{format(partner.last_login, dateTimeRowFormat)}</span>
        </div>}
        <div css={styles.DetailsItem}>
          <span css={styles.DetailsItemLabel}>{t('storageSpace')}</span>
          <span css={styles.DetailsItemValue}>
            {toGBDecrease(partner.storage_sum)}/{toGBDecrease(partner.storage_limit)} GB
          </span>
        </div>
      </div>
    </SideBar>
  );
};
