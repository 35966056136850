import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useContext } from 'react';
import { Button, Divider, Form, Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { NotificationContext, ThemeContext } from 'contexts';
import { users, usersApi } from 'api';
import { useForm } from 'antd/lib/form/Form';
import { InfiniteQuerySelect, Input, Modal } from 'components';
import { formErrorHandler, getNotificationError, toGBDecrease, toGBIncrease } from 'utils';
import { AxiosError } from 'axios';

interface PartnerFormModalInterface {
  id?: number;
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

export const PartnerFormModal = ({ id, open, onSuccess, onCancel }: PartnerFormModalInterface) => {
  const queryClient = useQueryClient();
  const [form] = useForm();
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const { data, isLoading: isPartnerLoading } = useQuery({
    ...(id ? usersApi.getPartnerProfile(id) : {}),
    onSuccess: (data) =>
      form.setFieldsValue({
        ...data,
        access_level: data.access_level?.id,
        storage_limit: toGBDecrease(data.storage_limit)
      }),
    enabled: !!id
  });

  const { mutate: createPartnerMutate, isLoading: isCreatePartnerLoading } = useMutation(
    (data: FormData) => (id ? users.updatePartner(id, data) : users.createPartner(data)),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        queryClient.invalidateQueries(usersApi.getPartnersList().queryKey);
        queryClient.invalidateQueries(usersApi.getPartnersStats().queryKey);
        queryClient.invalidateQueries(usersApi.getAccessLevelsList().queryKey);
        onCancel && onCancel();
      },
      onError: (error: AxiosError) => {
        form.setFields(formErrorHandler(error));
        notification.error({ message: getNotificationError(error) });
      }
    }
  );

  const { mutate: cifMutation } = useMutation(users.getCifData, {
    onSuccess: ({ found }) => {
      const name = found[0]?.date_generale?.denumire.trim();

      name && form.setFieldValue('name', name);
    }
  });

  const onCancelClick = () => {
    onCancel && onCancel();

    form.resetFields();
  };

  const onValuesChange = ({ cif }: any) => {
    if (cif) {
      cifMutation(cif);
    }
  };

  const onFormFinish = (values: any) =>
    createPartnerMutate({
      ...values,
      storage_limit: toGBIncrease(values.storage_limit)
    });

  return (
    <Modal
      title={t(id ? 'editPartner' : 'addPartner')}
      open={open}
      onCancel={onCancelClick}
      footer={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button type="link" onClick={onCancelClick}>
            {t('cancel')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            form="partner-form"
            loading={isCreatePartnerLoading}
          >
            {t('save')}
          </Button>
        </Space>
      }
    >
      <Spin spinning={!!id && isPartnerLoading}>
        <Typography.Text style={{ color: currentTheme['grey-40'], fontSize: '0.875rem' }}>
          {t(id ? 'partnerEditDescription' : 'partnerAddDescription')}
        </Typography.Text>

        <Divider />

        <Form
          layout="vertical"
          id="partner-form"
          form={form}
          onFinish={onFormFinish}
          onValuesChange={onValuesChange}
        >
          <Form.Item label={t('partnerEmailAddress')} name="email" required>
            <Input />
          </Form.Item>
          <Form.Item label={t('partnerName')} name="name" required>
            <Input />
          </Form.Item>
          <Form.Item label={t('cif')} name="cif">
            <Input />
          </Form.Item>
          <Form.Item label={t('department')} name="department">
            <Input />
          </Form.Item>
          <Form.Item label={t('accessLevel')} name="access_level">
            <InfiniteQuerySelect
              apiCall={usersApi.getAccessLevelsList}
              getOptions={(item) => ({
                label: item.name,
                value: item.id,
                payload: item
              })}
              setOptions={(options) =>
                options.find(({ value }) => value === data?.access_level?.id)
                  ? options
                  : [...options, { label: data?.access_level?.name, value: data?.access_level?.id }]
              }
            />
          </Form.Item>
          <Form.Item label={t('storageSpace')} name="storage_limit">
            <Input type="number" />
          </Form.Item>
        </Form>

        <Divider />
      </Spin>
    </Modal>
  );
};
