import { useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { load, save, remove } from 'react-cookies';
import { useTranslation } from 'react-i18next';

import { NotificationContext, UserContext } from 'contexts';
import { usersApi } from 'api';
import { ClientUserProfile, PartnerUserProfile } from 'api/users.types';

const STORAGE_KEY = '__storage_limit__';

export const StorageNotification = () => {
  const { t } = useTranslation();
  const { notification } = useContext(NotificationContext);
  const {
    role: { isClient, isPartner }
  } = useContext(UserContext);

  const { data: clientDetails } = useQuery({
    ...usersApi.getClientDetails(),
    enabled: isClient
  });

  const { data: partnerDetails } = useQuery({
    ...usersApi.getPartnerDetails(),
    enabled: isPartner
  });

  useEffect(() => {
    const handleSuccess = (data: ClientUserProfile | PartnerUserProfile) => {
      const percent = 100 * (data.storage_sum / data.storage_limit);

      if (percent < 80) {
        remove(STORAGE_KEY, { path: '/' });
        return;
      }

      !load(STORAGE_KEY) &&
        notification.info({
          message: t('storageLimitAlert'),
          placement: 'bottom',
          duration: null,
          onClose: () => save(STORAGE_KEY, 'true', { path: '/' })
        });
    };

    if (clientDetails) {
      handleSuccess(clientDetails);
    } else if (partnerDetails) {
      handleSuccess(partnerDetails);
    }

    return () => notification.destroy();
  }, [clientDetails, partnerDetails, notification, t]);

  return null;
};
