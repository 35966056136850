import { default as i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { load } from 'react-cookies';
import { Locale } from './utils';
import { localesResources } from './utils/locales';

export const i18next = i18n.createInstance();

export const i18Next = (): any =>
  i18next.use(initReactI18next).init({
    fallbackLng: [Locale.US, Locale.RO],
    lng: load('locale') || Locale.RO,
    resources: localesResources
  });
