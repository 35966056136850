import { useMutation, useQueryClient } from '@tanstack/react-query';
import { documentsQueries, users } from 'api';
import { usersQueries } from 'api/users';
import { useContext } from 'react';
import { UserContext } from 'contexts';
import { Locale } from 'utils';

export const useUpdateLanguage = () => {
  const queryClient = useQueryClient();

  const { data, role } = useContext(UserContext);

  const { mutate } = useMutation(users.updateLanguage, {
    onSuccess: () => {
      queryClient.invalidateQueries(usersQueries.getLoggedUserData().queryKey);
      queryClient.invalidateQueries(documentsQueries.getDirectoriesList().queryKey);
      queryClient.invalidateQueries(documentsQueries.getDirectoriesSearchList().queryKey);
    }
  });

  const conditionalLanguageUpdate = (language: Locale) => {
    if (data?.language !== language && role.role) {
      languageUpdate(language);
    }
  };

  const languageUpdate = (language: Locale) => mutate({ language: language });

  return { languageUpdate, conditionalLanguageUpdate };
};
