import { axios } from './axios';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { LoginInterface, ResetPasswordDataInterface, TokenRefresh } from './session.types';
import { UserType } from '../types';

export const session = {
  login: async (data: LoginInterface, userType: UserType, domain?: string) => {
    const response = await axios.post<{
      access: string;
      refresh: string;
      role: UserType;
    }>('/login', data, {
      headers: { 'X-USER-TYPE': userType, 'X-DOMAIN': domain }
    });

    return response.data;
  },
  tokenRefresh: async (data?: TokenRefresh) =>
    await axios
      .post<{ access: string; refresh: string }>('/token/refresh', data, {
        headers: { Authorization: undefined }
      })
      .then(({ data }) => data),
  resetPassword: async (data?: ResetPasswordDataInterface) => {
    const response = await axios.post('/users/reset-password', data, {
      headers: { Authorization: undefined }
    });

    return response.data;
  }
};

export const sessionQueries = createQueryKeys('session', {
  login: (params = {}, userType: UserType, domain) => ({
    queryKey: [params, userType, domain],
    queryFn: () => session.login(params, userType, domain)
  })
});
