import { RouteProps } from 'react-router-dom';

import { CleanedPathNavigate } from 'components';
import { UserType } from 'types';

import { ResetPassPage } from 'features/common/pages/ResetPassPage/ResetPassPage';
import { LogInPage } from 'features/common/pages/LogInPage';
import { PartnersPage } from 'features/client/pages/PartnersPage';
import { DocumentsPage } from 'features/client/pages/DocumentsPage';
import { ClientsPage } from 'features/superAdmin/ClientsPage';
import { DocumentsPage as PartnerDocumentsPage } from 'features/partener/pages/DocumentsPage';
import { DocumentItemPage as PartnerDocumentItemPage } from 'features/partener/pages/DocumentItemPage';
import { MyProfilePage as AdminProfilePage } from 'features/superAdmin/MyProfilePage';
import { ClientProfilePage } from 'features/superAdmin/ClientProfilePage';
import { PartnerDocumentsFolderPage } from 'features/partener/pages/PartnerDocumentsFolderPage';
import { MyProfilePage as ClientMyProfilePage } from 'features/client/pages/MyProfilePage';
import { MyProfilePage as PartnerMyProfilePage } from 'features/partener/pages/MyProfilePage';
import { PartnerPage } from 'features/partener/pages/PartnerPage';
import { NotFoundPage } from 'features/common/pages/NotFoundPage';
import { IndexRedirect } from 'components/IndexRedirect';
import { SubmitEmailPage } from 'features/common/pages/SubmitEmailPage';
import { DocumentsFolderPage } from 'features/client/pages/DocumentsFolderPage';
import { GroupPage } from './features/client/pages/GroupPage';
import { AnafCallbackPage } from 'features/partener/pages/AnafCallbackPage';
import { AccessLevelsPage } from 'features/client/pages/AccessLevelsPage';
import { UsersPage } from 'features/client/pages/UsersPage';
import { DelegatedDocumentPage } from 'features/client/pages/DelegatedDocumentPage';

type ExtendedRouteProps = RouteProps & {
  name: string;
  path: NonNullable<RouteProps['path']>;
  menuComponent?: {
    title: string;
    userType: UserType;
  };
  isAdminOrganization?: boolean;
};

export const routes: ExtendedRouteProps[] = [
  { name: 'BasePage', path: '/', element: <IndexRedirect /> },

  // client routes
  {
    name: 'ClientSetUpPasswordPage',
    path: '/client/reset-password',
    element: <ResetPassPage />
  },
  {
    name: 'ClientLogInPage',
    path: '/login',
    element: <LogInPage />
  },
  {
    name: 'ClientSubmitEmailPage',
    path: '/submit-email',
    element: <SubmitEmailPage />
  },
  {
    name: 'DocumentsPage',
    path: '/documents',
    element: <DocumentsPage />,
    menuComponent: {
      title: 'documents',
      userType: UserType.client
    }
  },
  {
    name: 'ClientDocumentsFolderPage',
    // NOTE: Should be a catch-all in case of nested path ("Parent_folder/Child_folder")
    path: '/documents/*',
    element: <DocumentsFolderPage />
  },
  {
    name: 'AccessLevelsPage',
    path: '/access-levels',
    element: <AccessLevelsPage />,
    menuComponent: {
      title: 'accessLevels',
      userType: UserType.client
    },
    isAdminOrganization: true
  },
  {
    name: 'UsersPage',
    path: '/users',
    element: <UsersPage />,
    menuComponent: {
      title: 'users',
      userType: UserType.client
    },
    isAdminOrganization: true
  },
  {
    name: 'PartnersPage',
    path: '/partners',
    element: <PartnersPage />,
    menuComponent: { title: 'partners', userType: UserType.client }
  },
  {
    name: 'PartnerPage',
    path: '/partner/:id',
    element: <PartnerPage />
  },
  {
    name: 'GroupPage',
    path: '/group/:group',
    element: <GroupPage />
  },
  {
    name: 'ClientProfilePage',
    path: '/clients/my-profile',
    element: <ClientMyProfilePage />
  },

  // admin routes
  {
    name: 'AdminSetUpPasswordPage',
    path: '/reset-password',
    element: <ResetPassPage />
  },
  { name: 'AdminLogInPage', path: '/admin/login', element: <LogInPage /> },
  {
    name: 'AdminSubmitEmailPage',
    path: '/admin/submit-email',
    element: <SubmitEmailPage />
  },
  { name: 'ClientsPage', path: '/clients', element: <ClientsPage /> },
  { name: 'ClientPage', path: '/clients/:id', element: <ClientProfilePage /> },
  {
    name: 'AdminProfilePage',
    path: '/admins/profile',
    element: <AdminProfilePage />
  },

  // partner routes

  {
    // Catch the anaf callback before checking dynamic routes
    name: 'AnafCallbackPage',
    path: '/anaf/callback',
    element: <AnafCallbackPage />
  },

  // Partner public routes <--
  {
    name: 'PartnerSetUpPasswordPage',
    path: '/:domain/partner/reset-password',
    element: <ResetPassPage />
  },
  {
    name: 'PartnerLogInPage',
    path: '/:domain',
    element: <LogInPage />
  },
  {
    name: 'PartnerSubmitEmailPage',
    path: '/:domain/submit-email',
    element: <SubmitEmailPage />
  },
  {
    name: 'PartnerSetUpPasswordPage',
    path: '/:domain/reset-password',
    element: <ResetPassPage />
  },
  // -->

  {
    name: 'PartnerDocumentsPage',
    path: '/partner/documents',
    element: <PartnerDocumentsPage />,
    menuComponent: {
      title: 'documents',
      userType: UserType.partner
    }
  },
  {
    name: 'PartnerDocumentItemPage',
    path: '/partner/document/:id',
    element: <PartnerDocumentItemPage />
  },
  {
    name: 'PartnerDocumentsFolderPage',
    // NOTE: Should be a catch-all in case of nested path ("Parent_folder/Child_folder")
    path: '/partner/documents/*',
    element: <PartnerDocumentsFolderPage />
  },
  {
    name: 'PartnerProfilePage',
    path: '/partner/my-profile',
    element: <PartnerMyProfilePage />
  },

  // Deprecated paths redirecting to the new ones
  {
    name: 'DeprecatedPartnerDocumentsPage',
    path: '/:domain/partner/documents',
    element: <CleanedPathNavigate />
  },
  {
    name: 'DeprecatedPartnerDocumentItemPage',
    path: '/:domain/partner/document/:id',
    element: <CleanedPathNavigate />
  },
  {
    name: 'DeprecatedPartnerDocumentsFolderPage',
    path: '/:domain/partner/documents/*',
    element: <CleanedPathNavigate />
  },
  {
    name: 'DeprecatedPartnerProfilePage',
    path: '/:domain/partner/my-profile',
    element: <CleanedPathNavigate />
  },

  { name: '404Page', path: '/not-found', element: <NotFoundPage /> },
  { name: '404Page', path: '*', element: <NotFoundPage /> }
];

export const publicRoutes: ExtendedRouteProps[] = [
  {
    name: 'DelegatedDocumentPage',
    path: '/partner/delegations/:id',
    element: <DelegatedDocumentPage />
  },

  { name: '404Page', path: '/not-found', element: <NotFoundPage /> },
  { name: '404Page', path: '*', element: <NotFoundPage /> }
];