import { Dropdown as AntdDropdown, DropdownProps } from 'antd';
import { css } from '@emotion/react';

export const Dropdown = (props: DropdownProps) => (
  <AntdDropdown
    {...props}
    overlayClassName="styled-dropdown"
    css={css`
      .ant-dropdown-menu {
        padding: 12px;
      }
      .ant-dropdown-menu-item {
        padding: 6px;
      }
    `}
  />
);
