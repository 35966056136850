import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Space } from 'antd';

import { Modal } from './Modal';
import { Input } from './Input/Input';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { documents, documentsQueries } from 'api';
import { NotificationContext } from 'contexts';
import { formErrorHandler, getNotificationError } from 'utils';
import { AxiosError } from 'axios';
import { useQueryParams } from 'hooks';
import { DirectoryAction } from 'types';

interface AddFolderProps {
  disabled?: boolean;
}

export const AddFolder = ({ children, disabled }: React.PropsWithChildren<AddFolderProps>) => {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { notification } = useContext(NotificationContext);
  const queryClient = useQueryClient();
  const query = useQueryParams();

  const handleCancelClick = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const createFolderMutation = useMutation(documents.createDirectory, {
    onSuccess: () => {
      notification.success({
        message: t('folderCreateNotification'),
        placement: 'topRight'
      });

      queryClient.invalidateQueries(documentsQueries.getDirectoriesList().queryKey);
      queryClient.invalidateQueries(documentsQueries.getDirectoriesSearchList().queryKey);
      handleCancelClick();
    },

    onError: (error: AxiosError) => {
      form.setFields(formErrorHandler(error));
      notification.error({
        message: getNotificationError(error)
      });
    }
  });

  const handleFinish = (values: DirectoryAction) => {
    createFolderMutation.mutate({ ...values, parent_id: query.directory });
  };

  return (
    <>
      <span onClick={() => !disabled && setIsOpen(true)}>{children}</span>

      <Modal
        onCancel={handleCancelClick}
        open={isOpen}
        title={t('addFolder')}
        footer={
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Button type="link" onClick={handleCancelClick}>
              {t('cancel')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              form="folder-form"
              loading={createFolderMutation.isLoading}
            >
              {t('save')}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" id="folder-form" form={form} onFinish={handleFinish}>
          <Form.Item label={t('name')} name="name" required>
            <Input placeholder={t('typeFolderName')} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
