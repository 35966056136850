import { axios } from './axios';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { $Object, WithPagination } from '../types';
import { AccessLevelStatuses, PartnerStatuses } from '../components/utils';
import {
  AccessLevel,
  AccessLevelGroup,
  AccessLevelGroupPost,
  AccessLevelPost,
  AnafLoginURLResponse,
  ClientUserProfile,
  PartnerUserProfile,
  SuperUser
} from './users.types';
import { default as queryString } from 'query-string';
import { UploadFile } from 'antd/es/upload/interface';

export const users = {
  getPartnersList: async (params?: unknown) =>
    await axios
      .get<WithPagination<any>>('/users/partner', {
        params
      })
      .then(({ data }) => data),
  createPartner: async (params?: unknown) =>
    await axios.post<any>('/users/partner', params).then(({ data }) => data),
  getPartner: async (id: number) =>
    await axios.get<PartnerUserProfile>(`/users/partner/${id}`).then(({ data }) => data),
  updatePartner: async (id: number, data?: unknown) =>
    await axios.patch<any>(`/users/partner/${id}`, data).then(({ data }) => data),
  updateProfilePartner: async (data: FormData) => {
    const response = await axios.patch(`/users/profile/update`, data);
    return response.data;
  },
  deletePartner: async (id: number) =>
    await axios.delete<any>(`/users/partner/${id}`).then(({ data }) => data),
  getWorkspaces: async (params?: unknown) =>
    await axios
      .get<
        {
          tag_name: string;
          description: string;
        }[]
      >('/users/workspaces', {
        params
      })
      .then(({ data }) => data),
  getPartnersStats: async (params?: unknown) =>
    await axios
      .get<{ [key in PartnerStatuses]: number }>('/users/client/partner-stats', {
        params
      })
      .then(({ data }) => data),
  getPartnerProfileDetails: async () => {
    const { data } = await axios.get<PartnerUserProfile>('/users/partner/details');

    return data;
  },
  getClientProfile: async () => {
    const { data } = await axios.get<ClientUserProfile>('/users/client/profile');

    return data;
  },
  getAdminProfile: async () => {
    const { data } = await axios.get<SuperUser>('/users/admin/details');

    return data;
  },
  getUserProfile: async () => {
    const { data } = await axios.get('/users/profile');

    return data;
  },
  getSignedUrls: async (
    data: {
      name: UploadFile['name'];
      content_type?: UploadFile['type'];
      size?: UploadFile['size'];
      operation_id?: string;
      folder_id?: string | number;
    }[]
  ) => {
    const response = await axios.post('/users/partner/signed-urls', data);

    return response.data;
  },
  getSignedUrlsForClients: async (
    data: {
      name: UploadFile['name'];
      content_type?: UploadFile['type'];
      size?: UploadFile['size'];
      operation_id?: string;
      folder_id?: string | number;
    }[]
  ) => {
    const response = await axios.post('/users/client/signed-urls', data);

    return response.data;
  },
  getPartnerSignedUrls: async (
    id: number,
    data: {
      name: UploadFile['name'];
      content_type?: UploadFile['type'];
      size?: UploadFile['size'];
      operation_id?: string;
      folder_id?: string;
    }[]
  ) => {
    const response = await axios.post(`/users/partner/${id}/signed-urls`, data);

    return response.data;
  },
  getClientsList: async (params: $Object) => {
    const { data } = await axios.get<WithPagination<ClientUserProfile>>('/users/client', {
      params
    });

    return data;
  },
  createClient: async (params?: unknown) =>
    await axios.post<any>('/users/client', params).then(({ data }) => data),
  getClient: async (id: number) =>
    await axios.get<ClientUserProfile>(`/users/client/${id}`).then(({ data }) => data),
  updateClient: async (id?: number, data?: unknown) =>
    await axios.patch<any>(`/users/client/${id}`, data).then(({ data }) => data),
  getClientsStats: async (params: $Object) => {
    const { data } = await axios.get('/users/admin/client-stats', { params });

    return data;
  },
  deleteClient: async (id: number) =>
    await axios.delete<any>(`/users/client/${id}`).then(({ data }) => data),
  bulkClientDelete: async (params: $Object) =>
    await axios
      .delete('/users/client/bulk-delete', {
        params,
        paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'comma' })
      })
      .then(({ data }) => data),
  importPartnersList: async (data: FormData) => {
    const response = await axios.post('/users/partner/insert', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response.data;
  },
  bulkPartnersDelete: async (params: $Object) => {
    const response = await axios.delete('/users/partner/bulk-delete', {
      params,
      paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'comma' })
    });

    return response.data;
  },
  updateAdmin: async (data: FormData, id?: number) => {
    const response = await axios.patch(`/users/admin/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response.data;
  },
  requestClientPassword: async (data?: ClientUserProfile | SuperUser | PartnerUserProfile) => {
    const response = await axios.post('/users/client/request-reset-password', data);

    return response.data;
  },
  sendClientPassword: async (id: number) => {
    const response = await axios.post(`/users/client/${id}/send-reset-password`, {});

    return response.data;
  },
  sendPartnerPassword: async (data?: ClientUserProfile | SuperUser | PartnerUserProfile) => {
    const response = await axios.post(`/users/partner/${data?.id}/send-reset-password`, data);

    return response.data;
  },
  getClientLogo: async (domainPrefix: string) => {
    const { data } = await axios.get(`/users/client/${domainPrefix}/logo`);

    return data;
  },
  requestSendPassword: async (action: string) => {
    const response = await axios.post(action);

    return response.data;
  },
  getCifData: async (cif: string) => {
    const { data } = await axios.get(`/users/cif/${cif}`);

    return data;
  },
  getInsertExcelExample: async () => {
    const response = await axios.get('/users/partner/insert-example', {
      responseType: 'blob'
    });

    return response.data;
  },
  sendResetPassword: async (data: $Object, userType: string, domain?: string) => {
    const response = await axios.post('/users/send-reset-password', data, {
      headers: { 'X-USER-TYPE': userType, 'X-DOMAIN': domain }
    });

    return response.data;
  },
  updateLanguage: async (data: { language: string }) => {
    const response = await axios.patch(`/users/profile/update`, data);

    return response.data;
  },

  getAnafLoginURL: async () => {
    const response = await axios.post<AnafLoginURLResponse>('/users/partner/anaf-request');
    return response.data;
  },

  /**
   *
   * @param params Query params as string (ex: '' or '?param=value&other_param=value)
   */
  sendAnafCallback: async (params: string) => {
    const response = await axios.post<AnafLoginURLResponse>(
      '/users/partner/anaf-callback' + params,
      {}
    );
    return response.data;
  },

  syncDocuments: async (partnerId: number) => {
    const response = await axios.post(`/users/partner/${partnerId}/sync-documents`);
    return response.data;
  },

  getAccessLevelsList: async (params?: unknown) => {
    const { data } = await axios.get<WithPagination<AccessLevel>>('/users/access-levels', {
      params
    });

    return data;
  },
  getAccessLevelById: async (id: number) => {
    const { data } = await axios.get<AccessLevel>(`/users/access-levels/${id}`);

    return data;
  },
  createAccessLevel: async (params: unknown) => {
    const { data } = await axios.post<AccessLevelPost>('/users/access-levels', params);

    return data;
  },
  editAccessLevel: async (id: number, params: unknown) => {
    const { data } = await axios.patch<AccessLevelPost>(`/users/access-levels/${id}`, params);

    return data;
  },
  deleteAccessLevel: async (id: number) => {
    const { data } = await axios.delete<AccessLevel>(`/users/access-levels/${id}`);

    return data;
  },
  getAccessLevelsStats: async (params: $Object) => {
    const { data } = await axios.get<{ [key in AccessLevelStatuses]: number }>(
      '/users/access-levels/stats',
      { params }
    );

    return data;
  },
  getClientStats: async (params: $Object) => {
    const { data } = await axios.get<{ [key in PartnerStatuses]: number }>(
      '/users/client/client-stats',
      { params }
    );

    return data;
  },
  getAccessLevelGroupsList: async (params?: unknown) => {
    const { data } = await axios.get<WithPagination<AccessLevelGroup>>(
      '/users/access-level-groups',
      { params }
    );

    return data;
  },
  getAccessLevelGroupById: async (id: number) => {
    const { data } = await axios.get<AccessLevelGroup>(`/users/access-level-groups/${id}`);

    return data;
  },
  createAccessLevelGroup: async (params: unknown) => {
    const { data } = await axios.post<AccessLevelGroupPost>('/users/access-level-groups', params);

    return data;
  },
  editAccessLevelGroup: async (id: number, params: unknown) => {
    const { data } = await axios.patch<AccessLevelGroupPost>(
      `/users/access-level-groups/${id}`,
      params
    );

    return data;
  },
  deleteAccessLevelGroup: async (id: number) => {
    const { data } = await axios.delete<AccessLevelGroup>(`/users/access-level-groups/${id}`);

    return data;
  },
  getClientDetails: async () => {
    const { data } = await axios.get<ClientUserProfile>(`/users/client/details`);

    return data;
  },
  getPartnerDetails: async () => {
    const { data } = await axios.get<PartnerUserProfile>(`/users/partner/details`);

    return data;
  }
};

export const usersQueries = createQueryKeys('users', {
  getPartnersList: (params = {}) => ({
    queryKey: [params],
    queryFn: (context) => users.getPartnersList({ page: context.pageParam, ...params })
  }),
  getPartnersStats: (params = {}) => ({
    queryKey: [params],
    queryFn: () => users.getPartnersStats(params)
  }),
  getWorkspaces: (params = {}) => ({
    queryKey: [params],
    queryFn: () => users.getWorkspaces(params)
  }),
  createPartner: (params = {}) => ({
    queryKey: [params],
    queryFn: () => users.createPartner(params)
  }),
  getPartnerProfile: (id: number) => ({
    queryKey: [id],
    queryFn: () => users.getPartner(id)
  }),
  getPartnerProfileDetails: () => ({
    queryKey: ['partner-profile-details'],
    queryFn: () => users.getPartnerProfileDetails()
  }),
  getLoggedUserData: () => ({
    queryKey: ['logged-user'],
    queryFn: () => users.getUserProfile()
  }),
  getClientsList: (params = {}) => ({
    queryKey: ['client', params],
    queryFn: () => users.getClientsList(params)
  }),
  getClient: (id: number) => ({
    queryKey: ['client', id],
    queryFn: () => users.getClient(id)
  }),
  getClientsStats: (params = {}) => ({
    queryKey: [params],
    queryFn: () => users.getClientsStats(params)
  }),
  getAdminProfile: () => ({
    queryKey: ['admin-profile'],
    queryFn: () => users.getAdminProfile()
  }),
  getClientLogo: (domainPrefix: string) => ({
    queryKey: ['client-logo', domainPrefix],
    queryFn: () => users.getClientLogo(domainPrefix)
  }),
  getAnafLoginURL: () => ({
    queryKey: ['anaf-login-url'],
    queryFn: () => users.getAnafLoginURL()
  }),
  getAccessLevelsList: (params = {}) => ({
    queryKey: ['access-levels', params],
    queryFn: (context) => users.getAccessLevelsList({ page: context.pageParam, ...params })
  }),
  getAccessLevelById: (id: number) => ({
    queryKey: ['access-levels', id],
    queryFn: () => users.getAccessLevelById(id)
  }),
  getAccessLevelsStats: (params = {}) => ({
    queryKey: ['access-levels-stats', params],
    queryFn: () => users.getAccessLevelsStats(params)
  }),
  getClientStats: (params = {}) => ({
    queryKey: ['client-stats', params],
    queryFn: () => users.getClientStats(params)
  }),
  getAccessLevelGroupList: (params = {}) => ({
    queryKey: ['access-level-groups', params],
    queryFn: (context) => users.getAccessLevelGroupsList({ page: context.pageParam, ...params })
  }),
  getAccessLevelGroupById: (id: number) => ({
    queryKey: ['access-level-groups', id],
    queryFn: () => users.getAccessLevelGroupById(id)
  }),
  getClientDetails: () => ({
    queryKey: ['client-details'],
    queryFn: () => users.getClientDetails()
  }),
  getPartnerDetails: () => ({
    queryKey: ['partner-details'],
    queryFn: () => users.getPartnerDetails()
  })
});
