import { useMemo } from 'react';
import { default as queryString, ParseOptions } from 'query-string';
import { useLocation } from 'react-router-dom';

export function useQueryParams<T = any>(
  options: ParseOptions = {
    arrayFormat: 'comma',
    decode: true,
    parseNumbers: true,
    parseBooleans: true
  }
): T {
  const { search } = useLocation();
  return useMemo(() => queryString.parse(search, options), [search, options]) as T;
}
