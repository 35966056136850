import { css } from '@emotion/react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType) => css`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem;
  width: fit-content;
  padding: 0.375rem 0.75rem 0.375rem 0.375rem;

  &.active {
    color: ${theme['green-100']};
    background: rgba(57, 198, 116, 0.2);
  }

  &.pending {
    color: ${theme['orange-100']};
    background-color: rgba(252, 131, 3, 0.2);
  }

  &.inactive {
    color: ${theme['grey-40']};
    background-color: ${theme['grey-80']};
    border: 0.5px solid ${theme['grey-60']};

    .ant-typography {
      color: ${theme['grey-40']};
    }
  }

  &.uploaded {
    color: ${theme['green-100']};
    background-color: rgba(57, 198, 116, 0.2);
  }

  &.deleted {
    color: ${theme['red-100']};
    background-color: ${theme['red-100']}20;
  }
`;
