/** @jsxImportSource @emotion/react */
import { PropsWithChildren, useContext } from 'react';
import { ThemeContext } from 'contexts';
import { stylesInit } from './SideBar.styles';

export const SideBar = ({ children }: PropsWithChildren) => {
  const { currentTheme } = useContext(ThemeContext);
  const styles = stylesInit(currentTheme);

  return <div css={styles.Container}>{children}</div>;
};
