import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Avatar, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import {
  AccessLevelStatusLabel,
  Actions,
  NoContentPlaceholder,
  Table,
  Text,
  Tag,
  EmptyField
} from 'components';
import { onFilterChange } from 'hooks';
import { format } from 'utils';
import { usersApi } from 'api';
import { ClientUserProfile } from 'api/users.types';
import { UserStatuses } from 'types';

import { UsersPageFiltersValue } from '../pages/UsersPage';

interface UsersTableInterface {
  filters: UsersPageFiltersValue;
  onFiltersChange: onFilterChange<UsersPageFiltersValue>;
  selectedUsers: number[];
  setSelectedUsers: Dispatch<SetStateAction<number[]>>;
  onEdit: Dispatch<SetStateAction<ClientUserProfile | undefined>>;
  onDelete: Dispatch<SetStateAction<ClientUserProfile | undefined>>;
}

export const UsersTable = ({
  filters,
  onFiltersChange,
  selectedUsers,
  setSelectedUsers,
  onEdit,
  onDelete
}: UsersTableInterface) => {
  const { t } = useTranslation();

  const [hoverId, setHoverId] = useState<number | null>(null);

  const { data, isLoading } = useQuery(usersApi.getClientsList({ ...filters }));

  const columns = useMemo<ColumnsType<ClientUserProfile>>(
    () => [
      {
        title: t('name'),
        dataIndex: 'name',
        render: (name, record) => (
          <Space>
            <Avatar src={record.logo} />
            {name}
          </Space>
        )
      },
      {
        title: t('accessLevel'),
        dataIndex: 'access_levels',
        render: (levels: ClientUserProfile['access_levels'], record) => (
          <EmptyField hidden={!levels?.length && !record?.access_level_groups?.length}>
            <Tag.Group>
              {[...levels, ...record.access_level_groups].map((val, idx) => (
                <Tag key={idx}>{val.name}</Tag>
              ))}
            </Tag.Group>
          </EmptyField>
        )
      },
      {
        title: t('created'),
        dataIndex: 'created_at',
        render: (createdAt) => <Text size="lg">{format(createdAt, 'DD MMM, YYYY HH:mm')}</Text>
      },
      {
        title: t('status'),
        dataIndex: 'state',
        render: (status) => <AccessLevelStatusLabel status={status} />
      },
      {
        width: 70,
        dataIndex: 'id',
        render: (id, obj) =>
          hoverId === id && ![UserStatuses.deleted].includes(obj.state) ? (
            <Actions
              actions={[
                <Typography.Text onClick={() => onEdit(obj)}>{t('edit')}</Typography.Text>,
                <Typography.Text onClick={() => onDelete(obj)}>{t('delete')}</Typography.Text>
              ]}
            />
          ) : null
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, data?.results, hoverId]
  );

  const rowSelection: TableRowSelection<ClientUserProfile> = {
    selectedRowKeys: selectedUsers,
    onChange: (selectedRowKeys) => setSelectedUsers(selectedRowKeys as number[]),
    preserveSelectedRowKeys: true,
    getCheckboxProps: (record) => ({
      disabled: [UserStatuses.deleted].includes(record.state)
    })
  };

  return (
    <NoContentPlaceholder show={false} placeholder={undefined}>
      <Table
        columns={columns}
        dataSource={data?.results}
        rowSelection={rowSelection}
        rowKey="id"
        pagination={{
          pageSize: filters.per_page,
          current: filters.page,
          total: data?.count,
          onChange: onFiltersChange
        }}
        loading={isLoading}
        rowClassName={(record) => (record.state === UserStatuses.inactive ? 'disabled' : '')}
        onRow={(record) => ({
          onPointerEnter: () => setHoverId(record.id),
          onPointerLeave: () => setHoverId(null)
        })}
      />
    </NoContentPlaceholder>
  );
};
