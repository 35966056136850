import { Key, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Space, Spin, Typography } from 'antd';
import { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  MainLayout,
  Table,
  UserStatusLabel,
  Input,
  NoContentPlaceholder,
  Pagination,
  NoDataPlaceholder,
  Actions,
  DeleteConfirmModal,
  Text,
  makeSortingControllerHelper,
  SortingController,
  HeadTitle,
  EmptyField,
  Progress,
  Flex,
  HeaderActions
} from 'components';
import { AddPartnerBtn, PartnerFormModal } from '../components';
import { users, usersApi } from 'api';
import { NotificationContext, ThemeContext } from 'contexts';
import {
  defaultPaginationProp,
  format,
  getNotificationError,
  getRoute,
  hasActiveFilters
} from 'utils';
import { useFilters, useQueryParams } from 'hooks';
import { PartnersLabels } from 'components/MainLayout/utils';
import { PartnerStatuses } from 'components/utils';
import { UserStatuses } from 'types';
import { PartnerUserProfile } from 'api/users.types';
import { PartnersMenuSection } from 'features/common/components';

export const PartnersPage = () => {
  const params = useQueryParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const [isPartnerFormOpen, setPartnerFormOpen] = useState(false);
  const [selectedEditPartner, setSelectedEditPartner] = useState<number>();
  const [selectedDeletePartner, setSelectedDeletePartner] = useState<any>();
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [hoverId, setHoverId] = useState<number | null>(null);

  const { filters, onFiltersChange } = useFilters<{
    search: string;
    page: number;
    per_page: number;
    state?: PartnerStatuses;
    ordering?: string;
  }>(
    {
      ...defaultPaginationProp,
      search: '',
      state: undefined,
      ordering: undefined
    },
    { keyword: 'partners-filters' }
  );

  const getSortingControllerProps = makeSortingControllerHelper(filters.ordering, (value) =>
    onFiltersChange({ ordering: value })
  );

  const { data: partners, isLoading } = useQuery(
    usersApi.getPartnersList({ ...filters, state: params.state })
  );

  const partnerStatsQuery = useQuery(usersApi.getPartnersStats());

  const columns = useMemo<ColumnsType<PartnerUserProfile>>(
    () => [
      {
        title: (
          <SortingController {...getSortingControllerProps('name')}>
            {t('Nume partener')}
          </SortingController>
        ),
        dataIndex: 'name',
        render: (name, { id, cif }) => (
          <Link to={getRoute('PartnerPage', { id })}>
            <Text size="lg">{name}</Text>
            <PartnerDescription>{cif}</PartnerDescription>
          </Link>
        ),
        width: '40%'
      },
      {
        title: t('department'),
        dataIndex: 'department',
        render: (value) => <EmptyField>{value}</EmptyField>
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('state')}>
            {t('profileStatus')}
          </SortingController>
        ),
        dataIndex: 'state',
        render: (state) => <UserStatusLabel unfilled status={state} />
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('documents_count')}>
            {t('documents')}
          </SortingController>
        ),
        render: (obj) => (
          <Flex gap={12} style={{ minWidth: 160 }}>
            <div>{obj.documents_count}</div>
            <Progress percent={(obj.storage_sum / obj.storage_limit || 0) * 100} />
          </Flex>
        )
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('last_login')}>
            {t('lastActivity')}
          </SortingController>
        ),
        dataIndex: 'last_login',
        render: (lastLogin) => (
          <EmptyField hidden={!lastLogin}>
            <Text size="lg">{format(lastLogin)}</Text>
            <PartnerDescription>{format(lastLogin, 'HH:mm A')}</PartnerDescription>
          </EmptyField>
        )
      },
      {
        width: 70,
        dataIndex: 'id',
        render: (id, obj) =>
          hoverId === id ? (
            <Actions
              disabled={[UserStatuses.deleted].includes(obj?.state)}
              actions={[
                <Typography.Text
                  onClick={() => {
                    setSelectedEditPartner(id);
                    setPartnerFormOpen(true);
                  }}
                >
                  {t('edit')}
                </Typography.Text>,
                <Typography.Text onClick={() => setSelectedDeletePartner(obj)}>
                  {t('delete')}
                </Typography.Text>
              ]}
            />
          ) : null
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partners?.results, hoverId]
  );

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<PartnerUserProfile> = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
    getCheckboxProps: (record) => ({
      disabled: [UserStatuses.deleted].includes(record.state)
    })
  };

  const PartnerDescription = styled.div`
    font-size: 0.75rem;
    color: ${currentTheme['grey-60']};
  `;

  const invalidatePartnersQueries = () => {
    queryClient.invalidateQueries(usersApi.getPartnersList().queryKey);
    queryClient.invalidateQueries(usersApi.getPartnersStats().queryKey);
  };

  const deleteMutation = useMutation(() => users.deletePartner(selectedDeletePartner?.id), {
    onSuccess: () => {
      invalidatePartnersQueries();
      setSelectedDeletePartner(undefined);

      notification.success({
        message: t('deletedPartner'),
        placement: 'bottomRight'
      });
    },
    onError: (error: AxiosError) => notification.error({ message: getNotificationError(error) })
  });

  const deleteBulkMutation = useMutation(() => users.bulkPartnersDelete({ ids: selectedRowKeys }), {
    onSuccess: () => {
      invalidatePartnersQueries();
      setShowBulkDeleteModal(false);
      setSelectedRowKeys([]);

      notification.success({ message: 'deletedPartners' });
    },
    onError: (error: AxiosError) => {
      setSelectedRowKeys([]);

      notification.error({ message: getNotificationError(error) });
    }
  });

  const PageHeading = useMemo(() => {
    const foundItem = PartnersLabels[(params.state as keyof typeof PartnersLabels) || 'total'];

    return <HeadTitle Icon={foundItem.icon} label={t(foundItem.menuLabel)} />;
  }, [params.state, t]);

  const selectedCount = selectedRowKeys.length;

  return (
    <MainLayout
      pageHeading={
        !selectedCount ? (
          PageHeading
        ) : (
          <HeaderActions
            onClear={() => setSelectedRowKeys([])}
            onDelete={() => setShowBulkDeleteModal(true)}
          >
            {selectedCount > 1
              ? t('selectedElements', {
                  count: selectedCount,
                  name: t('partners')
                })
              : t('selectedElement', {
                  count: selectedCount,
                  name: t('partner')
                })}
          </HeaderActions>
        )
      }
      onChange={onFiltersChange}
      pageActions={
        <Space>
          <Input
            prefix="search"
            style={{ width: 420 }}
            placeholder={t('searchPartner')}
            value={filters.search}
            onChange={(e) => onFiltersChange({ search: e.target.value })}
          />

          <Space size={16}>
            <AddPartnerBtn
              onManualClick={() => setPartnerFormOpen(true)}
              onImportClick={() => null}
            />
          </Space>
        </Space>
      }
      menuSections={<PartnersMenuSection onChange={onFiltersChange} />}
    >
      <PartnerFormModal
        id={selectedEditPartner}
        open={isPartnerFormOpen}
        onSuccess={() =>
          notification.success({
            message: t(selectedEditPartner ? 'editedPartner' : 'addedPartner'),
            placement: 'bottomRight'
          })
        }
        onCancel={() => {
          setPartnerFormOpen(false);

          if (selectedEditPartner) setSelectedEditPartner(undefined);
        }}
      />

      <DeleteConfirmModal
        title={t('deletePartner')}
        open={!!selectedDeletePartner}
        mutation={deleteMutation}
        onCancel={() => setSelectedDeletePartner(undefined)}
      >
        <Typography.Text style={{ color: currentTheme['grey-40'], fontSize: '0.875rem' }}>
          {t('partnerDeleteQuestion')}{' '}
          <Typography.Link href={`/partners/${selectedDeletePartner?.id}`}>
            "{selectedDeletePartner?.name}"
          </Typography.Link>
          ?
        </Typography.Text>
      </DeleteConfirmModal>

      <DeleteConfirmModal
        title={t('deletePartner')}
        mutation={deleteBulkMutation}
        open={showBulkDeleteModal}
        onCancel={() => setShowBulkDeleteModal(false)}
      >
        <Text style={{ color: currentTheme['grey-40'], fontSize: '0.875rem' }}>
          {t('partnersBulkDeleteQuestion', { count: selectedRowKeys.length })}
        </Text>
      </DeleteConfirmModal>

      <NoContentPlaceholder
        show={
          !partners?.results.length &&
          !isLoading &&
          !hasActiveFilters(filters) &&
          partnerStatsQuery.data?.total === 0
        }
        placeholder={
          <NoDataPlaceholder
            mainIcon="user"
            leftIcon="edit"
            onLeftClick={() => null}
            rightIcon="upload-outline"
            onRightClick={() => null}
            description={<div dangerouslySetInnerHTML={{ __html: t('noPartnersDescription') }} />}
            bottomContent={
              <AddPartnerBtn
                onManualClick={() => setPartnerFormOpen(true)}
                onImportClick={() => null}
              />
            }
          />
        }
      >
        <Spin spinning={isLoading}>
          <Table
            dataSource={partners?.results}
            columns={columns}
            rowSelection={rowSelection}
            rowKey="id"
            pagination={false}
            showSorterTooltip={{ children: <>test</> }}
            onRow={(record) => ({
              onPointerEnter: () => setHoverId(record.id),
              onPointerLeave: () => setHoverId(null)
            })}
          />
        </Spin>

        <Pagination
          pageSize={filters.per_page}
          current={filters.page}
          total={partners?.count}
          onChange={onFiltersChange}
        />
      </NoContentPlaceholder>
    </MainLayout>
  );
};
