import { css } from '@emotion/react';
import { ThemeType } from '../../types';

export const stylesInit = (theme: ThemeType) => ({
  DropdownWrapper: css`
    padding: 24px;
    background-color: ${theme['grey-80']};
    border-radius: 8px;
    border: 0.5px solid ${theme['grey-70']};
    margin-top: 22px;
    max-height: 700px;
    overflow: auto;
  `,
  SectionTitle: css`
    padding: 8px;
    border-radius: 8px;
    background-color: ${theme['grey-90']};
    width: calc(100% - 16px);
    color: ${theme['grey-50']};
  `,
  SectionParagraph: css`
    font-size: 12px;
  `
});
