import { css } from '@emotion/react';

import { useParams, Link, useNavigate } from 'react-router-dom';
import { Space } from 'antd';

import { MainLayout } from 'components';
import { ShortLeft } from 'icons';
import { PartnerDocumentsTable } from 'components';
import { getRoute, palette } from 'utils';
import { useFilters } from 'hooks';
import { PartnersMenuSection } from 'features/common/components';
import { PARTNER_DOCUMENTS_FILTERS } from 'app-constants';

interface PartnerDocumentsFolderPageFiltersInterface {
  search: string;
  page: number;
  per_page: number;
  documents_state?: string;
  ordering?: string;
  directory?: string | number | null;
  '--selectedTree'?: string[];
}

export const PartnerDocumentsFolderPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const folderName = params['*'];

  const { filters, onFiltersChange } = useFilters<PartnerDocumentsFolderPageFiltersInterface>(
    {
      search: '',
      page: 1,
      per_page: 8,
      documents_state: undefined,
      ordering: undefined,
      directory: undefined,
      '--selectedTree': undefined
    },
    { keyword: PARTNER_DOCUMENTS_FILTERS, useQueryParams: true }
  );

  return (
    <MainLayout
      pageHeading={
        <Space styles={{ item: { display: 'flex' } }}>
          <Link
            onClick={(e) => {
              if (window.history.length > 1) {
                e.preventDefault();
                navigate(-1);
              }
            }}
            css={css`
              display: inline-flex;
            `}
            to={getRoute('PartnerDocumentsPage', params)}
          >
            <ShortLeft color={palette['purple-100']} />
          </Link>
          {folderName}
        </Space>
      }
      filters={filters}
      onChange={onFiltersChange}
      menuSections={<PartnersMenuSection onChange={onFiltersChange} />}
    >
      <PartnerDocumentsTable
        filters={filters}
        onFiltersChange={onFiltersChange}
        showFolderColumn={false}
        hasStateFilter
      />
    </MainLayout>
  );
};
