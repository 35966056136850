import { css } from '@emotion/react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType) => ({
  Container: css`
    padding: 18px 32px;
    border-bottom: 0.5px solid ${theme['grey-70']};
    box-sizing: border-box;
  `,
  Title: css`
    color: ${theme.colorWhite};
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    padding: 8px 0;
  `
});
