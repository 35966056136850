import { UseMutationResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Space } from 'antd';

import { Modal, ModalProps } from './Modal';

export interface DeleteConfirmModalProps extends ModalProps {
  mutation: UseMutationResult<unknown, unknown, void>;
  onCancel: () => void;
}

export const DeleteConfirmModal = ({
  title,
  mutation,
  open,
  children,
  onCancel,
  ...props
}: DeleteConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      centered
      footer={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button type="link" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="primary" loading={mutation.isLoading} onClick={() => mutation.mutate()}>
            {t('delete')}
          </Button>
        </Space>
      }
      {...props}
    >
      {children}

      <Divider />
    </Modal>
  );
};
