import { CSSProperties, forwardRef, HTMLAttributes, PropsWithChildren } from 'react';

export interface FlexProps extends Omit<HTMLAttributes<HTMLDivElement>, 'wrap'> {
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
  direction?: CSSProperties['flexDirection'];
  wrap?: true | CSSProperties['flexWrap'];
  gap?: CSSProperties['gap'];
  width?: CSSProperties['width'];
}

export const Flex = forwardRef<HTMLDivElement, PropsWithChildren<FlexProps>>(
  ({ align, justify, direction, wrap, style, gap, width = '100%', ...props }, ref) => {
    return (
      <div
        style={{
          width: width,
          display: 'flex',
          justifyContent: justify,
          alignItems: align,
          flexDirection: direction,
          flexWrap: wrap === true ? 'wrap' : wrap,
          gap,
          ...style
        }}
        {...props}
        ref={ref}
      />
    );
  }
);
