import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';

import { usersApi } from 'api';
import { QuerySelectionCollapse } from 'components';
import { getRoute } from 'utils';
import { onFilterChange } from 'hooks';
import { CircleCheck } from 'icons';

import { PartnersLabels } from 'components/MainLayout/utils';
import { UsersPageFiltersValue } from '../pages/UsersPage';

interface UsersMenuSectionInterface {
  onChange?: onFilterChange<UsersPageFiltersValue>;
}

export const UsersMenuSection = ({ onChange }: UsersMenuSectionInterface) => {
  const { t } = useTranslation();
  const params = useParams<{ domain: string }>();

  return (
    <QuerySelectionCollapse
      title={
        <Space size={4} styles={{ item: { display: 'flex' } }}>
          <CircleCheck /> {t('status').toUpperCase()}
        </Space>
      }
      query={usersApi.getClientStats}
      paramsKey="state"
      route={getRoute('UsersPage', params)}
      translates={PartnersLabels}
      onChange={onChange}
    />
  );
};
