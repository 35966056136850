/** @jsxImportSource @emotion/react */

import { stylesInit } from './noPartnersPlaceholder.styles';
import { Space, Typography } from 'antd';
import { Icon } from '../Icon/Icon';
import { ReactNode, useContext } from 'react';
import { ThemeContext } from 'contexts';
import { useTranslation } from 'react-i18next';

interface NoPartnersPlaceholderInterface {
  mainIcon: string;
  leftIcon: string;
  onLeftClick: () => void;
  rightIcon: string;
  onRightClick: () => void;
  bottomContent?: ReactNode;
  description: ReactNode;
}

export const NoDataPlaceholder = ({
  mainIcon,
  leftIcon,
  onLeftClick,
  rightIcon,
  onRightClick,
  bottomContent,
  description
}: NoPartnersPlaceholderInterface) => {
  const { currentTheme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const styles = stylesInit(currentTheme);

  return (
    <div css={styles.placeholderContainer}>
      <Space css={styles.placeholderWrapper} size={40}>
        <div css={styles.iconWrapper}>
          <Icon type={mainIcon} size={80} fill={currentTheme['purple-125']} />
          <div css={styles.leftAction} onClick={onLeftClick}>
            <Icon type={leftIcon} />
          </div>
          <div css={styles.rightAction} onClick={onRightClick}>
            <Icon type={rightIcon} />
          </div>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Typography.Title level={1} style={{ margin: '0 0 8px 0' }}>
            {t('welcome')}
          </Typography.Title>
          <Typography.Text style={{ textAlign: 'center' }}>{description}</Typography.Text>
        </div>

        {bottomContent}
      </Space>
    </div>
  );
};
