import { APIDocument, DefaultListParams, DocumentStatus } from 'types';

export type DocumentStats = {
  [Property in DocumentStatus]: number;
};

export interface DirectoriesParams extends DefaultListParams {
  ids?: number | string;
  parent?: number | string | null;
  user_id?: number | string;
  partner_ids?: number | string;
}

export interface StatsParams extends DefaultListParams {
  ids?: number | string;
  user_id?: number;
}

export interface DocumentsParams extends DefaultListParams {
  ids?: number | string;
  user_id?: number | string;
  state?: DocumentStatus;
  folder_name?: string;
  group_id?: string | number | undefined;
  partner?: string | number;
  type?: string;
}

export interface ChartParams {
  search?: string;
  ordering?: string;
  user_id?: number | string;
  state?: DocumentStatus;
  partner_ids?: number[] | number;
  folder_name?: string;
  group_id?: string | number;
}

export interface BulkActions extends Omit<DocumentsParams, 'ids'> {
  ids?: number | number[];
  exclude_ids?: number | number[];
}

export interface DownloadFiles extends BulkActions {
  directory?: number;
  name?: string;
}

export enum DelegatedDocumentType {
  WAITING = 'waiting',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  EXPIRED = 'expired'
}

export interface DelegatedApprovalDocument {
  document: APIDocument;
  created_at?: string;
  email?: string;
  type?: DelegatedDocumentType;
}

export interface DelegatedDocument extends DelegatedApprovalDocument {
  id: number;
  modified_at?: string;
  approved_at?: string;
  expired_at?: string;
  token?: string;
}

export interface CreateDelegateDocument extends Omit<DelegatedApprovalDocument, 'document'> {
  document: number;
}

export interface DownloadBackgroundZip {
  downloaded_documents: number;
  id: number;
  total_documents: number;
  zip_file?: string;
}
