import { SelectProps } from 'components';

export const chartDateFilterOptions = [
  {
    label: 'Ultimele 12 luni',
    value: 12
  },
  {
    label: 'Ultimele 6 luni',
    value: 6
  },
  {
    label: 'Ultimele 3 luni',
    value: 3
  },
  {
    label: 'Ultima luna',
    value: 1
  }
] satisfies SelectProps['options'];

export const MonthsOptions = [
  { label: 'Ianuarie', value: 1 },
  { label: 'Februarie', value: 2 },
  { label: 'Martie', value: 3 },
  { label: 'Aprilie', value: 4 },
  { label: 'Mai', value: 5 },
  { label: 'Iunie', value: 6 },
  { label: 'Iulie', value: 7 },
  { label: 'August', value: 8 },
  { label: 'Septembrie', value: 9 },
  { label: 'Octombrie', value: 10 },
  { label: 'Noiembrie', value: 11 },
  { label: 'Decembrie', value: 12 }
];
