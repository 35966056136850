import { useMutation } from '@tanstack/react-query';

import { Dispatch, useContext } from 'react';
import { Button, Divider, ModalProps, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Modal, Text, Title } from 'components';
import { NotificationContext, ThemeContext, UserContext } from 'contexts';
import { AxiosError } from 'axios';
import { getNotificationError } from 'utils';
import { ClientUserProfile, PartnerUserProfile, SuperUser } from '../api/users.types';

export interface ChangePasswordModalProps extends ModalProps {
  open: boolean;
  setOpen: Dispatch<boolean>;
  call: (data?: ClientUserProfile | SuperUser | PartnerUserProfile) => Promise<any>;
  text?: string;
}

export const ChangePasswordModal = ({ open, setOpen, call, text }: ChangePasswordModalProps) => {
  const { t } = useTranslation();

  const { data: loggedUser } = useContext(UserContext);
  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const { mutate: resetPassMutation, isLoading } = useMutation(() => call(loggedUser), {
    onSuccess: () => {
      notification.success({ message: t('resetEmailWasSent') });
      onModalCancel();
    },
    onError: (error: AxiosError) => {
      notification.error({
        message: getNotificationError(error)
      });
      onModalCancel();
    }
  });
  const onModalCancel = () => setOpen(false);

  return (
    <Modal
      title={
        <Title level={4} style={{ margin: 0 }}>
          {t('changePassword')}
        </Title>
      }
      centered
      open={open}
      onCancel={onModalCancel}
      footer={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button type="link" onClick={onModalCancel}>
            {t('cancel')}
          </Button>
          <Button type="primary" loading={isLoading} onClick={() => resetPassMutation()}>
            {t('change')}
          </Button>
        </Space>
      }
    >
      <Text
        style={{
          color: currentTheme['grey-40'],
          fontSize: '0.875rem',
          display: 'inline-block'
        }}
        size="md"
      >
        {text || t('changePasswordQuestion')}
      </Text>

      <Divider />
    </Modal>
  );
};
