import mime from 'mime';

import { DocumentType } from 'components';

export const mimeTypeToDocumentType = (fileName?: string): DocumentType => {
  if (fileName?.endsWith('.pdf')) return 'pdf';
  const mimeType = mime.getType(fileName || '') || '';
  if (mimeType.toString().startsWith('application') || mimeType.toString().startsWith('text'))
    return 'doc';
  if (mimeType.toString().startsWith('image')) return 'img';
  return 'unknown';
};
