import { css } from '@emotion/react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType) => ({
  notificationList: css`
    width: 392px;
    background-color: ${theme['grey-80']};
    //padding: 24px;
    border: 0.5px solid ${theme['grey-70']};
    border-radius: 8px;
    margin-top: 24px;

    .ant-list-header {
      padding: 0;
      padding-bottom: 20px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      border-block-end: 0.5px solid ${theme['grey-70']};
    }
  `,
  notificationListItem: css`
    &.ant-list-item {
      height: 80px;
      overflow: hidden;
      padding-top: 20px;
      padding-bottom: 0;
      border-bottom: none;
    }
  `,
  notificationListItemTitle: (is_read?: boolean) => css`
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: ${!is_read ? 'pointer' : 'default'};
  `,
  notificationListItemDate: css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${theme['grey-50']};
  `,
  notificationListItemAvatar: css`
    &.ant-avatar {
      border-radius: 8px;
    }
  `
});

export const notificationBadge = (theme: ThemeType) => `
    height: 20px;
    line-height: unset;
    color: ${theme['grey-50']};
    margin-left: 12px;
    cursor: pointer;
  `;
