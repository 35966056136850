import styled from '@emotion/styled';

import { ReactNode, useContext } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';

import { ThemeContext } from 'contexts';
import { MoreVertical } from 'icons';

import { Dropdown } from '../Dropdown';

interface ActionsInterface {
  /**
   * Specify either actions, either dropdownItems
   */
  actions?: ReactNode[];
  dropdownItems?: ItemType[];
  disabled?: boolean;
  disablePaddingIcon?: boolean;
}

export const Actions = ({
  actions,
  dropdownItems,
  disabled,
  disablePaddingIcon
}: ActionsInterface) => {
  const { currentTheme } = useContext(ThemeContext);

  const StyledMoreVertical = styled(MoreVertical)`
    padding: ${disablePaddingIcon ? 0 : 5}px;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    color: ${currentTheme['grey-50']};
    transition: 0.2s;
    font-size: 16px;

    &:hover {
      color: ${currentTheme['grey-white']};
    }
  `;

  return (
    <Dropdown
      disabled={disabled}
      menu={{
        items:
          dropdownItems ||
          actions?.map((i, key) => ({
            key: key,
            label: i
          })) ||
          []
      }}
      trigger={['click']}
    >
      <StyledMoreVertical />
    </Dropdown>
  );
};
