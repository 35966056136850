import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import Icon from '@ant-design/icons';
import { Flex, Text } from 'components';
import { ThemeContext } from 'contexts';
import { format, dateFormat } from 'utils';
import { DelegatedDocument } from 'api/documents.types';
import { delegateStatusDocument } from './utils';
import { stylesInit } from './DelegateDocumentStatus.styles';

interface DelegateDocumentStatusProps {
  document: DelegatedDocument;
}

export const DelegateDocumentStatus = ({ document }: DelegateDocumentStatusProps) => {
  const { t } = useTranslation();
  const { currentTheme } = useContext(ThemeContext);

  const styles = stylesInit(currentTheme);

  return (
    <Row gutter={16} justify="start" align="bottom">
      {document?.type && (
        <Col flex={1}>
          <Flex align="center" gap={4} width="fit-content">
            <Icon
              component={delegateStatusDocument?.[document?.type]?.icon}
              style={{
                color: delegateStatusDocument?.[document?.type]?.color
              }}
            />

            <Text
              css={styles.DelegateStatusText}
              color={delegateStatusDocument?.[document?.type]?.color}
            >
              {t(delegateStatusDocument?.[document?.type]?.text)}
            </Text>
          </Flex>
        </Col>
      )}

      <Col>
        <Text style={{ width: '100%' }}>{document?.email}</Text>
      </Col>

      <Col>
        <Text size="sm" color={currentTheme['grey-60']}>
          {format(document?.modified_at, dateFormat)}
        </Text>
      </Col>
    </Row>
  );
};
