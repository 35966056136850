/** @jsxImportSource @emotion/react */

import { useInfiniteQuery } from '@tanstack/react-query';
import { useThrottledState } from '@react-hookz/web';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Button, Space, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usersApi } from 'api';
import { Search } from 'icons';

import { Select, SelectProps } from './Select';
import { Input } from './Input/Input';
import { onFilterChange } from 'hooks';
import { $Object, ClientDocumentsFiltersValue } from 'types';

export interface PartnerSelectDropdownProps {
  selectedPartners: number[];
  onFiltersChange: onFilterChange<ClientDocumentsFiltersValue>;
}

const DROPDOWN_PAGE_SIZE = 20;
const DROPDOWN_SEARCH_THROTTLE_TIME = 300;

const StyledOptionsWrapper = styled.div`
  padding: 16px;
`;

export const PartnerSelectDropdown = ({
  selectedPartners,
  onFiltersChange
}: PartnerSelectDropdownProps) => {
  const { t } = useTranslation();

  const [search, setSearch] = useThrottledState('', DROPDOWN_SEARCH_THROTTLE_TIME);
  // Can't use ref here as it's not initially rendered
  const [trigger, setTrigger] = useState<HTMLElement | null>(null);

  const query = useInfiniteQuery({
    ...usersApi.getPartnersList({
      search,
      per_page: DROPDOWN_PAGE_SIZE
    }),
    getNextPageParam: (lastPage) =>
      lastPage.current_page < lastPage.total_pages ? lastPage.current_page + 1 : undefined,
    keepPreviousData: true
  });

  const options = useMemo(() => {
    const result: SelectProps['options'] =
      query.data?.pages?.flatMap((page) =>
        page.results?.map((item) => ({
          label: item.name,
          value: item.id,
          payload: item
        }))
      ) || [];

    if (query.hasNextPage)
      result.push({
        value: 0,
        label: query.isFetchingNextPage ? (
          <Space align="center">
            <Spin />
            {t('pending')}
          </Space>
        ) : (
          // In case ref somehow doesn't work
          <Button
            size="small"
            type="ghost"
            ref={(e) => setTrigger(e)}
            onClick={() => query.fetchNextPage()}
          >
            {t('loadMore')}
          </Button>
        ),
        disabled: true
      });
    return result;
  }, [query, t]);

  useEffect(() => {
    /**
     * Antd is using virtual list under the hood
     * So when the load more button is rendered, we trigger a page load immediately
     */
    if (trigger) query.fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const onSelect = (values: number[], option: $Object) =>
    onFiltersChange({
      partner_ids: values
    });

  return (
    <Select
      placeholder={t('allPartners')}
      popupMatchSelectWidth={320}
      value={selectedPartners}
      onChange={onSelect}
      size="large"
      dropdownRender={(menu) => (
        <StyledOptionsWrapper>
          <Input
            style={{ width: '100%' }}
            prefix={<Search fontSize={20} />}
            placeholder={t('searchPartner')}
            onChange={(e) => setSearch(e.target.value)}
          />
          {query.isLoading ? (
            <Spin>
              <div
                css={css`
                  height: 60px;
                `}
              />
            </Spin>
          ) : (
            <div
              css={css`
                margin-top: ${options.length ? '8px' : '24px'};
              `}
            >
              {menu}
            </div>
          )}
        </StyledOptionsWrapper>
      )}
      options={options}
      mode="multiple"
      allowClear
      style={{ minWidth: 160 }}
    />
  );
};
