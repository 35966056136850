import { css } from '@emotion/react';

import { palette } from 'utils';

export const notificationStyles = css`
  .ant-notification {
    .ant-notification-notice {
      border-radius: 8px;
      background-color: ${palette['grey-80']};
      border: 1px solid ${palette['grey-70']};
      padding: 16px;

      .ant-notification-notice-close {
        background-color: ${palette['grey-90']};
        border-radius: 8px;
        padding: 2px;
        width: unset;
        height: unset;
        top: 16px;
        right: 16px;
      }

      .ant-notification-notice-with-icon {
        .ant-notification-notice-message {
          font-size: 16px;
          line-height: 20px;

          padding-top: 2px;
          margin-bottom: 0;
          margin-inline-start: 32px;
          padding-inline-end: 30px;
        }
      }
    }
  }
`;
