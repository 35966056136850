import { ActiveUserRole } from '../types';

export const getProfileRouteName = ({ isAdmin, isClient, isPartner }: ActiveUserRole) => {
  if (isAdmin) return 'AdminProfilePage';

  if (isClient) return 'ClientProfilePage';

  if (isPartner) return 'PartnerProfilePage';

  return '404Page';
};

export const getMainRouteName = ({ isAdmin, isClient, isPartner }: ActiveUserRole) => {
  if (isAdmin) return 'ClientsPage';

  if (isClient) return 'DocumentsPage';

  if (isPartner) return 'PartnerDocumentsPage';

  return '404Page';
};

export const getLogOutRedirectRouteName = ({ isAdmin, isClient, isPartner }: ActiveUserRole) => {
  if (isPartner) return 'PartnerLogInPage';

  if (isClient) return 'ClientLogInPage';

  if (isAdmin) return 'AdminLogInPage';

  return 'BasePage';
};
