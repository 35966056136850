import { useContext, useMemo } from 'react';
import { Table as AntdTable, Spin } from 'antd';
import { TableProps } from 'antd/es/table/InternalTable';
import { AnyObject } from 'antd/lib/_util/type';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ThemeContext } from 'contexts';
import { Pagination, PaginationProps } from 'components';

export interface TablePropsInterface<T extends AnyObject>
  extends Omit<TableProps<T>, 'pagination'> {
  scrollSize?: number;
  defaultScrollSize?: number;
  pagination?: false | PaginationProps;
}

/*
  Header 83px;
  Thead 70px
  Pagination 65px
*/
const DEFAULT_SCROLL_SIZE = 83 + 83 + 70 + 65;

export function Table<T extends AnyObject>({
  scrollSize = 0,
  defaultScrollSize = DEFAULT_SCROLL_SIZE,
  loading,
  pagination,
  ...props
}: TablePropsInterface<T>) {
  const { currentTheme } = useContext(ThemeContext);

  const yScrollStyle = useMemo(
    () => css`
      .ant-table-container {
        .ant-table-header {
          table {
            position: absolute;
            table-layout: unset !important;
          }
          height: 70px;
        }
        .ant-table-body {
          overflow: auto !important;
          height: calc(100vh - ${defaultScrollSize + scrollSize}px);
        }
      }
    `,
    [defaultScrollSize, scrollSize]
  );

  const StyledTable = useMemo(
    () => styled(AntdTable)`
      .ant-table-thead {
        font-size: 0.875rem;

        .ant-table-cell {
          color: ${currentTheme['grey-50']};
          font-weight: 300;
          height: 4.375rem;
          min-height: 4.375rem;

          &:before {
            display: none;
          }
        }

        > tr > th {
          background: transparent;
          border-bottom: 0.5px solid ${currentTheme['grey-70']};
          border-top: 0.5px solid ${currentTheme['grey-70']};
        }

        > tr > td {
          background: transparent;
          border-bottom: 0.5px solid ${currentTheme['grey-70']};
          border-top: 0.5px solid ${currentTheme['grey-70']};
        }
      }

      .ant-table-cell {
        &:first-of-type {
          padding-left: 2rem;
        }

        z-index: 2;
        &.absolute {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: transparent !important;
          z-index: 1;
          & > div {
            display: none;
            width: 100%;
            height: 100%;
          }
        }
      }

      .ant-table-tbody > tr.ant-table-row {
        position: relative;
        &.last-item {
          .ant-table-cell.absolute > div {
            display: block;
          }
        }
        .ant-table-cell a {
          color: ${currentTheme['grey-white']};
        }

        &.disabled {
          color: ${currentTheme['grey-60']};
          &.disabled-pointer {
            pointer-events: none;
          }
          img {
            filter: grayscale(100%);
          }
          .ant-tag {
            background-color: ${currentTheme['grey-80']} !important;
          }
        }

        &:hover {
          > td {
            background: ${currentTheme['grey-90']};
          }
        }

        > td {
          border-bottom: none;
        }
      }

      .ant-table-tbody > tr.ant-table-row-selected > td {
        background: #8e4dff1a;
      }

      .ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > td {
        background: ${currentTheme['grey-90']};
      }

      .ant-table-container table > thead > tr:first-child {
        & > *:last-child {
          border-start-end-radius: 0;
        }
        & > *:first-child {
          border-start-start-radius: 0;
        }
      }

      ${yScrollStyle}
    `,
    [currentTheme, yScrollStyle]
  );

  return (
    <Spin spinning={!!loading}>
      <StyledTable
        scroll={defaultScrollSize ? { y: 'auto', x: 'auto' } : {}}
        pagination={false}
        {...props}
      />

      {pagination && <Pagination {...pagination} />}
    </Spin>
  );
}
