/** @jsxImportSource @emotion/react */

import React, { ReactNode } from 'react';
import { stylesInit } from './filtersSection.styles';

interface FiltersSectionInterface {
  leftSide: ReactNode;
  rightSide?: ReactNode;
}

export const FiltersSection = ({ leftSide, rightSide }: FiltersSectionInterface) => {
  const styles = stylesInit();

  return (
    <div css={styles}>
      {leftSide}

      {rightSide}
    </div>
  );
};
