import { Modal as AntModal, Typography } from 'antd';
import type { ModalProps as AntModalProps } from 'antd';
import { CloseBig } from 'icons';

export interface ModalProps extends AntModalProps {}

export const Modal = ({ children, title, ...props }: ModalProps) => {
  return (
    <AntModal
      title={
        <Typography.Title level={4} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
      }
      closeIcon={<CloseBig />}
      width={400}
      {...props}
    >
      {children}
    </AntModal>
  );
};
