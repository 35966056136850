/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { Collapse } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DocumentCommentItems } from 'components';
import { CommentsContext, ThemeContext } from 'contexts';
import { ChevronDown, ChevronUp, Info2Outline } from 'icons';
import { APIDocument } from 'types';

import { stylesInit } from './DocumentComments.styles';

interface DocumentCommentsProps {
  document: APIDocument;
}

export const DocumentComments = ({ document }: DocumentCommentsProps) => {
  const { t } = useTranslation();
  const push = useNavigate();

  const { setActiveThread } = useContext(CommentsContext);
  const { currentTheme } = useContext(ThemeContext);

  const styles = stylesInit(currentTheme);

  const onOpen = (thread_id?: string | string[]) => {
    setActiveThread(Array.isArray(thread_id) ? thread_id.join('.') : thread_id);
    push(`#${thread_id}`);
  };

  const totalComments = Object.keys(document.comments).length;

  return (
    <div css={styles.Container}>
      <div css={styles.Header}>
        {t('documentNeedInfo')} <span>({totalComments})</span>
        <Info2Outline css={styles.InfoIcon} />
      </div>
      <div css={styles.Body}>
        <Collapse
          collapsible="header"
          defaultActiveKey={Object.keys(document.comments).map((i) => i)}
          onChange={onOpen}
          expandIconPosition="end"
          expandIcon={(panelProps) =>
            panelProps.isActive ? (
              <ChevronUp css={styles.Chevron} />
            ) : (
              <ChevronDown css={styles.Chevron} />
            )
          }
          items={Object.keys(document.comments).map((item, key) => ({
            key: item,
            label: (
              <div css={styles.CollapseItemLabel}>
                <span css={styles.CollapseKey}>{key + 1}</span>
                {document.comments[item] &&
                  document.comments[item][0]?.additional_info?.highlight?.text}
              </div>
            ),
            children: (
              <DocumentCommentItems document={document} comments={document.comments[item]} />
            )
          }))}
        />
      </div>
    </div>
  );
};
