import { css } from '@emotion/react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType) => ({
  Container: css`
    padding: 18px 32px;
    border-bottom: 0.5px solid ${theme['grey-70']};
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: ${theme.colorBgBase};
    border-top: 1px solid ${theme['grey-70']};
  `,

  ClientContainer: css`
    padding: 18px 32px;
    border-bottom: 0.5px solid ${theme['grey-70']};
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: ${theme.colorBgBase};
  `,

  Button: css`
    direction: rtl;

    .ant-btn-icon {
      margin-inline-end: 0px !important;
    }
  `
});
