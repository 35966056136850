import styled from '@emotion/styled';

import { CloseSmall } from 'icons';

export interface ResultTagProps {
  backgroundColor?: string;
  onCloseClick?: () => void;
}

const StyledResultTag = styled.div<ResultTagProps>`
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  line-height: 20px;

  .label {
    color: white;
    display: inline-block;
    padding: 0 8px;
  }
  .close {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.75);
  }

  background-color: ${(props) => props.backgroundColor};
`;

export const ResultTag = (props: React.PropsWithChildren<ResultTagProps>) => {
  const { children, onCloseClick } = props;

  return (
    <StyledResultTag {...props}>
      <div className="label">{children}</div>
      <CloseSmall className="close" onClick={onCloseClick} fontSize={20} />
    </StyledResultTag>
  );
};
