/** @jsxImportSource @emotion/react */
import { APIDocument } from 'types';
import { stylesInit } from './DocumentContent.styles';

interface DocumentPDFProps {
  document: APIDocument;
}

export const DocumentPDF = ({ document }: DocumentPDFProps) => {
  const styles = stylesInit();

  return (
    <div css={styles.Container}>
      <iframe title={document.title} width="100%" height="700px" src={document.file} />
    </div>
  );
};
