import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Form, Input, Space, Spin, Typography } from 'antd';
import { NotificationContext, ThemeContext } from 'contexts';
import { useForm } from 'antd/lib/form/Form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { users, usersApi } from 'api';
import { Modal } from 'components';
import { AxiosError } from 'axios';
import { formErrorHandler, getNotificationError, toGBDecrease, toGBIncrease } from 'utils';

interface ClientFormModalInterface {
  id?: number;
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

export const ClientFormModal = ({ id, open, onSuccess, onCancel }: ClientFormModalInterface) => {
  const [form] = useForm();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const { isLoading: isClientLoading } = useQuery({
    ...(id ? usersApi.getClient(id) : {}),
    onSuccess: (data) =>
      form.setFieldsValue({
        ...data,
        storage_limit: toGBDecrease(data.storage_limit)
      }),
    enabled: !!id
  });

  const { mutate: createClientMutate, isLoading: isCreateClientLoading } = useMutation(
    (data) => (id ? users.updateClient(id, data) : users.createClient(data)),
    {
      onSuccess: () => {
        onSuccess && onSuccess();

        queryClient.invalidateQueries(usersApi.getClientsList().queryKey);
        queryClient.invalidateQueries(usersApi.getClientsStats().queryKey);

        onCancel && onCancel();
      },
      onError: (error: AxiosError) => {
        form.setFields(formErrorHandler(error));

        notification.error({ message: getNotificationError(error) });
      }
    }
  );

  const { mutate: cifMutation } = useMutation(users.getCifData, {
    onSuccess: ({ found }) => {
      form.setFieldValue('name', found[0]?.denumire.trim());
    }
  });

  const onCancelClick = () => {
    onCancel && onCancel();

    form.resetFields();
  };

  const onValuesChange = ({ cif }: any) => {
    if (cif) {
      cifMutation(cif);
    }
  };

  const onFormFinish = (values: any) =>
    createClientMutate({
      ...values,
      storage_limit: toGBIncrease(values.storage_limit)
    });

  return (
    <Modal
      title={id ? '' : ''}
      open={open}
      onCancel={onCancelClick}
      footer={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button type="link" onClick={onCancelClick}>
            {t('cancel')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            form="client-form"
            loading={isCreateClientLoading}
          >
            {t('save')}
          </Button>
        </Space>
      }
    >
      <Spin spinning={!!id && isClientLoading}>
        <Typography.Text style={{ color: currentTheme['grey-40'], fontSize: '0.875rem' }}>
          {t(id ? 'clientEditDescription' : 'clientAddDescription')}
        </Typography.Text>

        <Divider />

        <Form
          layout="vertical"
          id="client-form"
          form={form}
          onFinish={onFormFinish}
          onValuesChange={onValuesChange}
        >
          <Form.Item label={t('companyName')} name="name" required>
            <Input />
          </Form.Item>

          <Form.Item label={t('cif')} name="cif">
            <Input />
          </Form.Item>

          <Form.Item label={t('department')} name="department">
            <Input />
          </Form.Item>

          <Form.Item label={t('emailAddressLabel')} name="email" required>
            <Input />
          </Form.Item>

          <Form.Item label={t('storageSpace')} name="storage_limit" required>
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label={t('domainPrefix')}
            name="domain_prefix"
            required
            rules={[
              {
                pattern: new RegExp(/^[A-Za-z]+$/),
                message: t('wrongFormat')
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
        <Divider />
      </Spin>
    </Modal>
  );
};
