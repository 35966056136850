import { css } from '@emotion/react';
import { ThemeType } from 'types';

export const stylesInit = (theme: ThemeType) => ({
  passwordHelper: css`
    padding: 12px;
    background-color: ${theme['grey-80']};
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    gap: 12px;

    > div {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  `
});
