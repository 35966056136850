import { css } from '@emotion/react';

import errorUrl from 'assets/svg/red-error.svg';

import { palette } from 'utils';

export const formStyles = css`
  .ant-form-item-has-error {
    margin-bottom: 32px;
  }
  .ant-form-item .ant-form-item-label > label {
    color: ${palette['grey-50']};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    content: ' *';
    display: inline-block;
    color: ${palette['red-100']};
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  .ant-form-item-explain-error {
    font-size: 14px;
    line-height: 18px;
    padding-top: 6px;
    display: flex;
    align-items: center;
    gap: 8px;

    &::before {
      color: red;
      content: '';
      background-image: url(${errorUrl});
      background-size: 16px;

      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }

  .ant-form-small {
    .ant-form-item .ant-form-item-label {
      padding-bottom: 4px;
    }
    .ant-form-item-explain-error {
      font-size: 12px;
      line-height: 16px;
      padding-top: 4px;

      &::before {
        background-size: 12px;

        display: inline-block;
        width: 12px;
        height: 12px;
      }
    }
  }
`;
