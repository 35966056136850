import { DownloadBackgroundZip } from 'api/documents.types';
import { DownloadFilesContext } from 'contexts';
import { useContext } from 'react';
import { downloadFile } from 'utils';

interface DataFileType {
  file: Blob | DownloadBackgroundZip;
  name?: string;
}

export const useDownloadFiles = () => {
  const { handleAddKeyToSessionStorage } = useContext(DownloadFilesContext);

  const handleDownloadFiles = (data: DataFileType) => {
    if (data.file instanceof Blob) {
      downloadFile(data.file, data.name);
      return;
    }

    data.file && handleAddKeyToSessionStorage(data.file);
  };

  return { handleDownloadFiles };
};
