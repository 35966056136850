import { AxiosError } from 'axios';

export const formErrorHandler = (error: AxiosError): { name: string[]; errors: string[] }[] => {
  const errorList: { name: string[]; errors: string[] }[] = [];

  const processNestedErrors = (obj: any, prefix: string[] = []) => {
    for (const [key, value] of Object.entries(obj?.data)) {
      const fieldName = [...prefix, key];
      if (Array.isArray(value)) {
        value.forEach((error: any) => {
          if (typeof error === 'object' && error !== null) {
            processNestedErrors(error, fieldName);
          } else {
            errorList.push({
              name: fieldName,
              errors: [error]
            });
          }
        });
      } else if (typeof value === 'object' && value !== null) {
        processNestedErrors(value, fieldName);
      }
    }
  };

  processNestedErrors(error);

  return errorList;
};
