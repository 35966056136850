import { css } from '@emotion/react';

import { palette } from 'utils';

export const modalStyles = css`
  .ant-modal {
    .ant-modal-content {
      border-radius: 8px;
      background-color: ${palette['grey-80']};
      border: 1px solid ${palette['grey-70']};
      padding: 32px;
      width: 400px;
    }

    &.move-document-modal {
      .ant-modal-content {
        width: 460px;
        padding: 20px;
      }
      .ant-modal-close {
        top: 20px;
        right: 20px;
      }
    }

    .ant-modal-close {
      top: 32px;
      inset-inline-end: 32px;
      background-color: ${palette['grey-90']};
      border-radius: 8px;
      padding: 2px;
      width: unset;
      height: unset;
    }

    .ant-modal-header {
      background-color: unset;
    }
  }
`;
