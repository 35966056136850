import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FoldersBreadcrumb, HeadTitle } from 'components';
import { useFilters, useQueryParams } from 'hooks';
import { defaultPaginationProp } from 'utils';
import { ClientDocumentsFiltersValue } from 'types';
import { DocumentsLabels } from 'components/MainLayout/utils';

import { DocumentsContentWrapper } from '../components';

export const DocumentsPage = () => {
  const params = useQueryParams();
  const { t } = useTranslation();

  const { filters, onFiltersChange } = useFilters<ClientDocumentsFiltersValue>(
    {
      ...defaultPaginationProp,
      search: '',
      documents_state: undefined,
      ordering: undefined,
      partner_ids: [],
      charts_enabled: false,
      sent_at_after: '',
      sent_at_before: '',
      directory: undefined,
      documents_archive: undefined,
      '--selectedTree': undefined
    },
    { keyword: 'documents-filters', objectKeys: ['partner'], useQueryParams: true }
  );

  const PageHeading = useMemo(() => {
    const foundItem =
      DocumentsLabels[(params.documents_state as keyof typeof DocumentsLabels) || 'total'];

    return (
      <HeadTitle
        Icon={foundItem.icon}
        label={<FoldersBreadcrumb>{t(foundItem.menuLabel)}</FoldersBreadcrumb>}
      />
    );
  }, [params.documents_state, t]);

  return (
    <DocumentsContentWrapper
      filters={filters}
      onFiltersChange={onFiltersChange}
      pageHeading={PageHeading}
    />
  );
};
