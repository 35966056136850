/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ReactNode } from 'react';

import { ChevronDown, CloseBig } from 'icons';
import { palette } from 'utils';

export interface SelectProps extends AntSelectProps {
  prefixIcon?: ReactNode;
}

interface SelectWrapperProps {
  hasPrefixIcon: boolean;
  size: SizeType;
}

const paddingLeft: Record<NonNullable<SizeType>, number> = {
  small: 8,
  middle: 10,
  large: 12
};

const defaultSize = 'middle' satisfies SizeType;

const SelectWrapper = styled.div<SelectWrapperProps>`
  position: relative;
  --icon-size: ${(props) => (props.size === 'small' ? 16 : 20)}px;
  --value-gap: 8px;
  /* Padding of the select */
  --padding-left: ${(props) => paddingLeft[props.size || defaultSize]}px;
  /* The wrapper's width */
  --width: calc(var(--icon-size) + var(--value-gap) + var(--padding-left));

  .prefix-icon-wrapper {
    color: ${palette['grey-60']};
    position: absolute;
    z-index: 1;
    left: var(--padding-left);
    height: 100%;
    display: flex;
    align-items: center;
    font-size: var(--icon-size);
    pointer-events: none;
  }

  ${({ hasPrefixIcon }) =>
    hasPrefixIcon
      ? css`
          /* Double && to override specifity */
          && .ant-select .ant-select-selector {
            padding-left: var(--width);
          }
        `
      : ''}
`;

export const Select = ({ prefixIcon, children, size, ...rest }: SelectProps) => {
  return (
    <SelectWrapper size={size} hasPrefixIcon={!!prefixIcon}>
      {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
      <AntSelect size={size} suffixIcon={<ChevronDown />} clearIcon={<CloseBig />} {...rest}>
        {children}
      </AntSelect>
    </SelectWrapper>
  );
};
