import styled from '@emotion/styled';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  NotificationDropdown,
  Dropdown,
  ChangePasswordModal,
  LanguageSelect,
  RangeDataFilter,
  FoldersTreeMenuSection,
  RangeInput
} from 'components';
import { documentsQueries, users, usersApi } from 'api';
import { ThemeContext, UserContext } from 'contexts';
import { MainLayoutStyled } from './MainLayoutStyled';
import { getLogOutRedirectRouteName, getRoute, removeSavedTokens } from 'utils';
import { ChevronDown, Forms, LogOut, ShortRight, UserCircle } from 'icons';
import { MenuSection } from './MenuSection';
import {
  ClientsLabels,
  DocumentLabelsForPartner,
  DocumentsLabels,
  PartnersLabels,
  getOrderStatus
} from './utils';
import { Legend } from './Legend';
import { Text } from '../Typography';
import { getMainRouteName, getProfileRouteName } from 'utils';

import { ReactComponent as PortalLogo } from 'assets/logos/dark_gradient_text.svg';
import { MainLayoutInterface } from './MainLayout';

const ContentWrapper = styled.div`
  width: 100%;
  overflow: hidden auto;
`;

export const MainLayoutOLD = ({
  children,
  pageHeading,
  onChange: onChangeFilter,
  filters
}: PropsWithChildren<MainLayoutInterface>) => {
  const params = useParams<{ domain: string }>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onChange: MainLayoutInterface['onChange'] = onChangeFilter
    ? (data) => onChangeFilter?.({ ...data, documents_archive: false, directory: null })
    : undefined;

  const { currentTheme } = useContext(ThemeContext);
  const {
    data: userData,
    isLoading: isLoadingUser,
    role: { isPartner, isClient, isAdmin }
  } = useContext(UserContext);

  const [headerTitle, setHeaderTitle] = useState(pageHeading);
  const [showResetPassModal, setShowResetPassModal] = useState(false);

  const domain = userData?.domain_prefix || params.domain;

  const { data: clientData } = useQuery({
    ...usersApi.getClientLogo(domain || ''),
    enabled: !!domain
  });

  useEffect(() => {
    setHeaderTitle(pageHeading);
  }, [pageHeading]);

  const Components = useMemo(() => MainLayoutStyled(currentTheme), [currentTheme]);

  const MenuLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    svg {
      font-size: 20px;
      color: ${currentTheme['grey-50']};
    }
  `;

  const onLogOutClick = async () => {
    removeSavedTokens();

    const redirectRouteName = getLogOutRedirectRouteName({
      isAdmin,
      isClient,
      isPartner
    });

    await queryClient.invalidateQueries(usersApi.getLoggedUserData().queryKey);

    removeSavedTokens();

    navigate(getRoute(redirectRouteName, { domain: userData?.domain_prefix }));
  };

  return (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      <Components.SideBar>
        <Space
          style={{
            justifyContent: 'space-between',
            width: 'calc(100% - 2.5rem)',
            padding: '1.25rem'
          }}
          styles={{ item: { display: 'flex', width: 'auto' } }}
        >
          <PortalLogo
            onClick={() =>
              navigate(getRoute(getMainRouteName({ isAdmin, isPartner, isClient }), params))
            }
          />

          {clientData?.logo && (
            <Space styles={{ item: { display: 'flex' } }} size={15}>
              <ShortRight fontSize={20} fill={currentTheme['grey-50']} />

              <Avatar src={clientData.logo} shape="square" size={26} />
            </Space>
          )}
        </Space>

        <Space direction="vertical" style={{ width: '100%' }}>
          {isClient && (
            <>
              <MenuSection
                title={t('documents').toUpperCase()}
                query={documentsQueries.getClientStats}
                paramsKey="documents_state"
                route={getRoute('DocumentsPage', params)}
                translates={DocumentsLabels}
                onChange={onChange}
                select={getOrderStatus}
              />

              <MenuSection
                title={t('partners').toUpperCase()}
                query={usersApi.getPartnersStats}
                paramsKey="state"
                route={getRoute('PartnersPage', params)}
                translates={PartnersLabels}
                onChange={onChange}
              />
            </>
          )}

          {isPartner && (
            <>
              <MenuSection
                title={t('documents').toUpperCase()}
                query={documentsQueries.getPartnerStats}
                paramsKey="documents_state"
                route={getRoute('PartnerDocumentsPage', params)}
                translates={DocumentLabelsForPartner}
                onChange={onChange}
                select={getOrderStatus}
              />
              {filters && onChange && (
                <RangeDataFilter
                  filters={filters}
                  onFiltersChange={onChange}
                  afterKey="created_at_after"
                  beforeKey="created_at_before"
                />
              )}

              {filters && (
                <FoldersTreeMenuSection filters={filters} onFiltersChange={onChangeFilter} />
              )}
            </>
          )}

          {isAdmin && (
            <>
              <MenuSection
                title={t('clients').toUpperCase()}
                query={usersApi.getClientsStats}
                paramsKey="clients_state"
                route={getRoute('ClientsPage', params)}
                translates={ClientsLabels}
                onChange={onChange}
              />

              <RangeInput
                filters={filters}
                onFiltersChange={onChange}
                gteKey="partners_count_gte"
                lteKey="partners_count_lte"
              />
            </>
          )}
        </Space>
      </Components.SideBar>

      <ContentWrapper>
        <Components.Header>
          <Space
            style={{
              justifyContent: 'space-between',
              width: 'calc(100% - 4rem)',
              padding: '1.25rem 2rem'
            }}
          >
            <Space styles={{ item: { display: 'flex' } }}>
              <Typography.Title level={4} style={{ margin: 0 }}>
                {headerTitle}
              </Typography.Title>
            </Space>
            <Space styles={{ item: { display: 'flex', alignItems: 'baseline' } }} align="center">
              <div style={{ marginRight: 10, display: 'flex' }}>
                <LanguageSelect />
              </div>

              <Legend />

              <NotificationDropdown />

              <Dropdown
                placement="bottomRight"
                overlayStyle={{ minWidth: 'unset' }}
                trigger={['click']}
                menu={{
                  items: [
                    {
                      key: '1',
                      label: (
                        <MenuLabel
                          onClick={() =>
                            navigate(
                              getRoute(
                                getProfileRouteName({
                                  isAdmin,
                                  isPartner,
                                  isClient
                                }),
                                params
                              )
                            )
                          }
                        >
                          <UserCircle />
                          {t('yourProfile')}
                        </MenuLabel>
                      )
                    },
                    isClient
                      ? {
                          key: '2',
                          label: (
                            <MenuLabel onClick={() => setShowResetPassModal(true)}>
                              <Forms />
                              {t('changePassword')}
                            </MenuLabel>
                          )
                        }
                      : null,
                    {
                      key: '3',
                      label: (
                        <MenuLabel onClick={onLogOutClick}>
                          <LogOut />
                          {t('logOut')}
                        </MenuLabel>
                      )
                    }
                  ]
                }}
              >
                <Space
                  style={{
                    color: currentTheme['grey-40'],
                    marginLeft: 20,
                    cursor: 'pointer'
                  }}
                  styles={{ item: { display: 'flex' } }}
                >
                  <Spin spinning={isLoadingUser}>
                    <Text size="lg" style={{ color: currentTheme['grey-white'] }}>
                      {userData?.name}
                    </Text>
                  </Spin>
                  <ChevronDown fontSize={20} />
                </Space>
              </Dropdown>

              <Avatar src={userData?.logo} shape="square" />
            </Space>
          </Space>
        </Components.Header>

        {children}

        <ChangePasswordModal
          open={showResetPassModal}
          setOpen={setShowResetPassModal}
          call={users.requestClientPassword}
        />
      </ContentWrapper>
    </div>
  );
};
