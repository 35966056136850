import { Upload, UploadProps } from 'antd';
import { Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect } from 'react';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { useQueryParams, useStateHandler } from '../hooks';
import { documents, users } from '../api';
import { getFileDirectory, getNotificationError } from '../utils';
import { axios } from '../api/axios';
import { AxiosError } from 'axios';
import { NotificationContext } from '../contexts';

interface SignedUploadInterface extends PropsWithChildren, UploadProps {
  folderName?: string;
  setProgress?: Dispatch<SetStateAction<{ [x: string]: number }>>;
  beforeApiCall?: () => void;
  apiCall?: (
    data: {
      name: UploadFile['name'];
      content_type?: UploadFile['type'];
      size?: UploadFile['size'];
      operation_id?: string;
    }[]
  ) => Promise<{ signed_url: string; db_name: string; id: number }[]>;
  beforeUploadCall?: () => void;
  afterUploadCall?: () => void;
}

export const SignedUpload = ({
  folderName,
  setProgress,
  beforeApiCall,
  apiCall = users.getSignedUrls,
  beforeUploadCall,
  afterUploadCall,
  children,
  ...props
}: SignedUploadInterface) => {
  const query = useQueryParams<{ directory?: string }>();
  const { notification } = useContext(NotificationContext);

  const [state, setState] = useStateHandler<{
    filesUidList: string[];
    fileList: UploadFile[];
  }>({ filesUidList: [], fileList: [] });

  useEffect(() => {
    const onFileListUpdate = () => {
      if (beforeApiCall) beforeApiCall();

      apiCall(
        state.fileList.map((i) => ({
          name: [
            folderName,
            getFileDirectory(
              i.originFileObj?.webkitRelativePath ? i.originFileObj.webkitRelativePath : i.name
            ),
            i.name
          ]
            .filter(Boolean)
            .join('/'),
          content_type: i.type,
          size: i.size,
          uuid: i.uid,
          folder_id: query.directory
        }))
      )
        .then(async (res) => {
          if (beforeUploadCall) await beforeUploadCall();

          await Promise.all(
            state.fileList.map(
              async (i, key) =>
                await axios
                  .put(res[key].signed_url, i.originFileObj, {
                    headers: {
                      'Content-Type': i.type
                    },
                    onUploadProgress: (progressEvent) => {
                      if (progressEvent?.total) {
                        if (setProgress)
                          setProgress((prev) => ({
                            ...prev,
                            [res[key]?.db_name]:
                              (progressEvent?.loaded * 100) / (progressEvent?.total || 0)
                          }));
                      }
                    }
                  })
                  .then(async () => {
                    await documents.confirmUpload({
                      ids: res?.[key]?.id
                    });
                  })
                  .catch((error: AxiosError) => {
                    notification.error({
                      message: getNotificationError(error)
                    });
                  })
            )
          );

          setState({ fileList: [], filesUidList: [] });

          if (afterUploadCall) afterUploadCall();
        })
        .catch((error: AxiosError) => {
          notification.error({
            message: getNotificationError(error)
          });
        });
    };

    if (state.fileList.length) {
      onFileListUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.fileList]);

  const onChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setState((prevState) => {
      if (!info.fileList.some((i) => prevState.filesUidList.includes(i.uid))) {
        return {
          filesUidList: info.fileList.map((i) => i.uid),
          fileList: info.fileList
        };
      }

      return prevState;
    });
  };

  return (
    <Upload
      multiple
      customRequest={() => null}
      onChange={onChange}
      fileList={state.fileList}
      showUploadList={false}
      {...props}
    >
      {children}
    </Upload>
  );
};
