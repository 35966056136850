import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { InfiniteData, useMutation } from '@tanstack/react-query';
import { TreeDataNode } from 'antd';
import { useLocation, useSearchParams } from 'react-router-dom';

import { documents } from 'api';
import { DirectoriesParams } from 'api/documents.types';
import { useQueryParams } from 'hooks';
import { Folder, FolderFill } from 'icons';
import { $Object, Directory } from 'types';
import { withValues } from 'utils';

interface FoldersContextInterface {
  formattedData: TreeDataNode[];
  setFormattedData: Dispatch<SetStateAction<TreeDataNode[]>>;
  fetchNextFolderPage: (forcePage?: number, foundId?: number) => void;
  isFolderMutateLoading: boolean;
  renderCount: number;
  setData: Dispatch<SetStateAction<InfiniteData<TreeDataNode> | undefined>>;
  icon: TreeDataNode['icon'];
  folderData?: Directory;
  setSelectedTreeFilter: (id: number | string, filters: $Object) => (number | string) | any[];
  handleExpand: (variables: DirectoriesParams & { id: number }) => Promise<void>;
  expandKey: number | null;
}

const icon: TreeDataNode['icon'] = ({ selected }) => (selected ? <FolderFill /> : <Folder />);

const PARAMS_SELECTED_TREE = '--selectedTree';

export const FoldersContext = createContext<FoldersContextInterface>({
  formattedData: [],
  setFormattedData: () => null,
  fetchNextFolderPage: () => null,
  isFolderMutateLoading: false,
  renderCount: 0,
  setData: () => null,
  icon,
  setSelectedTreeFilter: () => [],
  handleExpand: () => Promise.resolve(),
  expandKey: null
});

const returnChildrenTreeNode = (directory: Directory, formattedData: TreeDataNode[]) => {
  const recursive = (children?: TreeDataNode[]): TreeDataNode | undefined => {
    return children?.find((treeData) => {
      const found = `${treeData.key}` === `${directory.id}`;

      if (
        treeData?.children?.length &&
        found &&
        treeData?.children?.length !== directory.children.length + 1
      ) {
        treeData.children = [];
      }

      const isEmptyArray = treeData?.children?.[0]?.key === `key-${directory.id}`;

      if (treeData?.children?.length && !isEmptyArray) {
        return recursive(treeData.children);
      }

      const hasChildrenList = !!directory?.children?.length;

      if (found) {
        treeData.children = [
          ...directory.children.map(({ id, name }) => ({
            key: id,
            title: name,
            icon,
            className: `key-id--${id}`,
            children: [{ key: `key-${id}`, style: { display: 'none' } }]
          })),
          ...(hasChildrenList
            ? [
                {
                  key: `parent--${directory.id}--treedata--${treeData.key}`,
                  className: `parent--${directory.id}--treedata--${treeData.key}`,
                  title: <div data-id={`${directory.id}`} />,
                  style: { height: 0, visibility: 'hidden' } as React.CSSProperties
                }
              ]
            : [])
        ];
      }

      return found;
    });
  };

  recursive(formattedData);

  return formattedData;
};

export const FoldersProvider = ({ children }: PropsWithChildren) => {
  const [infiniteMutationData, setInfiniteMutationData] = useState<Record<string, Directory>>({});
  const [infinitePage, setInfinitePage] = useState<Record<string, number>>({});
  const [formattedData, setFormattedData] = useState<TreeDataNode[]>([]);
  const { pathname } = useLocation();
  const [renderCount, setRenderCount] = useState(0);
  const [data, setData] = useState<InfiniteData<TreeDataNode>>();
  const [expandKey, setExpandKey] = useState<number | null>(null);

  const queryParams = useQueryParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const selectedKey = queryParams.directory as number;

  const {
    mutate: folderMutate,
    mutateAsync: folderMutateAsync,
    isLoading: isFolderMutateLoading
  } = useMutation({
    mutationFn: ({ id, ...variables }: DirectoriesParams & { id: number }) =>
      documents.getDirectoryById(id, {
        per_page: 10,
        search: queryParams.search,
        ordering: queryParams.ordering,
        partner_ids: queryParams.partner_ids,
        ...variables
      }),
    onSuccess: (data, variables) => {
      setInfiniteMutationData((value) => {
        if (value[variables.id]) {
          value[variables.id].current_page = data.current_page;
          value[variables.id].total_pages = data.total_pages;
          value[variables.id].children[(variables?.page || 1) - 1] = [data.children] as any;
          return { ...value };
        } else {
          return { ...value, [variables.id]: { ...data, children: [data.children] } };
        }
      });
    }
  });

  useEffect(() => {
    if (!selectedKey) return;

    if (infiniteMutationData[selectedKey]) {
      setInfiniteMutationData((value) => ({
        ...value,
        [selectedKey]: { ...value[selectedKey], children: [] }
      }));
    }

    setInfinitePage(() => {
      const page = 1;
      folderMutate({ id: +selectedKey!, page });

      return { [selectedKey]: page };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.search, queryParams.ordering, queryParams.partner_ids]);

  const folderData = useCallback(
    (id = selectedKey) =>
      id && infiniteMutationData[id]
        ? {
            ...infiniteMutationData[id],
            children: infiniteMutationData[id].children.flat(2)
          }
        : undefined,
    [infiniteMutationData, selectedKey]
  );

  const fetchNextFolderPage = (forcePage?: number, foundId?: number) => {
    const id = foundId || selectedKey;

    if (!id || !folderData(id)) return;
    setExpandKey(id);

    const hasNextPage = (infinitePage[id] || 1) < infiniteMutationData[id]?.total_pages;

    if (!hasNextPage) return;

    setInfinitePage((value) => {
      const page = forcePage || (value[id] || 1) + 1;
      folderMutate({ id: +id!, page });

      return { [id]: page };
    });
  };

  const handleExpand = async (variables: DirectoriesParams & { id: number }) => {
    try {
      const data = await folderMutateAsync(variables);

      const newData = returnChildrenTreeNode(data, formattedData);

      setFormattedData(newData);
      setExpandKey(variables.id);
    } catch {
      setSearchParams(
        withValues({
          ...queryParams,
          directory: null,
          [PARAMS_SELECTED_TREE]: null
        })
      );
    }
  };

  useEffect(() => {
    const queryKey = queryParams[PARAMS_SELECTED_TREE];

    const queryKeys = Array.isArray(queryKey) ? queryKey : [queryKey];

    if (!(queryKey && queryKeys?.length) || !selectedKey) return;

    let dataSet = formattedData;

    queryKeys.forEach(async (id) => {
      try {
        const data = await folderMutateAsync({ id });

        const newData = returnChildrenTreeNode(data, dataSet);

        dataSet = newData;
        setRenderCount((i) => ++i);
      } catch {
        setSearchParams(
          withValues({
            ...queryParams,
            directory: null,
            [PARAMS_SELECTED_TREE]: null
          })
        );
      }
    });

    setFormattedData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, formattedData]);

  useEffect(() => {
    const id = expandKey || selectedKey;
    if (!folderData(id) || !id) return;
    const newData = returnChildrenTreeNode(folderData(id)!, formattedData);

    newData && setFormattedData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderData]);

  useEffect(() => {
    selectedKey && folderMutate({ id: +selectedKey });

    selectedKey && setExpandKey(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey]);

  useEffect(() => {
    if (!data) return;

    setFormattedData((treeData) => [
      ...treeData,
      ...(data?.pages.filter(
        ({ key }) => !treeData.length || !treeData.some((value) => value.key === key)
      ) || [])
    ]);
  }, [data]);

  const setSelectedTreeFilter = (id: number | string, filters: $Object) => {
    const param = Array.isArray(filters[PARAMS_SELECTED_TREE])
      ? [...filters[PARAMS_SELECTED_TREE], id]
      : filters[PARAMS_SELECTED_TREE]
        ? [filters[PARAMS_SELECTED_TREE], id]
        : id;

    return Array.isArray(param) ? Array.from(new Set(param)) : param;
  };

  return (
    <FoldersContext.Provider
      value={{
        formattedData,
        setFormattedData,
        fetchNextFolderPage,
        isFolderMutateLoading,
        renderCount,
        setData,
        icon,
        folderData: folderData(),
        setSelectedTreeFilter,
        handleExpand,
        expandKey
      }}
    >
      {children}
    </FoldersContext.Provider>
  );
};
