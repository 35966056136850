const NOTIFICATION_CONTAINER_LEFT_MARGIN = 24;
/**
 * Get necessary styles for a notification to be aligned with a target element
 *
 * Note that the notification should have the placement bottomLeft
 */
export const getAlignedNotificationStyles = (target: HTMLElement) => {
  const formBoundingRect = target.getBoundingClientRect();
  return {
    left: formBoundingRect.left - NOTIFICATION_CONTAINER_LEFT_MARGIN,
    width: formBoundingRect.width
  };
};
