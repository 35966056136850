import { $Object } from '../types';
import { load, save } from 'react-cookies';

const FILTERS = '__filters__';

export const getFilters = (): $Object | null => load(FILTERS);

export const setFilters = (newFilters: $Object): void =>
  save(FILTERS, JSON.stringify(newFilters), { path: '/' });

const miliard = 1000 * 1000 * 1000;

export const toGBIncrease = (number: number = 0) => number * miliard;
export const toGBDecrease = (number: number = 0) => number / miliard;

export const downloadFile = (blob: Blob, fileName = 'export.xlsx'): void => {
  const link = document.createElement('a');

  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();

  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 5000);
};

export const downloadFileFromURI = (
  uri: string,
  fileName = 'export.xlsx',
  isBlank = true
): void => {
  const link = document.createElement('a');

  link.href = uri;
  link.download = fileName;
  if (isBlank) {
    link.target = '_blank';
  }

  document.body.append(link);

  link.click();
  link.remove();
};
