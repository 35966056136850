import { useContext, useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Form, Space } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Modal, ModalProps, Input, InfiniteQuerySelect } from 'components';
import { AccessLevelGroup } from 'api/users.types';
import { users, usersApi } from 'api';
import { formErrorHandler, getNotificationError } from 'utils';
import { NotificationContext } from 'contexts';

interface AccessLevelGroupFormModalInterface extends ModalProps {
  data?: AccessLevelGroup;
  onCancel?: () => void;
}

export const AccessLevelGroupFormModal = ({
  data,
  onCancel,
  ...props
}: AccessLevelGroupFormModalInterface) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notification } = useContext(NotificationContext);

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue({
      ...data,
      access_levels: data.access_levels.map(({ id }) => id)
    });
  }, [data, form]);

  const onCancelClick = () => {
    onCancel?.();

    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(
    (body) =>
      data ? users.editAccessLevelGroup(data.id, body) : users.createAccessLevelGroup(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(usersApi.getAccessLevelGroupList().queryKey);
        queryClient.invalidateQueries(usersApi.getAccessLevelsList().queryKey);
        onCancelClick();
        notification.success({
          message: t(data ? 'editedAccessLevelGroup' : 'addedAccessLevelGroup'),
          placement: 'bottomRight'
        });
      },
      onError: (error: AxiosError) => {
        form.setFields(formErrorHandler(error));
        notification.error({ message: getNotificationError(error) });
      }
    }
  );

  return (
    <Modal
      title={t(data ? 'editAccessLevelGroup' : 'addAccessLevelGroup')}
      onCancel={onCancelClick}
      footer={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button type="link" onClick={onCancelClick}>
            {t('cancel')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            form="access-level-group-form"
            loading={isLoading}
          >
            {t('save')}
          </Button>
        </Space>
      }
      {...props}
    >
      <Divider />

      <Form layout="vertical" id="access-level-group-form" form={form} onFinish={mutate}>
        <Form.Item label={t('name')} name="name" required>
          <Input />
        </Form.Item>

        <Form.Item label={t('accessLevels')} name="access_levels" required>
          <InfiniteQuerySelect
            apiCall={usersApi.getAccessLevelsList}
            getOptions={(item) => ({
              label: item.name,
              value: item.id,
              payload: item
            })}
            mode="multiple"
          />
        </Form.Item>
      </Form>

      <Divider />
    </Modal>
  );
};
