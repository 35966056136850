/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { Avatar, List, Spin } from 'antd';
import VirtualList from 'rc-virtual-list';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { NotificationContext, ThemeContext } from 'contexts';
import { notificationApi, notifications, users } from 'api';
import { dateFormatDMCMY, doublePerPage, format } from 'utils';
import { Notification } from 'types';

import { stylesInit } from './notification-dropdown.styles';

const ContainerHeight = 440;

export const NotificationDropdownContent = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const styles = stylesInit(currentTheme);

  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: notificationApi.getList().queryKey,
    queryFn: ({ pageParam = 1 }) =>
      notifications.getList({ page: pageParam, per_page: doublePerPage }),
    getNextPageParam: (lastPage) =>
      lastPage.current_page < lastPage.total_pages ? lastPage.current_page + 1 : undefined
  });

  const { mutate: readNotificationMutate } = useMutation(notifications.patch, {
    onMutate: () => {
      queryClient.invalidateQueries({
        queryKey: notificationApi.getList().queryKey
      });
    },
    onError: (err) => {
      notification.success({
        message: t('cantMarkNotificationAsRead')
      });
    }
  });

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
      if (hasNextPage) {
        fetchNextPage();
      }
    }
  };

  const onReadNotification = (notification: Notification) => {
    if (!notification.is_read) {
      readNotificationMutate({
        id: notification.id,
        is_read: true
      });
    }
    if (!notification.is_read && notification?.action_name === 'Reset password') {
      users.requestSendPassword(notification?.action);
    }
  };

  return (
    <List
      css={styles.notificationList}
      header={<div style={{ padding: '24px 0 0 24px' }}>{t('notifications')}</div>}
      itemLayout="horizontal"
    >
      <Spin spinning={isLoading}>
        <VirtualList
          data={data?.pages.flatMap((page) => page.results) || []}
          height={ContainerHeight}
          itemHeight={83.2}
          itemKey="notification"
          onScroll={onScroll}
        >
          {(item) => (
            <List.Item
              css={styles.notificationListItem}
              onClick={item.is_read ? undefined : () => onReadNotification(item)}
              style={{
                backgroundColor: item.is_read ? 'transparent' : currentTheme['grey-70'],
                padding: '0 24px',
                cursor: item.is_read ? 'inherit' : 'pointer'
              }}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    shape="square"
                    css={styles.notificationListItemAvatar}
                    size={40}
                    src={item.initiator?.logo}
                  />
                }
                title={
                  <span
                    css={styles.notificationListItemTitle(item.is_read)}
                    title={!item.is_read ? t('makeClickToMarkAsRead') : ''}
                  >
                    {item.title}
                  </span>
                }
                description={
                  <span css={styles.notificationListItemDate}>
                    {format(item.created_at, dateFormatDMCMY)}
                  </span>
                }
              />
            </List.Item>
          )}
        </VirtualList>
      </Spin>
    </List>
  );
};
