import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Divider, Space } from 'antd';

import {
  ActionHeadTitle,
  ChangePasswordModal,
  DetailsCard,
  MainLayout,
  ProfilePageTemplate,
  Put,
  StatusTag,
  Text,
  UserStatusLabel
} from 'components';
import { BarChart, Info2Outline, Refresh02, ShortLeft, UserCircle } from 'icons';
import { dateTimeRowFormat, format, getNotificationError, palette } from 'utils';
import { users, usersApi } from 'api';
import { NotificationContext, ThemeContext, UserContext } from 'contexts';
import { AxiosError } from 'axios';

export const MyProfilePage = () => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { notification } = useContext(NotificationContext);
  const { data: loggedUser } = useContext(UserContext);
  const { currentTheme } = useContext(ThemeContext);

  const [showResetPassModal, setShowResetPassModal] = useState(false);

  const { data: partnerData } = useQuery({
    ...(loggedUser?.id ? usersApi.getPartnerProfileDetails() : {})
  });

  const anafLoginURLQuery = useQuery({
    ...usersApi.getAnafLoginURL(),
    retry: false
  });

  const syncEFacturaMutation = useMutation(() => users.syncDocuments(partnerData?.id || 0), {
    onSuccess: () => {
      notification.success({ message: t('syncEFacturaSuccessful') });
    },
    onError: () => {
      notification.error({ message: t('syncEFacturaError') });
    }
  });

  const updateMutate = useMutation((data: FormData) => users.updateProfilePartner(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(usersApi.getPartnerProfileDetails().queryKey);
      queryClient.invalidateQueries(usersApi.getLoggedUserData().queryKey);
    },

    onError: (err: AxiosError) => {
      notification.error({ message: getNotificationError(err) });
    }
  });

  return (
    <MainLayout pageHeading={<ActionHeadTitle label={t('yourProfile')} Icon={ShortLeft} />}>
      <ProfilePageTemplate data={partnerData} mutation={updateMutate}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100% - 74px)',
            width: '100%'
          }}
        >
          <div style={{ display: 'flex', height: '100%' }}>
            <DetailsCard
              title={t('myPartnerProfile')}
              Icon={UserCircle}
              items={[
                { label: t('partnerName'), value: <Put>{partnerData?.name}</Put> },
                { label: t('companyCif'), value: <Put>{partnerData?.cif}</Put> },
                { label: t('department'), value: <Put>{partnerData?.department}</Put> },
                { label: t('emailAddress'), value: <Put>{partnerData?.email}</Put> }
              ]}
            />

            <Divider type="vertical" style={{ height: 'auto', margin: 0 }} />

            <DetailsCard
              title={t('myClientProfile')}
              Icon={null}
              Avatar={<Avatar src={partnerData?.client.logo} size={20} shape="square" />}
              items={[
                { label: t('clientName'), value: <Put>{partnerData?.client.name}</Put> },
                { label: t('companyCif'), value: <Put>{partnerData?.client.cif}</Put> },
                {
                  label: t('department'),
                  value: <Put>{partnerData?.client.department}</Put>
                },
                {
                  label: t('emailAddress'),
                  value: <Put>{partnerData?.client.email}</Put>
                }
              ]}
            />

            <Divider type="vertical" style={{ height: 'auto', margin: 0 }} />

            <DetailsCard
              title={t('activity')}
              Icon={BarChart}
              items={[
                {
                  label: t('profileStatus'),
                  value: <UserStatusLabel unfilled status={partnerData?.state} />
                },
                {
                  label: t('profileCreatedAt'),
                  value:
                    partnerData?.created_at && format(partnerData.created_at, dateTimeRowFormat)
                },
                {
                  label: t('lastActivity'),
                  value:
                    partnerData?.last_login && format(partnerData.last_login, dateTimeRowFormat)
                },
                {
                  label: t('documentsNumber'),
                  value: partnerData?.documents_count
                }
              ]}
            />
          </div>

          <Space
            style={{
              borderTop: `.5px solid ${currentTheme['grey-70']}`,
              width: 'calc(100% - 64px)',
              justifyContent: 'space-between',
              padding: '18px 32px'
            }}
          >
            <Space size={16} styles={{ item: { display: 'flex' } }}>
              <Button
                disabled={!anafLoginURLQuery.data?.redirect_url}
                href={anafLoginURLQuery.data?.redirect_url}
                type="primary"
              >
                {t('enrollEFactura')}
              </Button>
              {partnerData?.external_e_factura_id && (
                <Button
                  onClick={() => syncEFacturaMutation.mutate()}
                  disabled={syncEFacturaMutation.isLoading}
                >
                  <Refresh02 fontSize={16} />
                  {t('syncEFactura')}
                </Button>
              )}
              {partnerData?.external_e_factura_updated_at && (
                <StatusTag color={palette['grey-40']} icon={<Info2Outline fontSize={16} />}>
                  {t('lastEnrollment')}: {format(partnerData?.external_e_factura_updated_at)}
                </StatusTag>
              )}
            </Space>
            <Space
              size={8}
              styles={{ item: { display: 'flex' } }}
              style={{ color: currentTheme['purple-100'], cursor: 'pointer' }}
              onClick={() => setShowResetPassModal(true)}
            >
              <Text size="lg">{t('requestChangePassword')}</Text>
              <Refresh02 />
            </Space>
          </Space>
        </div>
      </ProfilePageTemplate>

      <ChangePasswordModal
        open={showResetPassModal}
        setOpen={setShowResetPassModal}
        call={users.sendPartnerPassword}
      />
    </MainLayout>
  );
};
